import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { message } from 'antd';
import Cookies from 'js-cookie';
import { useAppData } from '../context/ApplicationProvider';

function AuthenticationPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [messageApi, contextHolder] = message.useMessage();
    const { login } = useAuth();
    const navigate = useNavigate();
    const { isLoggedIn } = useAuth();
    const { setCurrentUser } = useAppData();

    if (isLoggedIn) {
        navigate('/user/logged-in');
    }

    const getCurrentUser = async (user) => {
        try {
            const response = await fetch(`https://app.arenabackend.org/korisnici/korisnik/${user}`);
            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }
            const userData = await response.json();
            return userData;
        } catch (error) {
            console.error('Error fetching user data:', error);
            throw error;
        }
    };

    const handleLogin = async () => {
        try {
            const response = await axios.post('https://app.arenabackend.org/authentication/login', { email, password });
            const { token, user } = response.data;

            const userData = await getCurrentUser(user);
            // Set current user data in application state
            setCurrentUser(userData);

            // Save the token and user data to cookies
            Cookies.set('token', token, { expires: 7 }); // Expires in 7 days
            Cookies.set('user', user, { expires: 7 });
            Cookies.set('currentUser', JSON.stringify(userData), { expires: 7 });

            // Fetch current user data

            // Update the login state
            await login();

            messageApi.open({
                type: 'success',
                content: 'Uspiješno ste povukli podatke iz baze',
            });

            // Redirect to /user/logged-in
            navigate('/user/logged-in');
        } catch (error) {
            console.error('Login failed:', error);
            messageApi.open({
                type: 'error',
                content: 'Pogrešni podaci',
            });
        }
    };

    return (
        <div style={{ width: '100vw', height: '100vh', backgroundColor: '#c93030' }} className='flex items-center justify-center'>
            {contextHolder}
            <div className="page-container px-3 w-2/5 h-2/5 bg-white rounded hover:shadow-2xl flex flex-col items-center justify-center relative">
                <h1 className='text-center font-bold uppercase text-xl pb-10'>Pristupite admin panelu</h1>
                <div className="form-inputs flex flex-col gap-3 w-4/5">
                    <input type='email' onChange={(e) => setEmail(e.target.value)} placeholder='Unesite email adresu' className='w-full p-3 rounded text-sm outline-red-500 border' />
                    <input type='password' onChange={(e) => setPassword(e.target.value)} placeholder='Unesite šifru' className='w-full p-3 rounded text-sm outline-red-500 border' />
                    <button onClick={handleLogin} className='w-full p-3 rounded text-sm outline-red-500 border text-white text-center' style={{ backgroundColor: '#C93030' }}>PRISTUPITE</button>
                </div>
                <h3 className='absolute bottom-3 right-3 text-xs font-light'>U slučaju da ste zaboravili šifru, pošaljite poruku na: <span className='font-bold' style={{ color: '#C93030' }}>david@arenamotors.ba</span></h3>
            </div>
        </div>
    )
}

export default AuthenticationPage;
