import axios from 'axios';
import { Trash } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react'
import { DownloadTableExcel, useDownloadExcel } from 'react-export-table-to-excel';

import io from 'socket.io-client';
const socket = io("https://app.arenabackend.org", {
    reconnection: true
})
function ZalihaArena() {
    const [zalihe, setZalihe] = useState([]);
    const [createZaliha, setCreateZaliha] = useState(null);
    const [editingZaliha, setEditingZaliha] = useState(null);
    const [downloadLoading, setDownloadLoading] = useState(null);

    const jsxContentRef = useRef(null);
    const jsxContentRefT = useRef(null);

    const [activePage, setActivePage] = useState(0)

    const [formData, setFormData] = useState({
        datum: "",
        idBroj: "",
        brojKljuceva: "",
        dostupnost: "",
        tablice: "",
        dosleUzVozilo: "",
        vozilo: "",
        godiste: "",
        vrstaMotora: "",
        snaga: "",
        kilometraza: "",
        mjenjac: "",
        brojSasije: "",
        boja: "",
        ogasenaCijenaVozila: "",
        olxLink: "",
        datumObjave: "",
        registrovanDo: "",
        zadnjiServisKmDatum: "",
        brojDanaOdKadaJeVoziloNaPlacu: "",
        velikiservis: "",
        servisnaistorija: "",
        gume: ""
    })

    const [editingData, setEditingData] = useState({
        datum: "",
        idBroj: "",
        brojKljuceva: "",
        dostupnost: "",
        tablice: "",
        dosleUzVozilo: "",
        vozilo: "",
        godiste: "",
        vrstaMotora: "",
        snaga: "",
        kilometraza: "",
        mjenjac: "",
        brojSasije: "",
        boja: "",
        ogasenaCijenaVozila: "",
        olxLink: "",
        datumObjave: "",
        registrovanDo: "",
        zadnjiServisKmDatum: "",
        brojDanaOdKadaJeVoziloNaPlacu: "",
        velikiservis: "",
        servisnaistorija: "",
        gume: ""
    })


    const clearFormData = () => {
        setFormData({
            datum: "",
            idBroj: "",
            brojKljuceva: "",
            dostupnost: "",
            tablice: "",
            dosleUzVozilo: "",
            vozilo: "",
            godiste: "",
            vrstaMotora: "",
            snaga: "",
            kilometraza: "",
            mjenjac: "",
            brojSasije: "",
            boja: "",
            ogasenaCijenaVozila: "",
            olxLink: "",
            datumObjave: "",
            registrovanDo: "",
            zadnjiServisKmDatum: "",
            brojDanaOdKadaJeVoziloNaPlacu: "",
            velikiservis: "",
            servisnaistorija: "",
            gume: ""
        });
    };

    const fetchZalihe = async () => {
        await fetch('https://app.arenabackend.org/administration/sva-zaliha').then((res) => res.json()).then((response) => setZalihe(response)).catch((err) => console.log(err))
    }


    useEffect(() => {
        fetchZalihe()
    }, [])

    const handleArraySubmit = async () => {
        try {
            const response = await axios.post('https://app.arenabackend.org/administration/kreiraj-zalihu', formData);
            console.log(response.data);
            fetchZalihe();
            setCreateZaliha(null)
            clearFormData()
        } catch (error) {
            console.error('Greška prilikom slanja zahtjeva:', error);
            alert('NEE')
        }
    };

    const deleteZaliha = async (id) => {
        try {
            const response = await axios.delete(`https://app.arenabackend.org/administration/obrisi-zalihu/${id}`);
            fetchZalihe();
            setEditingZaliha(null);
            clearFormData();
        } catch (error) {
            console.error('Greška prilikom slanja zahtjeva:', error);
            alert('NEE')
        }
    }

    const handleEditContainer = (id) => {
        const thisData = zalihe.filter((zaliha) => zaliha._id === id)[0];
        setEditingData({
            datum: thisData.datum,
            idBroj: thisData.idBroj,
            brojKljuceva: thisData.brojKljuceva,
            dostupnost: thisData.dostupnost,
            tablice: thisData.tablice,
            dosleUzVozilo: thisData.dosleUzVozilo,
            vozilo: thisData.vozilo,
            godiste: thisData.godiste,
            vrstaMotora: thisData.vrstaMotora,
            snaga: thisData.snaga,
            kilometraza: thisData.kilometraza,
            mjenjac: thisData.mjenjac,
            brojSasije: thisData.brojSasije,
            boja: thisData.boja,
            ogasenaCijenaVozila: thisData.ogasenaCijenaVozila,
            olxLink: thisData.olxLink,
            datumObjave: thisData.datumObjave,
            registrovanDo: thisData.registrovanDo,
            zadnjiServisKmDatum: thisData.zadnjiServisKmDatum,
            brojDanaOdKadaJeVoziloNaPlacu: thisData.brojDanaOdKadaJeVoziloNaPlacu,
            velikiservis: thisData.velikiservis,
            servisnaistorija: thisData.servisnaistorija,
            gume: thisData.gume
        });
    };

    const applyEdit = async () => {
        try {
            const response = await axios.put(`https://app.arenabackend.org/administration/uredi-zalihu/${editingZaliha}`, {
                datum: editingData.datum,
                idBroj: editingData.idBroj,
                brojKljuceva: editingData.brojKljuceva,
                dostupnost: editingData.dostupnost,
                tablice: editingData.tablice,
                dosleUzVozilo: editingData.dosleUzVozilo,
                vozilo: editingData.vozilo,
                godiste: editingData.godiste,
                vrstaMotora: editingData.vrstaMotora,
                snaga: editingData.snaga,
                kilometraza: editingData.kilometraza,
                mjenjac: editingData.mjenjac,
                brojSasije: editingData.brojSasije,
                boja: editingData.boja,
                ogasenaCijenaVozila: editingData.ogasenaCijenaVozila,
                olxLink: editingData.olxLink,
                datumObjave: editingData.datumObjave,
                registrovanDo: editingData.registrovanDo,
                zadnjiServisKmDatum: editingData.zadnjiServisKmDatum,
                brojDanaOdKadaJeVoziloNaPlacu: editingData.brojDanaOdKadaJeVoziloNaPlacu,
                velikiservis: editingData.velikiservis,
                servisnaistorija: editingData.servisnaistorija,
                gume: editingData.gume
            });

            if (!response) {
                alert('GRESKA')
            }
            fetchZalihe();
            setEditingZaliha(null)
            socket.emit('arzuriranaZaliha', editingZaliha);
        } catch (error) {
            console.error('Error updating task:', error);
        }

    }

    const { onDownload } = useDownloadExcel( {
        currentTableRef: jsxContentRef.current,
        filename: 'ZALIHA_DOSTUPNA',
        sheet: "Dostupna zaliha"
    })

    return (
        <>
            {
                activePage === 0 ? (
                    <div className='px-3 pb-3'>
                        <div className='text-xl mb-3'>
                            <button onClick={() => setActivePage(0)} className={`w-1/2 font-regular p-3 text-sm bg-gray-200 border-b-2 ${activePage === 0 ? 'border-b-red-600' : null}`}>Dostupno na zalihama</button>
                            <button onClick={() => setActivePage(1)} className={`w-1/2 font-regular p-3 text-sm bg-gray-200 border-b-2 ${activePage === 1 ? 'border-b-red-600' : null}`}>Spremno za isporuku / Prodato</button>
                        </div>
                        <div className='zaliha-arenatable overflow-x-auto'>
                            <button className='p-3 mb-3 w-full text-xs font-semibold text-white uppercase' style={{ backgroundColor: '#d22829' }} onClick={onDownload}>{downloadLoading ? 'Preuzimanje...' : 'Preuzmi Excel podatke'}</button>
                            <table ref={jsxContentRef} className='border border-black'>
                                <thead>
                                    <tr>
                                        <th style={{ minWidth: 25, height: 25, fontSize: 10, width: 25 }} className='font-normal bg-black text-white px-1 text-left border-r'>ID</th>
                                        <th style={{ minWidth: 110, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Datum</th>
                                        <th style={{ minWidth: 50, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>ID broj</th>
                                        <th style={{ minWidth: 150, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Broj ključeva</th>
                                        <th style={{ minWidth: 160, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>DOSTUPNOST</th>
                                        <th style={{ minWidth: 100, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Tablice</th>
                                        <th style={{ minWidth: 90, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Došle uz vozilo</th>
                                        <th style={{ minWidth: 200, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Vozilo</th>
                                        <th style={{ minWidth: 70, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Godište</th>
                                        <th style={{ minWidth: 100, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Vrsta motora</th>
                                        <th style={{ minWidth: 80, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Snaga KW</th>
                                        <th style={{ minWidth: 100, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Kilometraža</th>
                                        <th style={{ minWidth: 100, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Tip mjenjača</th>
                                        <th style={{ minWidth: 150, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>BROJ ŠASIJE</th>
                                        <th style={{ minWidth: 70, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Boja</th>
                                        <th style={{ minWidth: 140, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Oglašena cijena vozila</th>
                                        <th style={{ minWidth: 200, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Link sa OLX-a</th>
                                        <th style={{ minWidth: 100, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Datum objave</th>
                                        <th style={{ minWidth: 130, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Registrovano DO</th>
                                        <th style={{ minWidth: 150, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Zadnji servis km/datum</th>
                                        <th style={{ minWidth: 200, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Broj dana od kada je vozilo na placu</th>
                                        <th style={{ minWidth: 130, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>VELIKI SERVIS</th>
                                        <th style={{ minWidth: 160, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>SERVISNA ISTORIJA</th>
                                        <th style={{ minWidth: 140, height: 25, fontSize: 10 }} className='font-normal text-center bg-black text-white px-1 text-left border-r'>GUME/LJETNE/ZIMSKE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        zalihe.filter((data) => data.dostupnost ? data.dostupnost.toLowerCase().includes("u dolasku") || data.dostupnost.toLowerCase().includes("u prodaji") || data.dostupnost.toLowerCase() === "u prodaji - komisiona" || data.dostupnost.toLowerCase().includes("u pripremi") || data.dostupnost.toLowerCase().includes("rezervisano") : null).map((data, i) => {
                                            if (editingZaliha === data._id) {
                                                return (
                                                    <tr className={`border-b border-black`} >
                                                        <td style={{ minWidth: 25, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><button onClick={() => deleteZaliha(data._id)} className='w-full h-full py-1.5 px-3 bg-red-600 text-white'><Trash size={12} /></button></td>
                                                        <td style={{ minWidth: 110, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.datum} onChange={(e) => setEditingData((prev) => ({ ...prev, datum: e.target.value }))} className="py-1 px-3 w-full " placeholder="datum" /> </td>
                                                        <td style={{ minWidth: 50, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.idBroj} onChange={(e) => setEditingData((prev) => ({ ...prev, idBroj: e.target.value }))} className="py-1 px-3 w-full " placeholder="ID Broj" /> </td>
                                                        <td style={{ minWidth: 150, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.brojKljuceva} onChange={(e) => setEditingData((prev) => ({ ...prev, brojKljuceva: e.target.value }))} className="py-1 px-3 w-full " placeholder="broj ključeva" /> </td>
                                                        <td style={{ minWidth: 160, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.dostupnost} onChange={(e) => setEditingData((prev) => ({ ...prev, dostupnost: e.target.value }))} className="py-1 px-3 w-full " placeholder="dostupnost" /> </td>
                                                        <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.tablice} onChange={(e) => setEditingData((prev) => ({ ...prev, tablice: e.target.value }))} className="py-1 px-3 w-full " placeholder="tablice" /> </td>
                                                        <td style={{ minWidth: 90, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.dosleUzVozilo} onChange={(e) => setEditingData((prev) => ({ ...prev, dosleUzVozilo: e.target.value }))} className="py-1 px-3 w-full " placeholder="Dosle uz vozilo?" /> </td>
                                                        <td style={{ minWidth: 200, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.vozilo} onChange={(e) => setEditingData((prev) => ({ ...prev, vozilo: e.target.value }))} className="py-1 px-3 w-full " placeholder="vozilo" /> </td>
                                                        <td style={{ minWidth: 70, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.godiste} onChange={(e) => setEditingData((prev) => ({ ...prev, godiste: e.target.value }))} className="py-1 px-3 w-full " placeholder="godište" /> </td>
                                                        <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.vrstaMotora} onChange={(e) => setEditingData((prev) => ({ ...prev, vrstaMotora: e.target.value }))} className="py-1 px-3 w-full " placeholder="zapreminu" /> </td>
                                                        <td style={{ minWidth: 80, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.snaga} onChange={(e) => setEditingData((prev) => ({ ...prev, snaga: e.target.value }))} className="py-1 px-3 w-full " placeholder="snagu (kw)" /> </td>
                                                        <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.kilometraza} onChange={(e) => setEditingData((prev) => ({ ...prev, kilometraza: e.target.value }))} className="py-1 px-3 w-full " placeholder="kilometražu" /> </td>
                                                        <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.mjenjac} onChange={(e) => setEditingData((prev) => ({ ...prev, mjenjac: e.target.value }))} className="py-1 px-3 w-full " placeholder="tip mjenjača" /> </td>
                                                        <td style={{ minWidth: 150, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.brojSasije} onChange={(e) => setEditingData((prev) => ({ ...prev, brojSasije: e.target.value }))} className="py-1 px-3 w-full " placeholder="broj šasije" /> </td>
                                                        <td style={{ minWidth: 70, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.boja} onChange={(e) => setEditingData((prev) => ({ ...prev, boja: e.target.value }))} className="py-1 px-3 w-full " placeholder="boju" /> </td>
                                                        <td style={{ minWidth: 140, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.ogasenaCijenaVozila} onChange={(e) => setEditingData((prev) => ({ ...prev, ogasenaCijenaVozila: e.target.value }))} className="py-1 px-3 w-full " placeholder="oglašenu cijenu" /> </td>
                                                        <td style={{ minWidth: 200, fontSize: 10 }} className='font-normal text-blue-500 text-left border-r border-black bg-white'><input defaultValue={data.olxLink} onChange={(e) => setEditingData((prev) => ({ ...prev, olxLink: e.target.value }))} className="py-1 px-3 w-full " placeholder="link OLX-a" /> </td>
                                                        <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.datumObjave} onChange={(e) => setEditingData((prev) => ({ ...prev, datumObjave: e.target.value }))} className="py-1 px-3 w-full " placeholder="datum objave" /> </td>
                                                        <td style={{ minWidth: 130, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.registrovanDo} onChange={(e) => setEditingData((prev) => ({ ...prev, registrovanDo: e.target.value }))} className="py-1 px-3 w-full " placeholder="Registrovan DO" /> </td>
                                                        <td style={{ minWidth: 150, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.zadnjiServisKmDatum} onChange={(e) => setEditingData((prev) => ({ ...prev, zadnjiServisKmDatum: e.target.value }))} className="py-1 px-3 w-full " placeholder="zadnji servis km/datum" /> </td>
                                                        <td style={{ minWidth: 200, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.brojDanaOdKadaJeVoziloNaPlacu} onChange={(e) => setEditingData((prev) => ({ ...prev, brojDanaOdKadaJeVoziloNaPlacu: e.target.value }))} className="py-1 px-3 w-full " placeholder="broj dana od kad je vozilo na lageru" /> </td>
                                                        <td style={{ minWidth: 130, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.velikiservis} onChange={(e) => setEditingData((prev) => ({ ...prev, velikiservis: e.target.value }))} className="py-1 px-3 w-full " placeholder="veliki servis" /> </td>
                                                        <td style={{ minWidth: 160, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.servisnaistorija} onChange={(e) => setEditingData((prev) => ({ ...prev, servisnaistorija: e.target.value }))} className="py-1 px-3 w-full " placeholder="servisnu istoriju" /> </td>
                                                        <td style={{ minWidth: 140, fontSize: 10 }} className='font-normal text-center text-black text-left border-r border-black'><input defaultValue={data.gume} onChange={(e) => setEditingData((prev) => ({ ...prev, gume: e.target.value }))} className="py-1 px-3 w-full " placeholder="gume" /> </td>
                                                    </tr>
                                                )
                                            } else {
                                                return (
                                                    <tr onDoubleClick={() => {
                                                        setEditingZaliha(data._id)
                                                        handleEditContainer(data._id)
                                                    }} onClick={() => setEditingZaliha(null)} className={`border-b border-black ${data.dostupnost ? data.dostupnost.toLowerCase().includes("u dolasku") ? 'bg-blue-200' : data.dostupnost.toLowerCase().includes("u prodaji") ? 'bg-green-200' : data.dostupnost.toLowerCase() === "u prodaji - komisiona" ? 'bg-green-200' : data.dostupnost.toLowerCase().includes("u pripremi") ? 'bg-orange-200' : data.dostupnost.toLowerCase().includes("rezervisano") ? 'bg-red-200' : '' : ''}`} >
                                                        <td style={{ minWidth: 25, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{i + 1}</td>
                                                        <td style={{ minWidth: 110, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.datum}</td>
                                                        <td style={{ minWidth: 50, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.idBroj}</td>
                                                        <td style={{ minWidth: 150, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.brojKljuceva}</td>
                                                        <td style={{ minWidth: 160, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.dostupnost}</td>
                                                        <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.tablice}</td>
                                                        <td style={{ minWidth: 90, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.dosleUzVozilo}</td>
                                                        <td style={{ minWidth: 200, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.vozilo}</td>
                                                        <td style={{ minWidth: 70, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.godiste}</td>
                                                        <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.vrstaMotora}</td>
                                                        <td style={{ minWidth: 80, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.snaga}</td>
                                                        <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{Number(data.kilometraza).toLocaleString(void 0, { maximumFractionDigits: 2 }).replace(",", ".")} KM</td>
                                                        <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.mjenjac}</td>
                                                        <td style={{ minWidth: 150, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.brojSasije}</td>
                                                        <td style={{ minWidth: 70, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.boja}</td>
                                                        <td style={{ minWidth: 140, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.ogasenaCijenaVozila}</td>
                                                        <td style={{ minWidth: 200, fontSize: 10 }} className='font-normal text-blue-500 py-1 px-3 text-left border-r border-black bg-white'><a target='_blank' href={data.olxLink}>{data.olxLink}</a></td>
                                                        <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.datumObjave}</td>
                                                        <td style={{ minWidth: 130, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.registrovanDo}</td>
                                                        <td style={{ minWidth: 150, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.zadnjiServisKmDatum}</td>
                                                        <td style={{ minWidth: 200, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.brojDanaOdKadaJeVoziloNaPlacu}</td>
                                                        <td style={{ minWidth: 130, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.velikiservis}</td>
                                                        <td style={{ minWidth: 160, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.servisnaistorija}</td>
                                                        <td style={{ minWidth: 140, fontSize: 10 }} className='font-normal text-center text-black py-1 px-3 text-left border-r border-black'>{data.gume}</td>
                                                    </tr>
                                                )
                                            }
                                        })
                                    }
                                </tbody>
                                {
                                    createZaliha ? (
                                        <tr onClick={(e) => e.stopPropagation()} className={`border-b border-black`} >
                                            <td style={{ minWidth: 25, fontSize: 10 }} className='font-normal py-1 px-3 text-black text-left border-r border-black'>{zalihe.length + 1}</td>
                                            <td style={{ minWidth: 110, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.datum} onChange={(e) => setFormData((prev) => ({ ...prev, datum: e.target.value }))} className="py-1 px-3 w-full " placeholder="datum" /> </td>
                                            <td style={{ minWidth: 50, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.idBroj} onChange={(e) => setFormData((prev) => ({ ...prev, idBroj: e.target.value }))} className="py-1 px-3 w-full " placeholder="ID Broj" /> </td>
                                            <td style={{ minWidth: 150, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.brojKljuceva} onChange={(e) => setFormData((prev) => ({ ...prev, brojKljuceva: e.target.value }))} className="py-1 px-3 w-full " placeholder="broj ključeva" /> </td>
                                            <td style={{ minWidth: 160, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.dostupnost} onChange={(e) => setFormData((prev) => ({ ...prev, dostupnost: e.target.value }))} className="py-1 px-3 w-full " placeholder="dostupnost" /> </td>
                                            <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.tablice} onChange={(e) => setFormData((prev) => ({ ...prev, tablice: e.target.value }))} className="py-1 px-3 w-full " placeholder="tablice" /> </td>
                                            <td style={{ minWidth: 90, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.dosleUzVozilo} onChange={(e) => setFormData((prev) => ({ ...prev, dosleUzVozilo: e.target.value }))} className="py-1 px-3 w-full " placeholder="Dosle uz vozilo?" /> </td>
                                            <td style={{ minWidth: 200, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.vozilo} onChange={(e) => setFormData((prev) => ({ ...prev, vozilo: e.target.value }))} className="py-1 px-3 w-full " placeholder="vozilo" /> </td>
                                            <td style={{ minWidth: 70, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.godiste} onChange={(e) => setFormData((prev) => ({ ...prev, godiste: e.target.value }))} className="py-1 px-3 w-full " placeholder="godište" /> </td>
                                            <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.vrstaMotora} onChange={(e) => setFormData((prev) => ({ ...prev, vrstaMotora: e.target.value }))} className="py-1 px-3 w-full " placeholder="zapreminu" /> </td>
                                            <td style={{ minWidth: 80, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.snaga} onChange={(e) => setFormData((prev) => ({ ...prev, snaga: e.target.value }))} className="py-1 px-3 w-full " placeholder="snagu (kw)" /> </td>
                                            <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.kilometraza} onChange={(e) => setFormData((prev) => ({ ...prev, kilometraza: e.target.value }))} className="py-1 px-3 w-full " placeholder="kilometražu" /> </td>
                                            <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.mjenjac} onChange={(e) => setFormData((prev) => ({ ...prev, mjenjac: e.target.value }))} className="py-1 px-3 w-full " placeholder="tip mjenjača" /> </td>
                                            <td style={{ minWidth: 150, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.brojSasije} onChange={(e) => setFormData((prev) => ({ ...prev, brojSasije: e.target.value }))} className="py-1 px-3 w-full " placeholder="broj šasije" /> </td>
                                            <td style={{ minWidth: 70, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.boja} onChange={(e) => setFormData((prev) => ({ ...prev, boja: e.target.value }))} className="py-1 px-3 w-full " placeholder="boju" /> </td>
                                            <td style={{ minWidth: 140, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.ogasenaCijenaVozila} onChange={(e) => setFormData((prev) => ({ ...prev, ogasenaCijenaVozila: e.target.value }))} className="py-1 px-3 w-full " placeholder="oglašenu cijenu" /> </td>
                                            <td style={{ minWidth: 200, fontSize: 10 }} className='font-normal text-blue-500 text-left border-r border-black bg-white'><input value={formData.olxLink} onChange={(e) => setFormData((prev) => ({ ...prev, olxLink: e.target.value }))} className="py-1 px-3 w-full " placeholder="link OLX-a" /> </td>
                                            <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.datumObjave} onChange={(e) => setFormData((prev) => ({ ...prev, datumObjave: e.target.value }))} className="py-1 px-3 w-full " placeholder="datum objave" /> </td>
                                            <td style={{ minWidth: 130, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.registrovanDo} onChange={(e) => setFormData((prev) => ({ ...prev, registrovanDo: e.target.value }))} className="py-1 px-3 w-full " placeholder="Registrovan DO" /> </td>
                                            <td style={{ minWidth: 150, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.zadnjiServisKmDatum} onChange={(e) => setFormData((prev) => ({ ...prev, zadnjiServisKmDatum: e.target.value }))} className="py-1 px-3 w-full " placeholder="zadnji servis km/datum" /> </td>
                                            <td style={{ minWidth: 200, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.brojDanaOdKadaJeVoziloNaPlacu} onChange={(e) => setFormData((prev) => ({ ...prev, brojDanaOdKadaJeVoziloNaPlacu: e.target.value }))} className="py-1 px-3 w-full " placeholder="broj dana od kad je vozilo na lageru" /> </td>
                                            <td style={{ minWidth: 130, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.velikiservis} onChange={(e) => setFormData((prev) => ({ ...prev, velikiservis: e.target.value }))} className="py-1 px-3 w-full " placeholder="veliki servis" /> </td>
                                            <td style={{ minWidth: 160, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.servisnaistorija} onChange={(e) => setFormData((prev) => ({ ...prev, servisnaistorija: e.target.value }))} className="py-1 px-3 w-full " placeholder="servisnu istoriju" /> </td>
                                            <td style={{ minWidth: 140, fontSize: 10 }} className='font-normal text-center text-black text-left border-r border-black'><input value={formData.gume} onChange={(e) => setFormData((prev) => ({ ...prev, gume: e.target.value }))} className="py-1 px-3 w-full " placeholder="gume" /> </td>
                                        </tr>
                                    ) : null
                                }
                            </table>
                        </div>
                        {
                            editingZaliha ? <button className='fixed top-4 right-4 p-2 text-white text-sm uppercase' style={{ backgroundColor: '#d22829' }} onClick={applyEdit}>Potvrdi izmjene</button> : null
                        }
                        <button onClick={() => createZaliha ? handleArraySubmit() : setCreateZaliha(true)} className='bg-transparent text-center w-full p-2 text-xs text-white mt-3' style={{ backgroundColor: '#d22829' }}>Kreiraj zalihu</button>
                    </div>
                ) : (
                        <div onClick={() => setCreateZaliha(null)} className='px-3 pb-3'>
                        <div className='text-xl mb-3'>
                            <button onClick={() => setActivePage(0)} className={`w-1/2 font-regular p-3 text-sm bg-gray-200 border-b-2 ${activePage === 0 ? 'border-b-red-600' : null}`}>Dostupno na zalihama</button>
                            <button onClick={() => setActivePage(1)} className={`w-1/2 font-regular p-3 text-sm bg-gray-200 border-b-2 ${activePage === 1 ? 'border-b-red-600' : null}`}>Spremno za isporuku / Prodato</button>
                        </div>
                        <div className='zaliha-arenatable overflow-x-auto'>
                                <table ref={jsxContentRefT} className='border border-black'>
                                <thead>
                                    <tr>
                                        <th style={{ minWidth: 25, height: 25, fontSize: 10, width: 25 }} className='font-normal bg-black text-white px-1 text-left border-r'>ID</th>
                                        <th style={{ minWidth: 110, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Datum</th>
                                        <th style={{ minWidth: 50, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>ID broj</th>
                                        <th style={{ minWidth: 150, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Broj ključeva</th>
                                        <th style={{ minWidth: 160, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>DOSTUPNOST</th>
                                        <th style={{ minWidth: 100, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Tablice</th>
                                        <th style={{ minWidth: 90, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Došle uz vozilo</th>
                                        <th style={{ minWidth: 200, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Vozilo</th>
                                        <th style={{ minWidth: 70, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Godište</th>
                                        <th style={{ minWidth: 100, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Vrsta motora</th>
                                        <th style={{ minWidth: 80, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Snaga KW</th>
                                        <th style={{ minWidth: 100, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Kilometraža</th>
                                        <th style={{ minWidth: 100, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Tip mjenjača</th>
                                        <th style={{ minWidth: 150, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>BROJ ŠASIJE</th>
                                        <th style={{ minWidth: 70, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Boja</th>
                                        <th style={{ minWidth: 140, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Oglašena cijena vozila</th>
                                        <th style={{ minWidth: 200, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Link sa OLX-a</th>
                                        <th style={{ minWidth: 100, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Datum objave</th>
                                        <th style={{ minWidth: 130, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Registrovano DO</th>
                                        <th style={{ minWidth: 150, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Zadnji servis km/datum</th>
                                        <th style={{ minWidth: 200, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>Broj dana od kada je vozilo na placu</th>
                                        <th style={{ minWidth: 130, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>VELIKI SERVIS</th>
                                        <th style={{ minWidth: 160, height: 25, fontSize: 10 }} className='font-normal bg-black text-white px-1 text-left border-r'>SERVISNA ISTORIJA</th>
                                        <th style={{ minWidth: 140, height: 25, fontSize: 10 }} className='font-normal text-center bg-black text-white px-1 text-left border-r'>GUME/LJETNE/ZIMSKE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        zalihe.filter((data) => data.dostupnost ? data.dostupnost.toLowerCase().includes("isporuka") || data.dostupnost.toLowerCase().includes("isporuku") || data.dostupnost.toLowerCase() === "prodato" : null).map((data, i) => {
                                            if (editingZaliha === data._id) {
                                                return (
                                                    <tr className={`border-b border-black`} >
                                                        <td style={{ minWidth: 25, fontSize: 10 }} className='font-normal py-1 px-3 text-black text-left border-r border-black'>{zalihe.length + 1}</td>
                                                        <td style={{ minWidth: 110, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.datum} onChange={(e) => setEditingData((prev) => ({ ...prev, datum: e.target.value }))} className="py-1 px-3 w-full " placeholder="datum" /> </td>
                                                        <td style={{ minWidth: 50, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.idBroj} onChange={(e) => setEditingData((prev) => ({ ...prev, idBroj: e.target.value }))} className="py-1 px-3 w-full " placeholder="ID Broj" /> </td>
                                                        <td style={{ minWidth: 150, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.brojKljuceva} onChange={(e) => setEditingData((prev) => ({ ...prev, brojKljuceva: e.target.value }))} className="py-1 px-3 w-full " placeholder="broj ključeva" /> </td>
                                                        <td style={{ minWidth: 160, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.dostupnost} onChange={(e) => setEditingData((prev) => ({ ...prev, dostupnost: e.target.value }))} className="py-1 px-3 w-full " placeholder="dostupnost" /> </td>
                                                        <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.tablice} onChange={(e) => setEditingData((prev) => ({ ...prev, tablice: e.target.value }))} className="py-1 px-3 w-full " placeholder="tablice" /> </td>
                                                        <td style={{ minWidth: 90, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.dosleUzVozilo} onChange={(e) => setEditingData((prev) => ({ ...prev, dosleUzVozilo: e.target.value }))} className="py-1 px-3 w-full " placeholder="Dosle uz vozilo?" /> </td>
                                                        <td style={{ minWidth: 200, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.vozilo} onChange={(e) => setEditingData((prev) => ({ ...prev, vozilo: e.target.value }))} className="py-1 px-3 w-full " placeholder="vozilo" /> </td>
                                                        <td style={{ minWidth: 70, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.godiste} onChange={(e) => setEditingData((prev) => ({ ...prev, godiste: e.target.value }))} className="py-1 px-3 w-full " placeholder="godište" /> </td>
                                                        <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.vrstaMotora} onChange={(e) => setEditingData((prev) => ({ ...prev, vrstaMotora: e.target.value }))} className="py-1 px-3 w-full " placeholder="zapreminu" /> </td>
                                                        <td style={{ minWidth: 80, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.snaga} onChange={(e) => setEditingData((prev) => ({ ...prev, snaga: e.target.value }))} className="py-1 px-3 w-full " placeholder="snagu (kw)" /> </td>
                                                        <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.kilometraza} onChange={(e) => setEditingData((prev) => ({ ...prev, kilometraza: e.target.value }))} className="py-1 px-3 w-full " placeholder="kilometražu" /> </td>
                                                        <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.mjenjac} onChange={(e) => setEditingData((prev) => ({ ...prev, mjenjac: e.target.value }))} className="py-1 px-3 w-full " placeholder="tip mjenjača" /> </td>
                                                        <td style={{ minWidth: 150, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.brojSasije} onChange={(e) => setEditingData((prev) => ({ ...prev, brojSasije: e.target.value }))} className="py-1 px-3 w-full " placeholder="broj šasije" /> </td>
                                                        <td style={{ minWidth: 70, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.boja} onChange={(e) => setEditingData((prev) => ({ ...prev, boja: e.target.value }))} className="py-1 px-3 w-full " placeholder="boju" /> </td>
                                                        <td style={{ minWidth: 140, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.ogasenaCijenaVozila} onChange={(e) => setEditingData((prev) => ({ ...prev, ogasenaCijenaVozila: e.target.value }))} className="py-1 px-3 w-full " placeholder="oglašenu cijenu" /> </td>
                                                        <td style={{ minWidth: 200, fontSize: 10 }} className='font-normal text-blue-500 text-left border-r border-black bg-white'><input defaultValue={data.olxLink} onChange={(e) => setEditingData((prev) => ({ ...prev, olxLink: e.target.value }))} className="py-1 px-3 w-full " placeholder="link OLX-a" /> </td>
                                                        <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.datumObjave} onChange={(e) => setEditingData((prev) => ({ ...prev, datumObjave: e.target.value }))} className="py-1 px-3 w-full " placeholder="datum objave" /> </td>
                                                        <td style={{ minWidth: 130, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.registrovanDo} onChange={(e) => setEditingData((prev) => ({ ...prev, registrovanDo: e.target.value }))} className="py-1 px-3 w-full " placeholder="Registrovan DO" /> </td>
                                                        <td style={{ minWidth: 150, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.zadnjiServisKmDatum} onChange={(e) => setEditingData((prev) => ({ ...prev, zadnjiServisKmDatum: e.target.value }))} className="py-1 px-3 w-full " placeholder="zadnji servis km/datum" /> </td>
                                                        <td style={{ minWidth: 200, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.brojDanaOdKadaJeVoziloNaPlacu} onChange={(e) => setEditingData((prev) => ({ ...prev, brojDanaOdKadaJeVoziloNaPlacu: e.target.value }))} className="py-1 px-3 w-full " placeholder="broj dana od kad je vozilo na lageru" /> </td>
                                                        <td style={{ minWidth: 130, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.velikiservis} onChange={(e) => setEditingData((prev) => ({ ...prev, velikiservis: e.target.value }))} className="py-1 px-3 w-full " placeholder="veliki servis" /> </td>
                                                        <td style={{ minWidth: 160, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input defaultValue={data.servisnaistorija} onChange={(e) => setEditingData((prev) => ({ ...prev, servisnaistorija: e.target.value }))} className="py-1 px-3 w-full " placeholder="servisnu istoriju" /> </td>
                                                        <td style={{ minWidth: 140, fontSize: 10 }} className='font-normal text-center text-black text-left border-r border-black'><input defaultValue={data.gume} onChange={(e) => setEditingData((prev) => ({ ...prev, gume: e.target.value }))} className="py-1 px-3 w-full " placeholder="gume" /> </td>
                                                    </tr>
                                                )
                                            } else {
                                                return (
                                                    <tr onDoubleClick={() => {
                                                        setEditingZaliha(data._id)
                                                        handleEditContainer(data._id)
                                                    }} onClick={() => setEditingZaliha(null)} className={`border-b border-black bg-red-300`} >
                                                        <td style={{ minWidth: 25, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{i + 1}</td>
                                                        <td style={{ minWidth: 110, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.datum}</td>
                                                        <td style={{ minWidth: 50, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.idBroj}</td>
                                                        <td style={{ minWidth: 150, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.brojKljuceva}</td>
                                                        <td style={{ minWidth: 160, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.dostupnost}</td>
                                                        <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.tablice}</td>
                                                        <td style={{ minWidth: 90, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.dosleUzVozilo}</td>
                                                        <td style={{ minWidth: 200, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.vozilo}</td>
                                                        <td style={{ minWidth: 70, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.godiste}</td>
                                                        <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.vrstaMotora}</td>
                                                        <td style={{ minWidth: 80, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.snaga}</td>
                                                        <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{Number(data.kilometraza).toLocaleString(void 0, { maximumFractionDigits: 2 }).replace(",", ".")} KM</td>
                                                        <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.mjenjac}</td>
                                                        <td style={{ minWidth: 150, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.brojSasije}</td>
                                                        <td style={{ minWidth: 70, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.boja}</td>
                                                        <td style={{ minWidth: 140, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.ogasenaCijenaVozila}</td>
                                                        <td style={{ minWidth: 200, fontSize: 10 }} className='font-normal text-blue-500 py-1 px-3 text-left border-r border-black bg-white'><a target='_blank' href={data.olxLink}>{data.olxLink}</a></td>
                                                        <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.datumObjave}</td>
                                                        <td style={{ minWidth: 130, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.registrovanDo}</td>
                                                        <td style={{ minWidth: 150, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.zadnjiServisKmDatum}</td>
                                                        <td style={{ minWidth: 200, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.brojDanaOdKadaJeVoziloNaPlacu}</td>
                                                        <td style={{ minWidth: 130, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.velikiservis}</td>
                                                        <td style={{ minWidth: 160, fontSize: 10 }} className='font-normal text-black py-1 px-3 text-left border-r border-black'>{data.servisnaistorija}</td>
                                                        <td style={{ minWidth: 140, fontSize: 10 }} className='font-normal text-center text-black py-1 px-3 text-left border-r border-black'>{data.gume}</td>
                                                    </tr>
                                                )
                                            }
                                        })
                                    }
                                </tbody>
                                {
                                    createZaliha ? (
                                        <tr className={`border-b border-black`} >
                                            <td style={{ minWidth: 25, fontSize: 10 }} className='font-normal py-1 px-3 text-black text-left border-r border-black'>{zalihe.length + 1}</td>
                                            <td style={{ minWidth: 110, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.datum} onChange={(e) => setFormData((prev) => ({ ...prev, datum: e.target.value }))} className="py-1 px-3 w-full " placeholder="datum" /> </td>
                                            <td style={{ minWidth: 50, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.idBroj} onChange={(e) => setFormData((prev) => ({ ...prev, idBroj: e.target.value }))} className="py-1 px-3 w-full " placeholder="ID Broj" /> </td>
                                            <td style={{ minWidth: 150, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.brojKljuceva} onChange={(e) => setFormData((prev) => ({ ...prev, brojKljuceva: e.target.value }))} className="py-1 px-3 w-full " placeholder="broj ključeva" /> </td>
                                            <td style={{ minWidth: 160, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.dostupnost} onChange={(e) => setFormData((prev) => ({ ...prev, dostupnost: e.target.value }))} className="py-1 px-3 w-full " placeholder="dostupnost" /> </td>
                                            <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.tablice} onChange={(e) => setFormData((prev) => ({ ...prev, tablice: e.target.value }))} className="py-1 px-3 w-full " placeholder="tablice" /> </td>
                                            <td style={{ minWidth: 90, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.dosleUzVozilo} onChange={(e) => setFormData((prev) => ({ ...prev, dosleUzVozilo: e.target.value }))} className="py-1 px-3 w-full " placeholder="Dosle uz vozilo?" /> </td>
                                            <td style={{ minWidth: 200, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.vozilo} onChange={(e) => setFormData((prev) => ({ ...prev, vozilo: e.target.value }))} className="py-1 px-3 w-full " placeholder="vozilo" /> </td>
                                            <td style={{ minWidth: 70, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.godiste} onChange={(e) => setFormData((prev) => ({ ...prev, godiste: e.target.value }))} className="py-1 px-3 w-full " placeholder="godište" /> </td>
                                            <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.vrstaMotora} onChange={(e) => setFormData((prev) => ({ ...prev, vrstaMotora: e.target.value }))} className="py-1 px-3 w-full " placeholder="zapreminu" /> </td>
                                            <td style={{ minWidth: 80, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.snaga} onChange={(e) => setFormData((prev) => ({ ...prev, snaga: e.target.value }))} className="py-1 px-3 w-full " placeholder="snagu (kw)" /> </td>
                                            <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.kilometraza} onChange={(e) => setFormData((prev) => ({ ...prev, kilometraza: e.target.value }))} className="py-1 px-3 w-full " placeholder="kilometražu" /> </td>
                                            <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.mjenjac} onChange={(e) => setFormData((prev) => ({ ...prev, mjenjac: e.target.value }))} className="py-1 px-3 w-full " placeholder="tip mjenjača" /> </td>
                                            <td style={{ minWidth: 150, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.brojSasije} onChange={(e) => setFormData((prev) => ({ ...prev, brojSasije: e.target.value }))} className="py-1 px-3 w-full " placeholder="broj šasije" /> </td>
                                            <td style={{ minWidth: 70, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.boja} onChange={(e) => setFormData((prev) => ({ ...prev, boja: e.target.value }))} className="py-1 px-3 w-full " placeholder="boju" /> </td>
                                            <td style={{ minWidth: 140, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.ogasenaCijenaVozila} onChange={(e) => setFormData((prev) => ({ ...prev, ogasenaCijenaVozila: e.target.value }))} className="py-1 px-3 w-full " placeholder="oglašenu cijenu" /> </td>
                                            <td style={{ minWidth: 200, fontSize: 10 }} className='font-normal text-blue-500 text-left border-r border-black bg-white'><input value={formData.olxLink} onChange={(e) => setFormData((prev) => ({ ...prev, olxLink: e.target.value }))} className="py-1 px-3 w-full " placeholder="link OLX-a" /> </td>
                                            <td style={{ minWidth: 100, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.datumObjave} onChange={(e) => setFormData((prev) => ({ ...prev, datumObjave: e.target.value }))} className="py-1 px-3 w-full " placeholder="datum objave" /> </td>
                                            <td style={{ minWidth: 130, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.registrovanDo} onChange={(e) => setFormData((prev) => ({ ...prev, registrovanDo: e.target.value }))} className="py-1 px-3 w-full " placeholder="Registrovan DO" /> </td>
                                            <td style={{ minWidth: 150, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.zadnjiServisKmDatum} onChange={(e) => setFormData((prev) => ({ ...prev, zadnjiServisKmDatum: e.target.value }))} className="py-1 px-3 w-full " placeholder="zadnji servis km/datum" /> </td>
                                            <td style={{ minWidth: 200, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.brojDanaOdKadaJeVoziloNaPlacu} onChange={(e) => setFormData((prev) => ({ ...prev, brojDanaOdKadaJeVoziloNaPlacu: e.target.value }))} className="py-1 px-3 w-full " placeholder="broj dana od kad je vozilo na lageru" /> </td>
                                            <td style={{ minWidth: 130, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.velikiservis} onChange={(e) => setFormData((prev) => ({ ...prev, velikiservis: e.target.value }))} className="py-1 px-3 w-full " placeholder="veliki servis" /> </td>
                                            <td style={{ minWidth: 160, fontSize: 10 }} className='font-normal text-black text-left border-r border-black'><input value={formData.servisnaistorija} onChange={(e) => setFormData((prev) => ({ ...prev, servisnaistorija: e.target.value }))} className="py-1 px-3 w-full " placeholder="servisnu istoriju" /> </td>
                                            <td style={{ minWidth: 140, fontSize: 10 }} className='font-normal text-center text-black text-left border-r border-black'><input value={formData.gume} onChange={(e) => setFormData((prev) => ({ ...prev, gume: e.target.value }))} className="py-1 px-3 w-full " placeholder="gume" /> </td>
                                        </tr>
                                    ) : null
                                }
                            </table>
                        </div>
                        {
                            editingZaliha ? <button className='fixed top-4 right-4 p-2 text-white text-sm uppercase' style={{ backgroundColor: '#d22829' }} onClick={applyEdit}>Potvrdi izmjene</button> : null
                        }
                        <button onClick={() => createZaliha ? handleArraySubmit() : setCreateZaliha(true)} className='bg-transparent text-center w-full p-2 text-xs text-white mt-3' style={{ backgroundColor: '#d22829' }}>Kreiraj zalihu</button>
                    </div>
                )
            }

        </>
    )
}

export default ZalihaArena