import React, { useEffect, useState } from 'react'
import { useAppData } from '../../context/ApplicationProvider';
import axios from 'axios';
import io from 'socket.io-client';
import { message } from 'antd';
const socket = io("https://app.arenabackend.org", {
    reconnection: true
})

function ChatsPage() {

    const { users, zadaci } = useAppData();
    const [messageForm, setMessageForm] = useState({
        sender: sessionStorage.getItem('user'),
        reciever: "",
        content: ""
    })
    const [messageApi, contextHolder] = message.useMessage();
    const [channels, setChannels] = useState([])

    const fetchChannels = async () => {
        await fetch(`https://app.arenabackend.org/chats/channels/${messageForm.sender}`)
            .then((res) => res.json())
            .then((response) => {
                setChannels(response)
            })
            .catch((e) => {
                messageApi.open({
                    type: 'error',
                    content: 'Greska prilikom izvlacenja DM-ova',
                });
            })
    }

    useEffect(() => {
        fetchChannels()
    })

    // const [channelId, setChannelId] = useState("");
    // const [messages, setMessages] = useState([])

    // const messageForm.sender = sessionStorage.getItem('user')

    // const submit = async () => {
    //     try {
    //         const response = await fetch(`https://app.arenabackend.org/chats/send-message/${messageForm.reciever}/${messageForm.sender}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({
    //                 content: messageForm.content
    //             }),
    //         });

    //         if (response.ok) {
    //             const responseData = await response.json();
    //             console.log(responseData);
    //             messageApi.open({
    //                 type: 'success',
    //                 content: `Uspijesno ste poslali poruku ${users.filter((user) => user._id.trim() === messageForm.reciever.trim()).map((user) => user.name)}`,
    //             });
    //             socket.emit('poslataPoruka', messageForm.reciever, `Uspijesno ste poslali poruku ${users.filter((user) => user._id.trim() === messageForm.reciever.trim()).map((user) => user.name)}`)
    //             // Optionally, handle the response data (e.g., update UI, show success message)
    //         } else {
    //             console.error('Error:', response.status);
    //             // Optionally, handle the error (e.g., show error message)
    //         }

    //         // Optionally, perform actions after the fetch request (e.g., show success message)
    //     } catch (error) {
    //         console.error('Error:', error);
    //         alert("Greška prilikom slanja zahtjeva");
    //     }
    // };


    // async function fetchMessages(channelId) {
    //     await fetch(`https://app.arenabackend.org/chats/channels/${channelId}`).then((res) => res.json()).then((response) => setMessages(response)).catch((e) => console.log(e))
    // }

    // const createChannel = async () => {
    //     try {
    //         const senderId = sessionStorage.getItem('user');
    //         const receiverId = messageForm.reciever;

    //         // Check if a channel already exists
    //         const existingChannel = await axios.get(`https://app.arenabackend.org/chats/check-channel/${senderId}/${receiverId}`);


    //         if (existingChannel.data) {
    //             // Channel already exists, update channelId in messageForm
    //             setChannelId(existingChannel.data.channel);

    //             // Return existing channel ID
    //             return existingChannel.data.channel;
    //         }

    //         if (existingChannel.channel === null) {
    //             // If channel doesn't exist, create a new one
    //             const response = await axios.post(`https://app.arenabackend.org/chats/create-channel/${senderId}/${receiverId}`, {
    //                 name: "DynamicChannelName" // You might want to make this dynamic
    //             });

    //             // Update messageForm.channelId based on the created channel ID
    //             setMessageForm((prev) => ({ ...prev, channelId: response.data.channel._id }));

    //             // Return the newly created channel ID
    //             return response.data.channel._id;
    //         }

    //     } catch (error) {
    //         console.error('Error:', error);
    //         alert("Greška prilikom slanja zahtjeva");
    //     }
    // };

    // // Use this function to set messageForm and fetch messages
    // const handleUserClick = async (user) => {
    //     try {
    //         const { _id: userId } = user;

    //         setMessageForm((prev) => ({ ...prev, reciever: userId }));

    //         // Create or retrieve the channel ID
    //         const channelId = await createChannel();

    //         // Fetch messages for the newly created or existing channel
    //         await fetchMessages(channelId);

    //     } catch (error) {
    //         console.error('Error:', error);
    //         // Handle errors appropriately
    //     }
    // };

    return (
        <div className='h-full flex items-start' style={{ height: '100vh' }}>
            {contextHolder}
            <div className='h-full flex items-start w-full'>
                <div className='w-2/12 h-full bg-gray-50 border-r' style={{ float: 'left' }}>
                    {/* {
                        users.filter((user) => user._id.trim() !== messageForm.sender.trim()).map((user) => (
                            <button onClick={() => setMessageForm((prev) => ({ ...prev, reciever: user._id }))} key={user._id} className='w-full p-3 text-black hover:bg-red-500 hover:text-white text-left'>
                                <h1>{user.name}</h1>
                                <p className='line-clamp-1 text-xs opacity-50'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio suscipit, illo excepturi quis rem atque harum hic beatae, recusandae corrupti, porro molestias aliquam facilis cupiditate quaerat labore libero doloremque a?</p>
                            </button>
                        ))
                    } */}
                    {
                        channels.map((channel) => {
                            return (
                                <button className='w-full p-3 text-black hover:bg-red-500 hover:text-white text-left'>
                                    {
                                        channel.members.map((member) => {
                                            return (
                                                <button onClick={() => setMessageForm((prev) => ({ ...prev, reciever: users.filter((user) => user._id.trim() === member.trim() && user._id.trim() !== messageForm.sender.trim()).map((user) => user._id) }))}>
                                                    <h1>{users.filter((user) => user._id.trim() === member.trim() && user._id.trim() !== messageForm.sender.trim()).map((user) => user.name)}</h1>
                                                </button>
                                            )
                                        })
                                    }
                                </button>
                            )
                        })
                    }
                </div>
                {
                    messageForm.reciever ? (
                        <div className="message-container h-full bg-gray-200 relative" style={{ flex: 1 }}>
                            <div className="message-header bg-white p-3 shadow-lg absolute w-full top-0 left-0 flex items-center" style={{ height: 50 }}>
                                <h3 className='text-sm font-medium line-clamp-1'>{users.filter((user) => user._id.trim() === messageForm.reciever.trim()).map((user) => user.name)}</h3>
                            </div>
                            {/* <div className="message-content w-full flex flex-wrap px-3" style={{ flex: 1, paddingTop: 65 }}>
                                {messages.map((msg) => {
                                    return (
                                        <div key={msg._id} className="message-container w-full" style={{ maxHeight: 'fit-content' }}>
                                            <div className={`bg-white w-fit text-sm py-2 px-4 rounded rounded-br-lg rounded-bl-lg ${message.sender === messageForm.sender ? 'rounded-tl-lg float-right' : 'rounded-tr-lg float-left'}`}>
                                                <h1>{msg.content}</h1>
                                            </div>
                                        </div>
                                    )
                                }

                                )}
                            </div> */}

                            <div className="send-message-container absolute w-full bottom-0 left-0 p-2 bg-white flex items-center gap-2">
                                <input defaultValue={messageForm.content} onChange={(e) => setMessageForm((prev) => ({ ...prev, content: e.target.value }))} className='text-sm bg-gray-100 outline-none px-4 py-2 rounded-full w-11/12' type="text" placeholder='Unesite poruku' />
                                <button className='w-1/12 bg-red-600 text-white p-2 rounded-full text-sm border-none'>Pošalji</button>
                            </div>
                        </div>
                    ) : null
                }

            </div>
        </div>
    )
}

export default ChatsPage