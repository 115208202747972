import { AutoComplete, Checkbox, Input, InputNumber, Switch, message } from 'antd'
import React, { useState } from 'react'

import { Check, InboxIcon, X } from 'lucide-react'
import axios from 'axios'
import Dragger from 'antd/es/upload/Dragger'



function ProcjenaVozila() {
    const [messageApi, contextHolder] = message.useMessage();

    const [formData, setFormData] = useState({
        zamjenaZaVozilo: '',
        vozilo: '',
        godiste: 2024,
        sasija: '',
        kilometraza: 0,
        realnoStanjeKM: 0,
        brojKljuceva: 1,
        registrovanDo: '',
        desnaStranicaLakirano: false,
        desnaStranicaUlaganje: false,
        desnaStranaStokLakirano: false,
        desnaStranaStokUlaganje: false,
        desnaStranaZadnjaVrataLakirano: false,
        desnaStranaZadnjaVrataUlaganje: false,
        desnaStranaPrednjaVrataLakirano: false,
        desnaStranaPrednjaVrataUlaganje: false,
        desnaStranaDesniBlatobranLakirano: false,
        desnaStranaDesniBlatobranUlaganje: false,
        lijevaStranicaLakirano: false,
        lijevaStranicaUlaganje: false,
        lijevaStranaStokLakirano: false,
        lijevaStranaStokUlaganje: false,
        lijevaStranaZadnjaVrataLakirano: false,
        lijevaStranaZadnjaVrataUlaganje: false,
        lijevaStranaPrednjaVrataLakirano: false,
        lijevaStranaPrednjaVrataUlaganje: false,
        lijevaStranaLijeviBlatobranLakirano: false,
        lijevaStranaLijeviBlatobranUlaganje: false,

        prenjiBranikLakirano: false,
        prenjiBranikUlaganje: false,
        haubaLakirano: false,
        haubaUlaganje: false,
        gepekLakirano: false,
        gepekUlaganje: false,
        zadnjiBranikLakirano: false,
        zadnjiBranikUlaganje: false,
        krovLakirano: false,
        krovUlaganje: false,
        pdrUdubljena: false,
        ccaPrice: 0,
        carVertical: false,
        alarm: '',
        servisnaKnjiga: false,
        servisnaKnjigaContent: '',
        drugiSetTockova: false,
        drugiSetTockovaContent: '',
        motorImjenjacUlaganja: 0,
        trapUlaganja: 0,
        limarijaUlaganja: 0,
        lakiranjeUlaganja: 0,
        poliranjeUlaganja: 0,
        dubinskoUlaganja: 0,
        presvlacenjeUlaganja: 0,
        felgeIgumeUlaganja: 0,
        ostalaUlaganja: 0,
        ukupnoUlaganja: 0,
        images: []
    })
    // const [images, setImages] = useState([]);

    const customRequest = ({ onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const beforeUpload = (file) => {
        // Assuming setFormData is the function to update formData state
        setFormData((prevFormData) => ({
            ...prevFormData,
            images: [...prevFormData.images, file],
        }));

        // Prevent the default file upload behavior
        return false;
    };


    const handleRemove = (file) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            images: prevFormData.images.filter(img => img !== file),
        }));
    };

    const props = {
        multiple: true,
        beforeUpload,
        customRequest,
        onRemove: handleRemove,
        fileList: formData.images,
    };



    const calcUkupnoUlaganje = formData.motorImjenjacUlaganja + formData.trapUlaganja + formData.limarijaUlaganja + formData.lakiranjeUlaganja + formData.poliranjeUlaganja + formData.dubinskoUlaganja + formData.presvlacenjeUlaganja + formData.felgeIgumeUlaganja + formData.ostalaUlaganja


    const submit = async (e) => {
        e.preventDefault();
        try {
            

            const formDataToSend = new FormData();
            formDataToSend.append('zamjenaZaVozilo', formData.zamjenaZaVozilo)
            formDataToSend.append('vozilo', formData.vozilo)
            formDataToSend.append('godiste', formData.godiste)
            formDataToSend.append('sasija', formData.sasija)
            formDataToSend.append('kilometraza', formData.kilometraza)
            formDataToSend.append('realnoStanjeKM', formData.realnoStanjeKM)
            formDataToSend.append('brojKljuceva', formData.brojKljuceva)
            formDataToSend.append('registrovanDo', formData.registrovanDo)
            formDataToSend.append('desnaStranicaLakirano', formData.desnaStranicaLakirano)
            formDataToSend.append('desnaStranicaUlaganje', formData.desnaStranicaUlaganje)
            formDataToSend.append('desnaStranaStokLakirano', formData.desnaStranaStokLakirano)
            formDataToSend.append('desnaStranaStokUlaganje', formData.desnaStranaStokUlaganje)
            formDataToSend.append('desnaStranaZadnjaVrataLakirano', formData.desnaStranaZadnjaVrataLakirano)
            formDataToSend.append('desnaStranaZadnjaVrataUlaganje', formData.desnaStranaZadnjaVrataUlaganje)
            formDataToSend.append('desnaStranaPrednjaVrataLakirano', formData.desnaStranaPrednjaVrataLakirano)
            formDataToSend.append('desnaStranaPrednjaVrataUlaganje', formData.desnaStranaPrednjaVrataUlaganje)
            formDataToSend.append('desnaStranaDesniBlatobranLakirano', formData.desnaStranaDesniBlatobranLakirano)
            formDataToSend.append('desnaStranaDesniBlatobranUlaganje', formData.desnaStranaDesniBlatobranUlaganje)
            formDataToSend.append('lijevaStranicaLakirano', formData.lijevaStranicaLakirano)
            formDataToSend.append('lijevaStranicaUlaganje', formData.lijevaStranicaUlaganje)
            formDataToSend.append('lijevaStranaStokLakirano', formData.lijevaStranaStokLakirano)
            formDataToSend.append('lijevaStranaStokUlaganje', formData.lijevaStranaStokUlaganje)
            formDataToSend.append('lijevaStranaZadnjaVrataLakirano', formData.lijevaStranaZadnjaVrataLakirano)
            formDataToSend.append('lijevaStranaZadnjaVrataUlaganje', formData.lijevaStranaZadnjaVrataUlaganje)
            formDataToSend.append('lijevaStranaPrednjaVrataLakirano', formData.lijevaStranaPrednjaVrataLakirano)
            formDataToSend.append('lijevaStranaPrednjaVrataUlaganje', formData.lijevaStranaPrednjaVrataUlaganje)
            formDataToSend.append('lijevaStranaLijeviBlatobranLakirano', formData.lijevaStranaLijeviBlatobranLakirano)
            formDataToSend.append('lijevaStranaLijeviBlatobranUlaganje', formData.lijevaStranaLijeviBlatobranUlaganje)
            formDataToSend.append('prenjiBranikLakirano', formData.prenjiBranikLakirano)
            formDataToSend.append('prenjiBranikUlaganje', formData.prenjiBranikUlaganje)
            formDataToSend.append('haubaLakirano', formData.haubaLakirano)
            formDataToSend.append('haubaUlaganje', formData.haubaUlaganje)
            formDataToSend.append('gepekLakirano', formData.gepekLakirano)
            formDataToSend.append('gepekUlaganje', formData.gepekUlaganje)
            formDataToSend.append('zadnjiBranikLakirano', formData.zadnjiBranikLakirano)
            formDataToSend.append('zadnjiBranikUlaganje', formData.zadnjiBranikUlaganje)
            formDataToSend.append('krovLakirano', formData.krovLakirano)
            formDataToSend.append('krovUlaganje', formData.krovUlaganje)
            formDataToSend.append('pdrUdubljena', formData.pdrUdubljena)
            formDataToSend.append('ccaPrice', formData.ccaPrice)
            formDataToSend.append('carVertical', formData.carVertical)
            formDataToSend.append('alarm', formData.alarm)
            formDataToSend.append('servisnaKnjiga', formData.servisnaKnjiga)
            formDataToSend.append('servisnaKnjigaContent', formData.servisnaKnjigaContent)
            formDataToSend.append('drugiSetTockova', formData.drugiSetTockova)
            formDataToSend.append('drugiSetTockovaContent', formData.drugiSetTockovaContent)
            formDataToSend.append('motorImjenjacUlaganja', formData.motorImjenjacUlaganja)
            formDataToSend.append('trapUlaganja', formData.trapUlaganja)
            formDataToSend.append('limarijaUlaganja', formData.limarijaUlaganja)
            formDataToSend.append('lakiranjeUlaganja', formData.lakiranjeUlaganja) 
            formDataToSend.append('poliranjeUlaganja', formData.poliranjeUlaganja)
            formDataToSend.append('dubinskoUlaganja', formData.dubinskoUlaganja)
            formDataToSend.append('presvlacenjeUlaganja', formData.presvlacenjeUlaganja)
            formDataToSend.append('felgeIgumeUlaganja', formData.felgeIgumeUlaganja)
            formDataToSend.append('ostalaUlaganja', formData.ostalaUlaganja)
            formDataToSend.append('ukupnoUlaganja', calcUkupnoUlaganje)
            for (let i = 0; i < formData.images.length; i++) {
                formDataToSend.append('images', formData.images[i]);
            }

            const response = await axios.post('https://app.arenabackend.org/procjene/postavi-procjenu', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (!response) {
                messageApi.open({
                    type: 'error',
                    content: 'Greška prilikom kreiranja procjene',
                });
            }

            messageApi.open({
                type: 'success',
                content: 'Uspiješno kreiranje procjene',
            });
            // Handle success or redirect
        } catch (error) {
            console.log(error)
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    return (
        <div className='px-2 pb-3'>
            {contextHolder}
            <h1 className='font-bold pt-2'>PROCJENA VOZILA</h1>
            <div className="app-form-container flex flex-col gap-4 pt-4 w-1/2" style={{ minWidth: 1000 }}>
                <Dragger className='w-full' {...props}>
                    <p className="ant-upload-drag-icon flex items-center justify-center">
                        <InboxIcon size={30} />
                    </p>
                    <p className="ant-upload-text">Pritisnite ili prevucite fajlove za upload</p>
                    <p className="ant-upload-hint">
                        Podrška za jedno ili grupno otpremanje. Strogo je zabranjeno učitavanje podataka kompanije ili drugog
                    </p>
                </Dragger>
                <div>
                    <p className=' w-full text-xs pb-1 font-semibold'>Zamjena za vozilo</p>
                    <Input onChange={handleInputChange} defaultValue={formData.zamjenaZaVozilo} name='zamjenaZaVozilo' className=' w-full p-2 ' placeholder='Unesite vozilo za zamjenu' />
                </div>
                <div>
                    <p className=' w-full text-xs pb-1 font-semibold'>Vozilo (marka, tip)</p>
                    <Input onChange={handleInputChange} defaultValue={formData.vozilo} name='vozilo' className=' w-full p-2 ' placeholder='Unesite vozilo' />
                </div>
                <div>
                    <p className=' w-full text-xs pb-1 font-semibold'>Godište</p>
                    <InputNumber onChange={(value) => setFormData((prevFormData) => ({ ...prevFormData, godiste: value }))} defaultValue={formData.godiste} name='godiste' className=' w-full p-2 ' min={1990} max={2025} />
                </div>
                <div>
                    <p className=' w-full text-xs pb-1 font-semibold'>Šasija</p>
                    <Input onChange={handleInputChange} defaultValue={formData.sasija} name='sasija' className=' w-full p-2 ' placeholder='Unesite šasiju' />
                </div>
                <div>
                    <p className=' w-full text-xs pb-1 font-semibold'>Kilometraža</p>
                    <InputNumber onChange={(value) => setFormData((prevFormData) => ({ ...prevFormData, kilometraza: value }))} defaultValue={formData.kilometraza} name='kilometraza' className=' w-full ' addonAfter="km" />
                </div>
                <div className=' w-full flex items-center gap-2'>
                    <p className=' w-full text-sm'>Realno stanje ?</p>
                    <Switch onChange={() => setFormData((prevFormData) => ({ ...prevFormData, realnoStanjeKM: !formData.realnoStanjeKM }))} defaultValue={formData.realnoStanjeKM} name='realnoStanjeKM' className='pt-0.5 bg-gray-300' checkedChildren={<Check size={19} />} unCheckedChildren={<X size={19} className=' w-full mt-0.5' />} />
                </div>
                <div>
                    <p className=' w-full text-xs pb-1 font-semibold'>Broj ključeva</p>
                    <InputNumber onChange={(value) => setFormData((prevFormData) => ({ ...prevFormData, brojKljuceva: value }))} defaultValue={formData.brojKljuceva} name='brojKljuceva' className=' w-full p-1 ' min={1} max={2} />
                </div>
                <div>
                    <p className=' w-full text-xs pb-1 font-semibold'>Registrovan do</p>
                    <select defaultValue={formData.registrovanDo} name='registrovanDo' className=' w-full  border py-2.5 border-gray-300 px-2 rounded-md text-sm' placeholder='Izaberi gorivo'>
                        <option defaultValue='03/2024'>03/2024</option>
                    </select>
                </div>
                <div className="car-container">
                    <table className=' w-full  bg-gray-50'>
                        <thead className=' w-full bg-gray-200'>
                            <tr className=' w-full border-b'>
                                <th className=' w-full font-bold text-xs uppercase text-left p-3 border-r border-r-gray-50'>Desna Strana</th>
                                <th className=' w-full font-bold text-xs uppercase text-left p-3 border-r border-r-gray-50'>Lakirano</th>
                                <th className=' w-full font-bold text-xs uppercase text-left p-3'>Ulaganje</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className=' w-full border-b'>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'>Desna stranica</td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, desnaStranicaLakirano: !formData.desnaStranicaLakirano }))} name='desnaStranicaLakirano' defaultValue={formData.desnaStranicaLakirano} /></td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, desnaStranicaUlaganje: !formData.desnaStranicaUlaganje }))} name='desnaStranicaUlaganje' defaultValue={formData.desnaStranicaUlaganje} /></td>
                            </tr>
                            <tr className=' w-full border-b'>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'>Štok</td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, desnaStranaStokLakirano: !formData.desnaStranaStokLakirano }))} name='desnaStranaStokLakirano' defaultValue={formData.desnaStranaStokLakirano} /></td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, desnaStranaStokUlaganje: !formData.desnaStranaStokUlaganje }))} name='desnaStranaStokUlaganje' defaultValue={formData.desnaStranaStokUlaganje} /></td>
                            </tr>
                            <tr className=' w-full border-b'>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'>Zadnja vrata</td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, desnaStranaZadnjaVrataLakirano: !formData.desnaStranaZadnjaVrataLakirano }))} name='desnaStranaZadnjaVrataLakirano' defaultValue={formData.desnaStranaZadnjaVrataLakirano} /></td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, desnaStranaZadnjaVrataUlaganje: !formData.desnaStranaZadnjaVrataUlaganje }))} name='desnaStranaZadnjaVrataUlaganje' defaultValue={formData.desnaStranaZadnjaVrataUlaganje} /></td>
                            </tr>
                            <tr className=' w-full border-b'>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'>Prednja vrata</td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, desnaStranaPrednjaVrataLakirano: !formData.desnaStranaPrednjaVrataLakirano }))} name='desnaStranaPrednjaVrataLakirano' defaultValue={formData.desnaStranaPrednjaVrataLakirano} /></td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, desnaStranaPrednjaVrataUlaganje: !formData.desnaStranaPrednjaVrataUlaganje }))} name='desnaStranaPrednjaVrataUlaganje' defaultValue={formData.desnaStranaPrednjaVrataUlaganje} /></td>
                            </tr>
                            <tr className=' w-full '>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'>Desni blatobran</td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, desnaStranaDesniBlatobranLakirano: !formData.desnaStranaDesniBlatobranLakirano }))} name='desnaStranaDesniBlatobranLakirano' defaultValue={formData.desnaStranaDesniBlatobranLakirano} /></td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, desnaStranaDesniBlatobranUlaganje: !formData.desnaStranaDesniBlatobranUlaganje }))} name='desnaStranaDesniBlatobranUlaganje' defaultValue={formData.desnaStranaDesniBlatobranUlaganje} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="car-container">
                    <table className=' w-full  bg-gray-50'>
                        <thead className=' w-full bg-gray-200'>
                            <tr className=' w-full border-b'>
                                <th className=' w-full font-bold text-xs uppercase text-left p-3 border-r border-r-gray-50'>Lijeva Strana</th>
                                <th className=' w-full font-bold text-xs uppercase text-left p-3 border-r border-r-gray-50'>Lakirano</th>
                                <th className=' w-full font-bold text-xs uppercase text-left p-3'>Ulaganje</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className=' w-full border-b'>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'>Lijeva stranica</td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, lijevaStranicaLakirano: !formData.lijevaStranicaLakirano }))} name='lijevaStranicaLakirano' defaultValue={formData.lijevaStranicaLakirano} /></td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, lijevaStranicaUlaganje: !formData.lijevaStranicaUlaganje }))} name='lijevaStranicaUlaganje' defaultValue={formData.lijevaStranicaUlaganje} /></td>
                            </tr>
                            <tr className=' w-full border-b'>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'>Štok</td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, lijevaStranaStokLakirano: !formData.lijevaStranaStokLakirano }))} name='lijevaStranaStokLakirano' defaultValue={formData.lijevaStranaStokLakirano} /></td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, lijevaStranaStokUlaganje: !formData.lijevaStranaStokUlaganje }))} name='lijevaStranaStokUlaganje' defaultValue={formData.lijevaStranaStokUlaganje} /></td>
                            </tr>
                            <tr className=' w-full border-b'>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'>Zadnja vrata</td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, lijevaStranaZadnjaVrataLakirano: !formData.lijevaStranaZadnjaVrataLakirano }))} name='lijevaStranaZadnjaVrataLakirano' defaultValue={formData.lijevaStranaZadnjaVrataLakirano} /></td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, lijevaStranaZadnjaVrataUlaganje: !formData.lijevaStranaZadnjaVrataUlaganje }))} name='lijevaStranaZadnjaVrataUlaganje' defaultValue={formData.lijevaStranaZadnjaVrataUlaganje} /></td>
                            </tr>
                            <tr className=' w-full border-b'>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'>Prednja vrata</td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, lijevaStranaPrednjaVrataLakirano: !formData.lijevaStranaPrednjaVrataLakirano }))} name='lijevaStranaPrednjaVrataLakirano' defaultValue={formData.lijevaStranaPrednjaVrataLakirano} /></td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, lijevaStranaPrednjaVrataUlaganje: !formData.lijevaStranaPrednjaVrataUlaganje }))} name='lijevaStranaPrednjaVrataUlaganje' defaultValue={formData.lijevaStranaPrednjaVrataUlaganje} /></td>
                            </tr>
                            <tr className=' w-full '>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'>Lijevi blatobran</td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, lijevaStranaLijeviBlatobranLakirano: !formData.lijevaStranaLijeviBlatobranLakirano }))} name='lijevaStranaLijeviBlatobranLakirano' defaultValue={formData.lijevaStranaLijeviBlatobranLakirano} /></td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, lijevaStranaLijeviBlatobranUlaganje: !formData.lijevaStranaLijeviBlatobranUlaganje }))} name='lijevaStranaLijeviBlatobranUlaganje' defaultValue={formData.lijevaStranaLijeviBlatobranUlaganje} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="car-container">
                    <table className=' w-full  bg-gray-50'>
                        <thead className=' w-full bg-gray-200'>
                            <tr className=' w-full border-b'>
                                <th className=' w-full font-bold text-xs uppercase text-left p-3 border-r border-r-gray-50'>Naprijed / Nazad</th>
                                <th className=' w-full font-bold text-xs uppercase text-left p-3 border-r border-r-gray-50'>Lakirano</th>
                                <th className=' w-full font-bold text-xs uppercase text-left p-3'>Ulaganje</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className=' w-full border-b'>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'>Prednji branik</td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, prenjiBranikLakirano: !formData.prenjiBranikLakirano }))} name='prenjiBranikLakirano' defaultValue={formData.prenjiBranikLakirano} /></td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, prenjiBranikUlaganje: !formData.prenjiBranikUlaganje }))} name='prenjiBranikUlaganje' defaultValue={formData.prenjiBranikUlaganje} /></td>
                            </tr>
                            <tr className=' w-full border-b'>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'>Hauba</td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, haubaLakirano: !formData.haubaLakirano }))} name='haubaLakirano' defaultValue={formData.haubaLakirano} /></td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, haubaUlaganje: !formData.haubaUlaganje }))} name='haubaUlaganje' defaultValue={formData.haubaUlaganje} /></td>
                            </tr>
                            <tr className=' w-full border-b'>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'>Gepek</td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, gepekLakirano: !formData.gepekLakirano }))} name='gepekLakirano' defaultValue={formData.gepekLakirano} /></td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, gepekUlaganje: !formData.gepekUlaganje }))} name='gepekUlaganje' defaultValue={formData.gepekUlaganje} /></td>
                            </tr>
                            <tr className=' w-full border-b'>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'>Zadnji branik</td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, zadnjiBranikLakirano: !formData.zadnjiBranikLakirano }))} name='zadnjiBranikLakirano' defaultValue={formData.zadnjiBranikLakirano} /></td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, zadnjiBranikUlaganje: !formData.zadnjiBranikUlaganje }))} name='zadnjiBranikUlaganje' defaultValue={formData.zadnjiBranikUlaganje} /></td>
                            </tr>
                            <tr className=' w-full '>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'>Krov</td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3 border-r'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, krovLakirano: !formData.krovLakirano }))} name='krovLakirano' defaultValue={formData.krovLakirano} /></td>
                                <td className=' w-full font-bold text-xs uppercase text-left p-3'><Checkbox onChange={() => setFormData((prevFormData) => ({ ...prevFormData, krovUlaganje: !formData.krovUlaganje }))} name='krovUlaganje' defaultValue={formData.krovUlaganje} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className=' w-full flex gap-3 items-center flex-wrap'>
                    <h3 className='text-sm'>PDR udubljena</h3>
                    <Switch onChange={() => setFormData((prevFormData) => ({ ...prevFormData, pdrUdubljena: !formData.pdrUdubljena }))} defaultValue={formData.pdrUdubljena} className='pt-0.5 bg-gray-300' checkedChildren={<Check size={19} />} unCheckedChildren={<X size={19} className=' w-full mt-0.5' />} />
                    <div className='flex items-center gap-2 ml-5'>
                        <p className='text-sm'>cca</p>
                        <InputNumber onChange={(value) => setFormData((prevFormData) => ({ ...prevFormData, ccaPrice: value }))} name='ccaPrice' defaultValue={formData.ccaPrice} min={0} max={10000000} className='w-full p-2 w-fit text-xs' placeholder='Unesite cijenu' />
                        <h3 className='font-bold'>KM</h3>
                    </div>
                </div>
                <div className=' w-full flex items-center gap-2'>
                    <p className=' w-full text-sm'>CAR VERTICAL ?</p>
                    <Switch onChange={() => setFormData((prevFormData) => ({ ...prevFormData, carVertical: !formData.carVertical }))} className='pt-0.5 bg-gray-300' defaultValue={formData.carVertical} checkedChildren={<Check size={19} />} unCheckedChildren={<X size={19} className=' w-full mt-0.5' />} />
                </div>
                <div className=' w-full '>
                    <h3 className=' w-full font-bold uppercase text-sm py-2 border-b mb-3'>Dodatno</h3>
                    <div className=' w-full w-full flex flex-wrap items-center mb-2'>
                        <p className=' w-full text-xs pb-1 font-semibold w-full'>Alarm</p>
                        <Input onChange={handleInputChange} name='alarm' className=' w-full p-2 w-full' defaultValue={formData.alarm} placeholder='Unesite podatke' />
                    </div>
                    <div className=' w-full w-full flex flex-wrap items-center'>
                        <p className=' w-full text-xs pb-1 font-semibold w-full'>Servisna knjiga</p>
                        <Input onChange={handleInputChange} name='servisnaKnjigaContent' className=' w-full p-2 w-8/12' defaultValue={formData.servisnaKnjigaContent} placeholder='Unesite podatke' />
                        <Switch onChange={() => setFormData((prevFormData) => ({ ...prevFormData, servisnaKnjiga: !formData.servisnaKnjiga }))} className=' bg-gray-300 mt-1' defaultValue={formData.servisnaKnjiga} checkedChildren="Da" unCheckedChildren="Ne" />
                    </div>
                    <div className=' w-full w-full flex flex-wrap items-center'>
                        <p className=' w-full text-xs pb-1 font-semibold w-full'>Drugi set točkova</p>
                        <Input onChange={handleInputChange} name='drugiSetTockovaContent' className=' w-full p-2 w-8/12' defaultValue={formData.drugiSetTockovaContent} placeholder='Unesite podatke' />
                        <Switch onChange={() => setFormData((prevFormData) => ({ ...prevFormData, drugiSetTockova: !formData.drugiSetTockova }))} className='bg-gray-300 mt-1' defaultValue={formData.drugiSetTockova} checkedChildren="Da" unCheckedChildren="Ne" />
                    </div>
                </div>
                <div className=' w-full '>
                    <h3 className=' w-full font-bold uppercase py-2 border-b mb-3'>PREGLED I ULAGANJA</h3>
                    <div className=' w-full w-full flex flex-wrap items-center mb-2'>
                        <p className=' w-full text-xs pb-1 font-semibold w-full'>Motor i mjenjač</p>
                        <InputNumber onChange={(value) => setFormData((prevFormData) => ({ ...prevFormData, motorImjenjacUlaganja: value }))} name='motorImjenjacUlaganja' className=' w-full w-full' defaultValue={formData.motorImjenjacUlaganja} addonAfter="KM"  />
                    </div>
                    <div className=' w-full w-full flex flex-wrap items-center mb-2'>
                        <p className=' w-full text-xs pb-1 font-semibold w-full'>Trap</p>
                        <InputNumber onChange={(value) => setFormData((prevFormData) => ({ ...prevFormData, trapUlaganja: value }))} name='trapUlaganja' className=' w-full w-full' defaultValue={formData.trapUlaganja} addonAfter="KM"  />
                    </div>
                    <div className=' w-full w-full flex flex-wrap items-center mb-2'>
                        <p className=' w-full text-xs pb-1 font-semibold w-full'>Limarija</p>
                        <InputNumber onChange={(value) => setFormData((prevFormData) => ({ ...prevFormData, limarijaUlaganja: value }))} name='limarijaUlaganja' className=' w-full w-full' defaultValue={formData.limarijaUlaganja} addonAfter="KM" />
                    </div>
                    <div className=' w-full w-full flex flex-wrap items-center mb-2'>
                        <p className=' w-full text-xs pb-1 font-semibold w-full'>Lakiranje</p>
                        <InputNumber onChange={(value) => setFormData((prevFormData) => ({ ...prevFormData, lakiranjeUlaganja: value }))} name='lakiranjeUlaganja' className=' w-full w-full' defaultValue={formData.lakiranjeUlaganja} addonAfter="KM" />
                    </div>
                    <div className=' w-full w-full flex flex-wrap items-center mb-2'>
                        <p className=' w-full text-xs pb-1 font-semibold w-full'>Poliranje</p>
                        <InputNumber onChange={(value) => setFormData((prevFormData) => ({ ...prevFormData, poliranjeUlaganja: value }))} name='poliranjeUlaganja' className=' w-full w-full' defaultValue={formData.poliranjeUlaganja} addonAfter="KM" />
                    </div>
                    <div className=' w-full w-full flex flex-wrap items-center mb-2'>
                        <p className=' w-full text-xs pb-1 font-semibold w-full'>Dubinsko</p>
                        <InputNumber onChange={(value) => setFormData((prevFormData) => ({ ...prevFormData, dubinskoUlaganja: value }))} name='dubinskoUlaganja' className=' w-full w-full' defaultValue={formData.dubinskoUlaganja} addonAfter="KM" />
                    </div>
                    <div className=' w-full w-full flex flex-wrap items-center mb-2'>
                        <p className=' w-full text-xs pb-1 font-semibold w-full'>Presvlačenje</p>
                        <InputNumber onChange={(value) => setFormData((prevFormData) => ({ ...prevFormData, presvlacenjeUlaganja: value }))} name='presvlacenjeUlaganja' className=' w-full w-full' defaultValue={formData.presvlacenjeUlaganja} addonAfter="KM" />
                    </div>
                    <div className=' w-full w-full flex flex-wrap items-center mb-2'>
                        <p className=' w-full text-xs pb-1 font-semibold w-full'>Felge i gume</p>
                        <InputNumber onChange={(value) => setFormData((prevFormData) => ({ ...prevFormData, felgeIgumeUlaganja: value }))} name='felgeIgumeUlaganja' className=' w-full w-full' defaultValue={formData.felgeIgumeUlaganja} addonAfter="KM" />
                    </div>
                    <div className=' w-full w-full flex flex-wrap items-center mb-2'>
                        <p className=' w-full text-xs pb-1 font-semibold w-full'>Ostala ulaganja</p>
                        <InputNumber onChange={(value) => setFormData((prevFormData) => ({ ...prevFormData, ostalaUlaganja: value }))} name='ostalaUlaganja' className=' w-full w-full' defaultValue={formData.ostalaUlaganja} addonAfter="KM" />
                    </div>
                    <div className=' w-full w-full flex flex-wrap items-center mb-2'>
                        <p className=' w-full text-sm pb-1 font-bold w-full'>UKUPNO ULAGANJA</p>
                        <InputNumber onChange={() => setFormData((prev) => ({ ...prev, ukupnoUlaganja: calcUkupnoUlaganje }))} readOnly value={calcUkupnoUlaganje} addonAfter="KM" />
                    </div>
                    <button className='p-3 text-white text-sm uppercase rounded w-full' style={{ backgroundColor: '#C93030' }} onClick={submit}>POTVRDI</button>
                </div>
            </div>
        </div>
    )
}

export default ProcjenaVozila