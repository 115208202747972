import React from 'react'

function ArenaMotorsTasks() {
    return (
        <div className='' style={{ width: '100vw' }}>
            <h3 className='text-sm font-bold pb-4'>POSLEDNJIH 10 ZADATAK-a</h3>
            <div className="list-of-tasks w-full">
                <div className="task-container flex items-center justify-between border-b py-2 w-full">
                    <div className='flex gap-4 w-2/5'>
                        <h3 style={{ width: 30, height: 30 }} className='bg-gray-100 rounded border flex items-center justify-center font-semibold text-sm'>1</h3>
                        <h2>VW Caddy VIN:3124 - <strong>VUK</strong></h2>
                    </div>
                    <div className="user-of-task flex items-center gap-2 w-2/5">
                        <h3 className='text-sm rounded-full bg-gray-200 flex items-center justify-center font-bold' style={{ width: 30, height: 30, backgroundColor: 'rgba(232, 179, 44, 0.25)', color: 'rgb(232, 179, 44)' }}>N</h3>
                        <h3 className='text-sm'>Nemanja Kovačević</h3>
                    </div>
                    <div className='flex items-center justify-end w-1/5' style={{ }}>
                        <div className="status-of-task flex items-center gap-2 p-2 rounded" style={{ backgroundColor: "#212121", width: 'fit-content' }}>
                            <div style={{ width: 15, height: 15 }} className='rounded-full border-2 border-dashed border-orange-500'></div>
                            <h3 className='text-orange-500 text-sm'>Preuzeto</h3>
                        </div>
                    </div>
                </div>
                <div className="task-container flex items-center justify-between border-b py-2 w-full">
                    <div className='flex gap-4 w-2/5'>
                        <h3 style={{ width: 30, height: 30 }} className='bg-gray-100 rounded border flex items-center justify-center font-semibold text-sm'>1</h3>
                        <h2>VW Caddy VIN:3124 - <strong>VUK</strong></h2>
                    </div>
                    <div className="user-of-task flex items-center gap-2 w-2/5">
                        <h3 className='text-sm rounded-full bg-gray-200 flex items-center justify-center font-bold' style={{ width: 30, height: 30, backgroundColor: '#ddd', color: 'rgb(232, 179, 44)' }}>?</h3>
                        <h3 className='text-sm text-blue-600 font-semibold'>Preuzmite zadatak</h3>
                    </div>
                    <div className='flex items-center justify-end w-1/5'>
                        <div className="status-of-task flex items-center gap-2 p-2 rounded" style={{ backgroundColor: "#212121", width: 'fit-content' }}>
                            <div style={{ width: 15, height: 15 }} className='rounded-full border-2 border-dashed border-red-500'></div>
                            <h3 className='text-red-500 text-sm'>Nije preuzeto</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ArenaMotorsTasks