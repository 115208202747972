import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import io from 'socket.io-client';
import axios from 'axios';
const socket = io("https://app.arenabackend.org", {
    reconnection: true
})


function EditProcjena() {

    const { procjenaId } = useParams();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        zamjenaZaVozilo: "",
        vozilo: '',
        godiste: '',
        sasija: '',
        odgovornaOsoba: '',
        kilometraza: '',
        realnoStanjeKM: false,
        brojKljuceva: '',
        registrovanDo: '',

        uVlasnistvu: '',
        kupljen: '',
        zadnjaZabKM: '',

        desnaStranicaLakirano: false,
        desnaStranicaUlaganje: false,

        desnaStranaStokLakirano: false,
        desnaStranaStokUlaganje: false,

        desnaStranaZadnjaVrataLakirano: false,
        desnaStranaZadnjaVrataUlaganje: false,

        desnaStranaPrednjaVrataLakirano: false,
        desnaStranaPrednjaVrataUlaganje: false,

        desnaStranaDesniBlatobranLakirano: false,
        desnaStranaDesniBlatobranUlaganje: false,

        lijevaStranicaLakirano: false,
        lijevaStranicaUlaganje: false,

        lijevaStranaStokLakirano: false,
        lijevaStranaStokUlaganje: false,

        lijevaStranaZadnjaVrataLakirano: false,
        lijevaStranaZadnjaVrataUlaganje: false,

        lijevaStranaPrednjaVrataLakirano: false,
        lijevaStranaPrednjaVrataUlaganje: false,

        lijevaStranaLijeviBlatobranLakirano: false,
        lijevaStranaLijeviBlatobranUlaganje: false,

        lijevaStranaPragUlaganje: false,
        lijevaStranaPragLakirano: false,

        desnaStranaPragLakirano: false,
        desnaStranaPragUlaganje: false,

        prenjiBranikLakirano: false,
        prenjiBranikUlaganje: false,

        haubaLakirano: false,
        haubaUlaganje: false,

        gepekLakirano: false,
        gepekUlaganje: false,

        zadnjiBranikLakirano: false,
        zadnjiBranikUlaganje: false,

        krovLakirano: false,
        krovUlaganje: false,

        pdrUdubljena: false,

        ccaPrice: '',
        alarm: '',

        servisnaKnjiga: null,
        servisnaKnjigaContent: '',

        drugiSetTockova: null,
        drugiSetTockovaContent: '',

        motor: '',
        motorContent: '',

        mjenjac: '',
        mjenjacContent: '',

        kvacilo: '',
        kvaciloContent: '',

        ovijes: '',
        ovijesContent: '',

        kocnice: '',
        kocnicePart: '',
        kocniceContent: '',

        limarija: '',
        limarijaContent: '',

        lakiranje: '',
        lakiranjeContent: '',

        poliranje: '',
        poliranjeContent: '',

        dubinsko: '',
        dubinskoContent: '',

        presvlacenje: '',
        presvlacenjeContent: '',

        felgeIgume: '',
        felgeIgumeContent: '',

        ostalaUlaganja: '',
        ostalaUlaganjaContent: '',

        ukupnoUlaganja: '',

        imeIprezime: "",
        brojTelefona: "",


        izvrsenaOnlineProvjera: null,

        cijenaVlasnika: "",
        trzisnaCijena: "",
        otkupnaCijena: "",
        prodajnaCijena: "",
        procjenaCijena: "",

        createdAt: new Date(),

        images: []
    })

    const fetchProcjena = async () => {
        await fetch(`https://app.arenabackend.org/procjene/procjena/${procjenaId}`).then((res) => res.json()).then((response) => {
            setFormData({
                zamjenaZaVozilo: response.zamjenaZaVozilo,
                vozilo: response.vozilo,
                godiste: response.godiste,
                sasija: response.sasija,
                odgovornaOsoba: response.odgovornaOsoba,
                kilometraza: response.kilometraza,
                realnoStanjeKM: response.realnoStanjeKM,
                brojKljuceva: response.brojKljuceva,
                registrovanDo: response.registrovanDo,

                uVlasnistvu: response.uVlasnistvu,
                kupljen: response.kupljen,
                zadnjaZabKM: response.zadnjaZabKM,

                desnaStranicaLakirano: response.desnaStranicaLakirano,
                desnaStranicaUlaganje: response.desnaStranicaUlaganje,

                desnaStranaStokLakirano: response.desnaStranaStokLakirano,
                desnaStranaStokUlaganje: response.desnaStranaStokUlaganje,

                desnaStranaZadnjaVrataLakirano: response.desnaStranaZadnjaVrataLakirano,
                desnaStranaZadnjaVrataUlaganje: response.desnaStranaZadnjaVrataUlaganje,

                desnaStranaPrednjaVrataLakirano: response.desnaStranaPrednjaVrataLakirano,
                desnaStranaPrednjaVrataUlaganje: response.desnaStranaPrednjaVrataUlaganje,

                desnaStranaDesniBlatobranLakirano: response.desnaStranaDesniBlatobranLakirano,
                desnaStranaDesniBlatobranUlaganje: response.desnaStranaDesniBlatobranUlaganje,

                lijevaStranicaLakirano: response.lijevaStranicaLakirano,
                lijevaStranicaUlaganje: response.lijevaStranicaUlaganje,

                lijevaStranaStokLakirano: response.lijevaStranaStokLakirano,
                lijevaStranaStokUlaganje: response.lijevaStranaStokUlaganje,

                lijevaStranaZadnjaVrataLakirano: response.lijevaStranaZadnjaVrataLakirano,
                lijevaStranaZadnjaVrataUlaganje: response.lijevaStranaZadnjaVrataUlaganje,

                lijevaStranaPrednjaVrataLakirano: response.lijevaStranaPrednjaVrataLakirano,
                lijevaStranaPrednjaVrataUlaganje: response.lijevaStranaPrednjaVrataUlaganje,

                lijevaStranaLijeviBlatobranLakirano: response.lijevaStranaLijeviBlatobranLakirano,
                lijevaStranaLijeviBlatobranUlaganje: response.lijevaStranaLijeviBlatobranUlaganje,

                lijevaStranaPragUlaganje: response.lijevaStranaPragUlaganje,
                lijevaStranaPragLakirano: response.lijevaStranaPragLakirano,

                desnaStranaPragLakirano: response.desnaStranaPragLakirano,
                desnaStranaPragUlaganje: response.desnaStranaPragUlaganje,

                prenjiBranikLakirano: response.prenjiBranikLakirano,
                prenjiBranikUlaganje: response.prenjiBranikUlaganje,

                haubaLakirano: response.haubaLakirano,
                haubaUlaganje: response.haubaUlaganje,

                gepekLakirano: response.gepekLakirano,
                gepekUlaganje: response.gepekUlaganje,

                zadnjiBranikLakirano: response.zadnjiBranikLakirano,
                zadnjiBranikUlaganje: response.zadnjiBranikUlaganje,

                krovLakirano: response.krovLakirano,
                krovUlaganje: response.krovUlaganje,

                pdrUdubljena: response.pdrUdubljena,

                ccaPrice: response.ccaPrice,
                alarm: response.alarm,

                servisnaKnjiga: null,
                servisnaKnjigaContent: response.servisnaKnjigaContent,

                drugiSetTockova: null,
                drugiSetTockovaContent: response.drugiSetTockovaContent,

                motor: response.motor,
                motorContent: response.motorContent,

                mjenjac: response.mjenjac,
                mjenjacContent: response.mjenjacContent,

                kvacilo: response.kvacilo,
                kvaciloContent: response.kvaciloContent,

                ovijes: response.ovijes,
                ovijesContent: response.ovijesContent,

                kocnice: response.kocnice,
                kocnicePart: response.kocnicePart,
                kocniceContent: response.kocniceContent,

                limarija: response.limarija,
                limarijaContent: response.limarijaContent,

                lakiranje: response.lakiranje,
                lakiranjeContent: response.lakiranjeContent,

                poliranje: response.poliranje,
                poliranjeContent: response.poliranjeContent,

                dubinsko: response.dubinsko,
                dubinskoContent: response.dubinskoContent,

                presvlacenje: response.presvlacenje,
                presvlacenjeContent: response.presvlacenjeContent,

                felgeIgume: response.felgeIgume,
                felgeIgumeContent: response.felgeIgumeContent,

                ostalaUlaganja: response.ostalaUlaganja,
                ostalaUlaganjaContent: response.ostalaUlaganjaContent,

                ukupnoUlaganja: response.ukupnoUlaganja,

                imeIprezime: response.imeIprezime,
                brojTelefona: response.brojTelefona,

                izvrsenaOnlineProvjera: response.izvrsenaOnlineProvjera,

                cijenaVlasnika: response.cijenaVlasnika,
                trzisnaCijena: response.trzisnaCijena,
                otkupnaCijena: response.otkupnaCijena,
                prodajnaCijena: response.prodajnaCijena,
                procjenaCijena: response.procjenaCijena,

                createdAt: new Date(response.createdAt),

                images: response.images
            })
        }).catch((e) => alert('Greska prilikom izvlacenja procjena'))
    }

    

    const submit = async () => {
        try {

            const response = await axios.put(`https://app.arenabackend.org/procjene/edit-procjena/${procjenaId}`, {
                ...formData,
                zamjenaZaVozilo: formData.zamjenaZaVozilo,
                vozilo: formData.vozilo,
                godiste: formData.godiste,
                sasija: formData.sasija,
                odgovornaOsoba: formData.odgovornaOsoba,
                kilometraza: formData.kilometraza,
                realnoStanjeKM: formData.realnoStanjeKM,
                brojKljuceva: formData.brojKljuceva,
                registrovanDo: formData.registrovanDo,

                uVlasnistvu: formData.uVlasnistvu,
                kupljen: formData.kupljen,
                zadnjaZabKM: formData.zadnjaZabKM,

                desnaStranicaLakirano: formData.desnaStranicaLakirano,
                desnaStranicaUlaganje: formData.desnaStranicaUlaganje,

                desnaStranaStokLakirano: formData.desnaStranaStokLakirano,
                desnaStranaStokUlaganje: formData.desnaStranaStokUlaganje,

                desnaStranaZadnjaVrataLakirano: formData.desnaStranaZadnjaVrataLakirano,
                desnaStranaZadnjaVrataUlaganje: formData.desnaStranaZadnjaVrataUlaganje,

                desnaStranaPrednjaVrataLakirano: formData.desnaStranaPrednjaVrataLakirano,
                desnaStranaPrednjaVrataUlaganje: formData.desnaStranaPrednjaVrataUlaganje,

                desnaStranaDesniBlatobranLakirano: formData.desnaStranaDesniBlatobranLakirano,
                desnaStranaDesniBlatobranUlaganje: formData.desnaStranaDesniBlatobranUlaganje,

                lijevaStranicaLakirano: formData.lijevaStranicaLakirano,
                lijevaStranicaUlaganje: formData.lijevaStranicaUlaganje,

                lijevaStranaStokLakirano: formData.lijevaStranaStokLakirano,
                lijevaStranaStokUlaganje: formData.lijevaStranaStokUlaganje,

                lijevaStranaZadnjaVrataLakirano: formData.lijevaStranaZadnjaVrataLakirano,
                lijevaStranaZadnjaVrataUlaganje: formData.lijevaStranaZadnjaVrataUlaganje,

                lijevaStranaPrednjaVrataLakirano: formData.lijevaStranaPrednjaVrataLakirano,
                lijevaStranaPrednjaVrataUlaganje: formData.lijevaStranaPrednjaVrataUlaganje,

                lijevaStranaLijeviBlatobranLakirano: formData.lijevaStranaLijeviBlatobranLakirano,
                lijevaStranaLijeviBlatobranUlaganje: formData.lijevaStranaLijeviBlatobranUlaganje,

                lijevaStranaPragUlaganje: formData.lijevaStranaPragUlaganje,
                lijevaStranaPragLakirano: formData.lijevaStranaPragLakirano,

                desnaStranaPragLakirano: formData.desnaStranaPragLakirano,
                desnaStranaPragUlaganje: formData.desnaStranaPragUlaganje,

                prenjiBranikLakirano: formData.prenjiBranikLakirano,
                prenjiBranikUlaganje: formData.prenjiBranikUlaganje,

                haubaLakirano: formData.haubaLakirano,
                haubaUlaganje: formData.haubaUlaganje,

                gepekLakirano: formData.gepekLakirano,
                gepekUlaganje: formData.gepekUlaganje,

                zadnjiBranikLakirano: formData.zadnjiBranikLakirano,
                zadnjiBranikUlaganje: formData.zadnjiBranikUlaganje,

                krovLakirano: formData.krovLakirano,
                krovUlaganje: formData.krovUlaganje,

                pdrUdubljena: formData.pdrUdubljena,

                ccaPrice: formData.ccaPrice,
                alarm: formData.alarm,

                servisnaKnjiga: null,
                servisnaKnjigaContent: formData.servisnaKnjigaContent,

                drugiSetTockova: null,
                drugiSetTockovaContent: formData.drugiSetTockovaContent,

                motor: formData.motor,
                motorContent: formData.motorContent,

                mjenjac: formData.mjenjac,
                mjenjacContent: formData.mjenjacContent,

                kvacilo: formData.kvacilo,
                kvaciloContent: formData.kvaciloContent,

                ovijes: formData.ovijes,
                ovijesContent: formData.ovijesContent,

                kocnice: formData.kocnice,
                kocnicePart: formData.kocnicePart,
                kocniceContent: formData.kocniceContent,

                limarija: formData.limarija,
                limarijaContent: formData.limarijaContent,

                lakiranje: formData.lakiranje,
                lakiranjeContent: formData.lakiranjeContent,

                poliranje: formData.poliranje,
                poliranjeContent: formData.poliranjeContent,

                dubinsko: formData.dubinsko,
                dubinskoContent: formData.dubinskoContent,

                presvlacenje: formData.presvlacenje,
                presvlacenjeContent: formData.presvlacenjeContent,

                felgeIgume: formData.felgeIgume,
                felgeIgumeContent: formData.felgeIgumeContent,

                ostalaUlaganja: formData.ostalaUlaganja,
                ostalaUlaganjaContent: formData.ostalaUlaganjaContent,

                ukupnoUlaganja: formData.ukupnoUlaganja,

                imeIprezime: formData.imeIprezime,
                brojTelefona: formData.brojTelefona,

                izvrsenaOnlineProvjera: formData.izvrsenaOnlineProvjera,

                cijenaVlasnika: formData.cijenaVlasnika,
                trzisnaCijena: formData.trzisnaCijena,
                otkupnaCijena: formData.otkupnaCijena,
                prodajnaCijena: formData.prodajnaCijena,
                procjenaCijena: formData.procjenaCijena,

                createdAt: new Date(formData.createdAt),

                images: formData.images
            });

            if (!response) {
                alert('Greska')
            }
            navigate(-1)
            socket.emit('arzuriranaProcjena', 'arzurirana je procjena');
            // Handle success or redirect
        } catch (error) {
            alert('Greska prilikom postavljanja procjene!')
        }
    }

    console.log(formData.createdAt)


    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        if (type === 'file') {
            setFormData(prevState => ({
                ...prevState,
                [name]: files
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: type === 'checkbox' ? checked : value
            }));
        }
    };

    const formatLabel = (key) => {
        return key
            .replace(/([A-Z])/g, ' $1') // Razdvoji camelCase riječi
            .replace(/^./, str => str.toUpperCase()) // Prvo slovo postavi na veliko
            .trim(); // Ukloni eventualne početne ili završne razmake
    };


    const renderCheckboxes = () => {
        return Object.keys(formData).map((key) => {
            if (typeof formData[key] === 'boolean') {
                // Generiraj checkbox input za boolean vrijednosti
                return (
                    <div className='flex items-center justify-between border p-1.5' style={{ maxWidth: '49.8%', minWidth: '49.8%' }} key={key}>
                        <label className='text-xs'>
                            {formatLabel(key)}
                        </label>
                            <input
                                type="checkbox"
                                className='bg-gray-100 py-1 px-2 text-sm'
                                name={key}
                                checked={formData[key]}
                                onChange={handleChange}
                            />
                    </div>
                );
            } else if (key === 'images') {
                // Generiraj file input za 'images'
                return null
            } else {
                // Generiraj text input za ostale vrijednosti
                return null
            }
        });
    };

    function formatDateForInput(dateString) {
        // Create a new Date object from the input string
        const date = new Date(dateString);

        // Extract the year, month, and day
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');

        // Format the date as YYYY-MM-DD
        return `${year}-${month}-${day}`;
    }


    const renderInputs = () => {
        return Object.keys(formData).map((key) => {
            if (typeof formData[key] === 'boolean') {
                // Generiraj checkbox input za boolean vrijednosti
                return null
            } else if (key === 'images') {
                // Generiraj file input za 'images'
                return null
            } else if (key === 'createdAt') {
                return (
                    <div className='flex flex-col border p-1.5' style={{ maxWidth: '49.8%', minWidth: '49.8%' }} key={key}>
                        <label className='text-xs mb-1'>
                            {formatLabel(key)}
                        </label>
                        <input
                            className='bg-gray-100 py-1 px-2 text-sm'
                            type="date"
                            name={key}
                            placeholder={`Unesite ${formatLabel(key)}`}
                            value={formatDateForInput(formData[key])}
                            onChange={(e) => setFormData(prev => ({ ...prev, createdAt: new Date(e.target.value) }))}
                        />
                    </div>
                )
            } else {
                // Generiraj text input za ostale vrijednosti
                return (
                    <div className='flex flex-col border p-1.5' style={{ maxWidth: '49.8%', minWidth: '49.8%' }} key={key}>
                        <label className='text-xs mb-1'>
                            {formatLabel(key)}
                        </label>
                        <input
                            className='bg-gray-100 py-1 px-2 text-sm'
                            type="text"
                            name={key}
                            placeholder={`Unesite ${formatLabel(key)}`}
                            value={formData[key]}
                            onChange={handleChange}
                        />
                    </div>
                );
            }
        });
    };

    useEffect(() => {
        fetchProcjena()
    }, [])


    return (
        <div>
            <h1 className='p-3 text-xl font-semibold text-center'>Uredi procjenu procjena: {formData.vozilo}</h1>
            <div>
                <div className='flex flex-wrap px-3 pb-3 justify-between w-full' style={{ gap: 5 }}>
                    {renderInputs()}
                </div>
                <div className='flex flex-wrap px-3 pb-3 justify-between w-full' style={{ gap: 5 }}>
                    {renderCheckboxes()}
                </div>
                <div className='mx-3 mb-3'>
                    <button onClick={submit} className='w-full p-3 bg-red-600 text-white text-center rounded-full'>Primjenite izmjene</button>
                </div>
            </div>
        </div>
    )
}

export default EditProcjena