import React, { useEffect, useState } from 'react'
import PrijemNaZalihu from '../../componets/zapisnici/PrijemNaZalihu';


function PrijemiNaZalihu({ navigation }) {

    const [ prijemi, setPrijemi ] = useState([]);
    const [ activePrijem, setActivePrijem ] = useState("");
    const [ editingPrijem, setEditingPrijem ] = useState(null);
    
    const fetchPrijemi = async () => {
        await fetch('https://app.arenabackend.org/zapisnici/svi-izvjestaji').then((res) => res.json()).then((response) => setPrijemi(response)).catch((err) => console.log(err))
    }

    useEffect(() => {
        fetchPrijemi()
    }, [])

    const formatDate = (date) => {
        const dati = new Date(date)
        return `${dati.getDate()}.${dati.getMonth() + 1}.${dati.getFullYear()} u ${dati.getHours()}h i ${dati.getMinutes()}min`;
    }


    return (
        <div className='px-4 pb-4'>
            <h1 className='uppercase text-xl font-bold text-center py-10'>Prijem na zalihu</h1>
            {
                activePrijem ? <button className='absolute top-2 right-2 py-3 px-4 text-white rounded-full text-sm' style={{ backgroundColor: '#D22829' }} onClick={() => setActivePrijem("")}>Zatvori prijem</button> : null
            }
            <table className='w-full border-x border-t' style={{ transition: 'ease-out' }}>
                <thead className='w-full'>
                    <tr className='w-full border-b bg-gray-100'>
                        <th className='font-semibold text-left p-3 text-sm border-r'>Naziv vozila</th>
                        <th className='font-semibold text-left p-3 text-sm border-r'>Šasija</th>
                        <th className='font-semibold text-left p-3 text-sm border-r'>Broj kljuceva</th>
                        <th className='font-semibold text-left p-3 text-sm border-r'>Kilometraža</th>
                        <th className='font-semibold text-left p-3 text-sm border-r'>Potrebnih radova</th>
                        <th className='font-semibold text-left p-3 text-sm'>Datum izvještaja</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        prijemi.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((prijem) => {
                            return (
                                <tr onClick={() => setActivePrijem(prijem._id)} className='w-full border-b hover:bg-gray-100 hover:border-red-400' style={{ cursor: 'pointer' }}>
                                    <th className='font-medium text-left p-3 text-sm uppercase border-r'>{prijem.vozilo}</th>
                                    <th className='font-medium text-left p-3 text-sm uppercase border-r'>{prijem.sasija}</th>
                                    <th className='font-medium text-left p-3 text-sm uppercase border-r'>{prijem.brojKljuceva}</th>
                                    <th className='font-medium text-left p-3 text-sm uppercase border-r'>{prijem.kilometraza}</th>
                                    <th className='font-medium text-left p-3 text-sm uppercase border-r'>{prijem.potrebniRadovi.length}</th>
                                    <th className='font-medium text-left p-3 text-sm'>{prijem.createdAt ? formatDate(prijem.createdAt) : 'NEMA DATUMA'}</th>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            {
                activePrijem ? <PrijemNaZalihu data={prijemi.filter((prijem) => prijem._id === activePrijem)[0]} /> : null
            }
            
        </div>
    )
}

export default PrijemiNaZalihu