import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        // Check login status on app startup
        checkLoginStatus();
    }, []);

    const checkLoginStatus = () => {
        const isAuthenticated = Cookies.get('isAuthenticated');
        if (isAuthenticated === 'true') {
            setLoggedIn(true);
        }
    };

    const login = () => {
        // Perform login logic here
        // Assuming login is successful, set isLoggedIn to true
        setLoggedIn(true);
        // Set a long-lived cookie to indicate that the user is authenticated
        Cookies.set('isAuthenticated', 'true', { expires: 7 }); // Expires in 1 year
    };

    const logout = () => {
        // Perform logout logic here
        // Assuming logout is successful, set isLoggedIn to false
        setLoggedIn(false);
        // Remove the authentication cookie
        Cookies.remove('isAuthenticated');
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
