import { Avatar } from 'antd'
import React from 'react'

import '../styles/components.css'

// icons

function UserMessages() {
    return (
        <div className='component-container rounded border' style={{  width: 'calc(100% / 2 - 10px)' }}>
            <div className="messages-header p-3 border-b flex items-center justify-between pr-1.5">
                <h3>Lista poruka</h3>
                <button className='flex text-black text-xs gap-2 border items-center p-2 rounded-full hover:bg-gray-100 hover:shadow-xl'>Pogledaj sve poruke</button>
            </div>
            <div className="messages-body overflow-auto p-3 flex flex-col gap-2" style={{ maxHeight: 400 }}>
                <div className="message-container opacity-50 cursor-pointer text-sm flex pt-2 items-start gap-3 justify-between pl-3 py-1.5 pr-1.5 bg-gray-100 rounded hover:bg-gray-50 hover:shadow-md">
                    <div className="message-main flex gap-2">
                        <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>D</Avatar>
                    </div>
                    <div className="message-content">
                        <h3 className='font-bold'>David Obradovic</h3>
                        <p className='text-sm line-clamp-2	'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit dolorum nulla, earum facilis possimus assumenda sequi dolores veritatis neque aliquid aut fugit architecto illo molestiae ducimus, deleniti quo! Voluptatibus, vel?</p>
                    </div>
                </div>
                <div className="message-container opacity-50 cursor-pointer text-sm flex pt-2 items-start gap-3 justify-between pl-3 py-1.5 pr-1.5 bg-gray-100 rounded hover:bg-gray-50 hover:shadow-md">
                    <div className="message-main flex gap-2">
                        <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>O</Avatar>
                    </div>
                    <div className="message-content">
                        <h3 className='font-bold'>Ognjen Lubura</h3>
                        <p className='text-sm line-clamp-2	'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit dolorum nulla, earum facilis possimus assumenda sequi dolores veritatis neque aliquid aut fugit architecto illo molestiae ducimus, deleniti quo! Voluptatibus, vel?</p>
                    </div>
                </div>
                <div className="message-container opacity-50 cursor-pointer text-sm flex pt-2 items-start gap-3 justify-between pl-3 py-1.5 pr-1.5 bg-gray-100 rounded hover:bg-gray-50 hover:shadow-md">
                    <div className="message-main flex gap-2">
                        <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>A</Avatar>
                    </div>
                    <div className="message-content">
                        <h3 className='font-bold'>Admir Arena</h3>
                        <p className='text-sm line-clamp-2	'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit dolorum nulla, earum facilis possimus assumenda sequi dolores veritatis neque aliquid aut fugit architecto illo molestiae ducimus, deleniti quo! Voluptatibus, vel?</p>
                    </div>
                </div>
                <div className="message-container opacity-50 cursor-pointer text-sm flex pt-2 items-start gap-3 justify-between pl-3 py-1.5 pr-1.5 bg-gray-100 rounded hover:bg-gray-50 hover:shadow-md">
                    <div className="message-main flex gap-2">
                        <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>A</Avatar>
                    </div>
                    <div className="message-content">
                        <h3 className='font-bold'>Ajlan Arena</h3>
                        <p className='text-sm line-clamp-2	'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit dolorum nulla, earum facilis possimus assumenda sequi dolores veritatis neque aliquid aut fugit architecto illo molestiae ducimus, deleniti quo! Voluptatibus, vel?</p>
                    </div>
                </div>
                <div className="message-container opacity-50 cursor-pointer text-sm flex pt-2 items-start gap-3 justify-between pl-3 py-1.5 pr-1.5 bg-gray-100 rounded hover:bg-gray-50 hover:shadow-md">
                    <div className="message-main flex gap-2">
                        <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>M</Avatar>
                    </div>
                    <div className="message-content">
                        <h3 className='font-bold'>Milica Arena</h3>
                        <p className='text-sm line-clamp-2	'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit dolorum nulla, earum facilis possimus assumenda sequi dolores veritatis neque aliquid aut fugit architecto illo molestiae ducimus, deleniti quo! Voluptatibus, vel?</p>
                    </div>
                </div>
                <div className="message-container opacity-50 cursor-pointer text-sm flex pt-2 items-start gap-3 justify-between pl-3 py-1.5 pr-1.5 bg-gray-100 rounded hover:bg-gray-50 hover:shadow-md">
                    <div className="message-main flex gap-2">
                        <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>N</Avatar>
                    </div>
                    <div className="message-content">
                        <h3 className='font-bold'>Nemanja Kovacevic</h3>
                        <p className='text-sm line-clamp-2	'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit dolorum nulla, earum facilis possimus assumenda sequi dolores veritatis neque aliquid aut fugit architecto illo molestiae ducimus, deleniti quo! Voluptatibus, vel?</p>
                    </div>
                </div>
                <div className="message-container cursor-pointer border-b-2 border-b-red-400 text-sm flex pt-2 items-start gap-3 justify-between pl-3 py-1.5 pr-1.5 bg-gray-100 rounded hover:bg-gray-50 hover:shadow-md">
                    <div className="message-main flex gap-2">
                        <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>N</Avatar>
                    </div>
                    <div className="message-content">
                        <h3 className='font-bold'>Nemanja Lubura</h3>
                        <p className='text-sm line-clamp-2	'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit dolorum nulla, earum facilis possimus assumenda sequi dolores veritatis neque aliquid aut fugit architecto illo molestiae ducimus, deleniti quo! Voluptatibus, vel?</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default UserMessages