import { FloatButton, Modal, message } from 'antd';
import axios from 'axios';
import { DollarSign } from 'lucide-react';
import React, { useEffect, useState } from 'react'

function DnevnaEvidencija() {

    const [addEvidencija, setAddEvidencija] = useState(null)
    const [updateEvidencija, setUpdateEvidencija] = useState(null)


    const [formData, setFormData] = useState({
        title: "",
        price: ""
    })

    const [updateFormData, setUpdateFormData] = useState({
        title: "",
        price: ""
    })

    const [messageApi, contextHolder] = message.useMessage();
    const [evidencije, setEvidencije] = useState([])
    const [budzet, setBudzet] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isBudgetModalOpen, setIsBudgetModalOpen] = useState(false);
    const [budgetValue, setBudgetValue] = useState(0)

    function formatDate(date) {
        if (!date) return "";
        // Dohvati dan, mjesec i godinu
        const day = date.getDate();
        const month = date.getMonth() + 1; // Mjeseci počinju od 0, pa se dodaje 1
        const year = date.getFullYear();

        // Formatiraj datum
        return `${day}.${month}.${year}`;
    }


    const fetchEvidencije = async () => {
        await fetch(`https://app.arenabackend.org/administration/dnevni-izvjestaji`)
            .then((res) => res.json())
            .then((response) => {
                setEvidencije(response)
            })
            .catch((e) => {
                messageApi.open({
                    type: 'error',
                    content: 'Greska prilikom izvlacenja DM-ova',
                });
            })
    }

    const fetchBudget = async () => {
        await fetch(`https://app.arenabackend.org/administration/svi-budzeti`)
            .then((res) => res.json())
            .then((response) => {
                setBudzet(response)
            })
            .catch((e) => {
                messageApi.open({
                    type: 'error',
                    content: 'Greska prilikom izvlacenja DM-ova',
                });
            })
    }

    const handleEvidencijaPost = async () => {
        try {
            const response = await axios.post('https://app.arenabackend.org/administration/post-dnevna-evidencija', formData);

            if (!response) {
                messageApi.open({
                    type: 'error',
                    content: 'Greška prilikom postavljanja evidencije!',
                });
            }

            messageApi.open({
                type: 'success',
                content: 'Evidencija je uspiješno postavljena!',
            });
            setFormData({
                title: "",
                price: ""
            })
            setAddEvidencija(null);
            fetchEvidencije();
        } catch (error) {
            console.error('Greška prilikom slanja zahtjeva:', error);
        }
    };

    const handleUpdateEvidencija = async () => {
        try {
            const response = await axios.put(`https://app.arenabackend.org/administration/update-dnevna-evidencija/${updateEvidencija}`, {
                title: updateFormData.title,
                price: updateFormData.price
            });

            if (!response) {
                messageApi.open({
                    type: 'error',
                    content: 'Greška prilikom aržuriranja evidencije!',
                });
            }

            messageApi.open({
                type: 'success',
                content: 'Evidencija je uspiješno aržurirana!',
            });

            fetchEvidencije()
            setUpdateEvidencija(null)

            setAddEvidencija(null);
            fetchEvidencije();
        } catch (error) {
            console.error('Greška prilikom slanja zahtjeva:', error);
        }
    };

    const handleEvidencijaBudget = async () => {
        if (budgetValue < 10) {
            alert('Unesite ispravan budžet')
        } else {
            try {
                const response = await axios.post('https://app.arenabackend.org/administration/post-evidencija-budget', {
                    value: budgetValue
                });

                if (!response) {
                    messageApi.open({
                        type: 'error',
                        content: 'Greška prilikom postavljanja evidencije!',
                    });
                }

                messageApi.open({
                    type: 'success',
                    content: 'Evidencija je uspiješno postavljena!',
                });
                setAddEvidencija(null);
                fetchEvidencije();
                fetchBudget();
            } catch (error) {
                console.error('Greška prilikom slanja zahtjeva:', error);
            }
        }
    };


    useEffect(() => {
        fetchEvidencije()
        fetchBudget()
    }, [])

    const arr = evidencije.map((ev) => parseFloat(ev.price))
    const sum = arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleBudgetCancel = () => {
        setIsBudgetModalOpen(false);
    };

    const clearTable = async () => {
        try {
            const response = await axios.delete('https://app.arenabackend.org/administration/obrisi-evidencije');

            if (!response) {
                alert('Greska prilikom kreiranja obavjestenja')
            }
            messageApi.open({
                type: 'success',
                content: 'Uspiješno ste očistili tabelu',
            });
            fetchEvidencije();
            fetchBudget();
        } catch (error) {
            console.error('Error:', error);
            alert("Greška prilikom slanja zahtjeva");
        }
    };

    const calculatedValue = () => {
        const bValue = budzet.length > 0 ? Number(budzet[0].value) + sum || 0 + sum : null

        return bValue
    }

    return (
        <div className='p-3'>
            {contextHolder}
            {
                evidencije.length > 0 ? (
                    <button className='text-center p-3 mb-3 text-sm text-white' style={{ backgroundColor: '#d22829' }} onClick={() => setIsModalOpen(true)}>Očisti tabelu</button>
                ) : null
            }
            {
                updateEvidencija != null ? (
                    <button className='text-center p-3 mb-3 ml-3 text-sm text-white bg-blue-500' onClick={handleUpdateEvidencija} style={{}}>Aržuriraj evidenciju</button>
                ) : null
            }
            <FloatButton icon={<DollarSign />} onClick={() => setIsBudgetModalOpen(true)} />
            <Modal title="Očisti tabelu" open={isModalOpen} onOk={clearTable} onCancel={handleCancel} cancelText='Odustani' okText='Potvrdi' okType='default' >
                <h3>Da li ste sigurni da želite očistiti tabelu ?</h3>
            </Modal>
            <Modal title="Dodaj današnji budżet" open={isBudgetModalOpen} onOk={handleEvidencijaBudget} onCancel={handleBudgetCancel} cancelText='Odustani' okText='Potvrdi' okType='default' >
                <input type="number" placeholder='Unesite budžet za danas' className='p-3 text-sm w-full border bg-gray-100 rounded mb-3' onChange={(e) => setBudgetValue(e.target.value)} />
                <h3>Molimo vas da uneste realan budžet za ovaj dan!</h3>
            </Modal>
            <button className='p-3 text-sm w-full text-white' style={{ backgroundColor: '#d22829' }} onClick={() => addEvidencija ? handleEvidencijaPost() : setAddEvidencija(true)}>{addEvidencija ? 'Potvrdi' : 'Dodaj evidenciju'}</button>
            <h3 className='mt-3 text-sm uppercase font-medium'>Današnji budzet iznosi: {budzet.length > 0 ? Number(budzet[0].value).toLocaleString(void 0, { maximumFractionDigits: 2 }) : 0} KM</h3>
            <h3 className='mb-3 text-sm uppercase font-medium' style={{ color: calculatedValue() < 0 ? '#d22829' : 'black' }}>Preostalo: {budzet.length > 0 ? calculatedValue() : null} KM od {budzet.length > 0 ? Number(budzet[0].value).toLocaleString(void 0, { maximumFractionDigits: 2 }) : 0} KM</h3>
            <table className='w-full border-x border-t'>
                <thead className='w-full'>
                    <tr className='w-full'>
                        <th style={{ maxWidth: '20%' }} className='w-1/5 text-left py-1 px-3 font-normal bg-gray-200 border-r border-r-gray-50 text-sm'>Datum</th>
                        <th style={{ maxWidth: '60%' }} className='w-1/3 text-left py-1 px-3 font-normal bg-gray-200 border-r border-r-gray-50 text-sm'>Posao</th>
                        <th style={{ maxWidth: '20%' }} className='w-1/5 text-left py-1 px-3 font-normal bg-gray-200 text-sm'>Iznos</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        evidencije.map((ev) => {
                            return (
                                <tr onDoubleClick={() => {
                                    setUpdateEvidencija(ev._id)
                                    setUpdateFormData({
                                        title: ev.title,
                                        price: ev.price
                                    })
                                }} className='border-b bg-gray-50 hover:bg-gray-100'>
                                    <td style={{ maxWidth: '20%' }} className='w-1/5 text-left py-1 px-3 font-normal border-r border-r-gray-200 text-sm'>{formatDate(new Date(ev.createdAt))}</td>
                                    <td style={{ maxWidth: '60%' }} className='w-3/5 text-left py-1 px-3 font-normal border-r border-r-gray-200 text-sm uppercase'>{ev.title}</td>
                                    <td style={{ maxWidth: '20%' }} className='w-1/5 text-left py-1 px-3 font-normal text-sm'>{ev.price}</td>
                                </tr>
                            )
                        })
                    }
                    {
                        updateEvidencija ? (
                            (
                                <tr className='border-b bg-white border-red-600'>
                                    <td style={{ maxWidth: '20%' }} className='w-1/5 text-left font-normal border-r border-r-gray-200 text-sm'><input className='py-1 px-3 text-sm w-full' readOnly value={formatDate(new Date())} /></td>
                                    <td style={{ maxWidth: '60%' }} className='w-3/5 text-left font-normal border-r border-r-gray-200 text-sm'><input className='py-1 px-3 text-sm w-full' defaultValue={updateFormData.title} onChange={(e) => setUpdateFormData((prev) => ({ ...prev, title: e.target.value.toUpperCase() }))} /></td>
                                    <td style={{ maxWidth: '20%' }} className='w-1/3 text-left font-normal text-sm'><input className='py-1 px-3 text-sm w-full' defaultValue={updateFormData.price} onChange={(e) => setUpdateFormData((prev) => ({ ...prev, price: e.target.value }))} /></td>
                                </tr>
                            )
                        ) : null
                    }
                    {
                        addEvidencija ? (
                            <tr className='border-b bg-white border-red-600'>
                                <td style={{ maxWidth: '20%' }} className='w-1/5 text-left font-normal border-r border-r-gray-200 text-sm'><input className='py-1 px-3 text-sm w-full' readOnly value={formatDate(new Date())} /></td>
                                <td style={{ maxWidth: '60%' }} className='w-3/5 text-left font-normal border-r border-r-gray-200 text-sm'><input className='py-1 px-3 text-sm w-full' defaultValue={formData.title.toUpperCase()} onChange={(e) => setFormData((prev) => ({ ...prev, title: e.target.value.toUpperCase() }))} /></td>
                                <td style={{ maxWidth: '20%' }} className='w-1/5 text-left font-normal text-sm'><input className='py-1 px-3 text-sm w-full' defaultValue={formData.price} onChange={(e) => setFormData((prev) => ({ ...prev, price: e.target.value }))} /></td>
                            </tr>
                        ) : null
                    }
                    <tr className='border-b bg-gray-50 hover:bg-gray-100'>
                        <td className='w-1/3 text-left py-1 px-3 font-normal border-r border-r-gray-200 text-sm' colSpan={2}>Ukupno</td>
                        <td className='w-1/3 text-left py-1 px-3 font-normal text-sm'>{sum.toLocaleString(void 0, { maximumFractionDigits: 2 }).replaceAll(",", ".")} KM</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default DnevnaEvidencija