import { Select, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useAppData } from '../../context/ApplicationProvider';
import axios from 'axios';

function EditTask() {

    const navigate = useNavigate();

    const { taskId } = useParams();

    const [messageApi, contextHolder] = message.useMessage();
    const { users, sections, fetchZadaci } = useAppData();

    const [zadatak, setZadatak] = useState([])
    const [loading, setLoading] = useState(true)
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        sektor: "",
        users: [],
        importantLever: 0
    })

    const fetchZadatak = () => {
        fetch(`https://app.arenabackend.org/zadaci/zadatak/${taskId}`)
            .then((res) => {
                return res.json();
            })
            .then((response) => {
                setZadatak(response);
                setFormData({
                    title: response.title,
                    description: response.description,
                    sektor: response.sektor,
                    users: response.users,
                    importantLever: response.importantLever,
                });
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false); // Set loading to false even in case of error
            });
    };

    const updateTask = async () => {
        await axios.put(`https://app.arenabackend.org/zadaci/app-edit-task/${taskId}/${formData.sektor}`, formData).then((res) => {
            fetchZadaci()
            messageApi.open({
                type: 'success',
                content: 'Uspiješno ste uredili zadatak!',
            });
            console.log('Zavrsen zadatak');
        }).catch((e) => console.log(e));
    }

    useEffect(() => {
        fetchZadatak()
    }, [loading])

    return (
        <div className='p-3'>
            {contextHolder}
            <input onChange={(e) => setFormData((prev) => ({...prev, title: e.target.value}))} type="text" className='p-3 bg-gray-50 border rounded w-full text-sm' defaultValue={zadatak.title} placeholder='Naziv zadatka' />
            <textarea onChange={(e) => setFormData((prev) => ({...prev, description: e.target.value}))} className='p-3 bg-gray-50 border rounded w-full text-sm mt-3' defaultValue={zadatak.description} placeholder='Opis zadatka' />
            <Select onChange={(value) => setFormData((prev) => ({...prev, sektor: value}))} value={formData.sektor} options={sections.map((sektor) => {
                return {
                    label: sektor.title,
                    value: sektor._id.trim()
                }
            })} placeholder='Izaberite sektor' className='w-full mt-3' style={{ height: 45 }} />
            {
                zadatak.users ? zadatak.users.length < 1 ? (
                    <Select onChange={(value) => setFormData((prev) => ({ ...prev, users: value }))} defaultValue={formData.users} options={users.map((user) => {
                        return {
                            label: user.name,
                            value: user._id.trim()
                        }
                    })} placeholder='Izaberite korisnika' className='w-full mt-3' style={{ height: 45 }} />
                ) : (
                    <Select onChange={(value) => setFormData((prev) => ({ ...prev, users: value}))}
                        mode="multiple"
                        className='w-full mt-3'
                        placeholder="Izaberi korisnike"
                        defaultValue={formData.users}
                        style={{ width: '100%' }}
                        options={users.map((user) => ({
                            label: user.name,
                            value: user._id
                        }))}
                    />
                ) : null
            }
            <div className='flex items-center gap-2 mt-3'>
                <button onClick={() => setFormData((prev) => ({...prev, importantLever: 0}))} className={`w-1/3 p-3 bg-gray-100 border rounded font-semibold text-green-500 ${formData.importantLever === 0 ? 'border-red-600' : null}`}>NORMALNO</button>
                <button onClick={() => setFormData((prev) => ({...prev, importantLever: 1}))} className={`w-1/3 p-3 bg-gray-100 border rounded font-semibold text-orange-500 ${formData.importantLever === 1 ? 'border-red-600' : null}`}>SREDNJE HITNO</button>
                <button onClick={() => setFormData((prev) => ({ ...prev, importantLever: 2 }))} className={`w-1/3 p-3 bg-gray-100 border rounded font-semibold text-red-500 ${formData.importantLever === 2 ? 'border-red-600' : null}`}>HITNO</button>
            </div>
            <button onClick={() => updateTask()} className='w-full p-3 text-sm bg-red-600 text-white rounded mt-3'>POTVRDI IZMJENE</button>
        </div>
    )
}

export default EditTask