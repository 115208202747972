import React, { useEffect, useState } from 'react'
import { Divider, Input, Modal, Select, Slider, message, DatePicker } from 'antd';
import { useAppData } from '../../context/ApplicationProvider';
import io from 'socket.io-client';
import { CheckCircle, Circle } from 'lucide-react';
import axios from 'axios';
import CalendarComponent from '../../componets/kalendarZadataka/CalendarComponent';
import { VscLayersActive } from "react-icons/vsc";
import { Link } from 'react-router-dom';
const { RangePicker } = DatePicker;

const socket = io("https://app.arenabackend.org", {
    reconnection: true
})
function Tasks() {

    const [formData, setFormData] = useState({
        title: "",
        description: "",
        sektor: "",
        users: [],
        importantLever: 0
    })
    const [usersData, setUsers] = useState([])

    const [selectedPage, setSelectedPage] = useState(1)
    const [zadaciType, setZadaciType] = useState("mojiZadaci");

    const { zadaci, users, sections, currentUser, fetchZadaci } = useAppData();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeTask, setActiveTask] = useState(null)
    const [messageApi, contextHolder] = message.useMessage();
    const [draggedTask, setDraggedTask] = useState(null);

    const handleDragStart = (zadatakId) => {
        setDraggedTask(zadatakId);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (sektorId) => {
        // Here, you can implement the logic to update the task's sector
        console.log(`Move task ${draggedTask} to sector ${sektorId}`);
        setDraggedTask(null);
    };


    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const clearFormData = () => {
        setFormData({
            title: "",
            description: "",
            sektor: "",
            importantLever: 0
        });
    };


    const submit = async () => {
        try {

            const response = await fetch(`https://app.arenabackend.org/zadaci/app-postavi-zadatak/${formData.sektor}/${currentUser._id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                // Handle non-successful response
                console.error(`Error: ${response}`);
                alert("Neuspješno kreiranje zadatka");
                return;
            }

            socket.emit('kreiranZadatak', usersData);

            const data = await response.json();

            messageApi.open({
                type: 'success',
                content: 'Uspiješno ste kreirali zadatak',
            });
            fetchZadaci()
        } catch (error) {
            console.error('Error:', error);
            alert("Greška prilikom slanja zahtjeva");
        }
    };


    const zavrsiZadatak = async (zadatakId) => {
        const finishedById = currentUser._id; // Assuming currentUser is the ID of the user who finished the task
        await axios.put(`https://app.arenabackend.org/zadaci/zavrsi-zadatak/${zadatakId}/${finishedById}`).then((res) => {
            socket.emit('finishedTask', `Zadatak "${zadaci.filter((zadatak) => zadatak._id.trim() === activeTask.trim()).map((zadatak) => zadatak.title)}" je uspješno završen! Završio ga je: ${currentUser.name}`);
            console.log('Zavrsen zadatak');
        }).catch((e) => console.log(e));
    }

    const obrisiZadatak = async (zadatakId) => {
        await axios.delete(`https://app.arenabackend.org/zadaci/obrisi-zadatak/${zadatakId}`).then((res) => {
            messageApi.open({
                type: 'success',
                content: 'Uspiješno ste obrisali zadatak',
            });
            fetchZadaci();
        }).catch((e) => console.log(e));
    }

    const setDueDateToZadatak = async (date) => {
        const apiUrl = `https://app.arenabackend.org/zadaci/postavi-rok-zadatka/${activeTask}`; // Replace with your API endpoint

        try {
            const response = await axios.put(apiUrl, { dueDate: date });
            socket.emit('dodatRokZadatka', "Postavljen je rok zadatka");
            messageApi.open({
                type: 'success',
                content: 'Uspiješno ste postavili rok zadatka',
            });
        } catch (error) {
            console.error('Error setting reminder date:', error.response.data);
        }
    };

    function formatDate(date) {
        if (!date) return "";
        // Dohvati dan, mjesec i godinu
        const day = date.getDate();
        const month = date.getMonth() + 1; // Mjeseci počinju od 0, pa se dodaje 1
        const year = date.getFullYear();

        // Dohvati sate i minute
        const hours = date.getHours();
        const minutes = date.getMinutes();

        // Formatiraj datum
        return `${day}.${month}.${year} u ${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    }

    const sortFunc = (a, b) => {

        if (a.importantLever < b.importantLever) return 1;
        if (a.importantLever > b.importantLever) return -1;

        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        if (dateA < dateB) return 1;
        if (dateA > dateB) return -1;

        return 0;
    }

    useEffect(() => {
        setFormData(prevData => ({
            ...prevData,
            users: usersData
        }));
    }, [usersData]);


    return (
        <div className='px-3 bg-white'>
            {contextHolder}
            <button onClick={(showModal)} className='w-full p-3 my-3 text-white text-xs uppercase' style={{ backgroundColor: '#C93030' }}>Kreiraj zadatak</button>
            {
                currentUser.role === "administrator" ? (
                    <select onChange={(e) => setZadaciType(e.target.value)} className='p-3 w-full text-sm border outline-none bg-gray-100' name="" id="">
                        <option value="mojiZadaci">Moji zadaci</option>
                        <option value="sviZadaci">Svi zadaci</option>
                    </select>
                ) : null
            }
            <div className="pages-selector flex items-center justify-between mb-3">
                <button onClick={() => setSelectedPage(1)} className={`w-1/2 p-3 bg-gray-50 border-b-2 ${selectedPage === 1 ? 'border-b-red-600' : null}`}>Zadaci</button>
                <button onClick={() => setSelectedPage(2)} className={`w-1/2 p-3 bg-gray-50 border-b-2 ${selectedPage === 2 ? 'border-b-red-600' : null}`}>Kalendar zadataka</button>
            </div>
            <Modal title="Kreiraj zadatak" open={isModalOpen} onOk={submit} onCancel={handleCancel} cancelText='Otkazi' okText='Potvrdi' okType='default' >
                <Input onChange={(e) => setFormData((prev) => ({ ...prev, title: e.target.value }))} placeholder='Unesite zadatak' className='p-2' />
                <Input onChange={(e) => setFormData((prev) => ({ ...prev, description: e.target.value }))} placeholder='Unesite opis zadatka' className='p-2 mt-3' />
                <Select className='w-full mt-3' style={{ height: 40 }} placeholder="Izaberite sektor" onChange={(e) => setFormData((prev) => ({ ...prev, sektor: e }))} options={sections.map((sector) => {
                    return {
                        label: sector.title,
                        value: sector._id
                    }
                })} />
                <Select
                    mode="multiple"
                    placeholder="Izaberi korisnike"
                    className='mt-3'
                    value={usersData}
                    onChange={(e) => setUsers(e)}
                    style={{ width: '100%' }}
                    options={users.map((user) => {
                        return {
                            label: user.name,
                            value: user._id
                        };
                    })}
                >
                </Select>
                <div className="w-full my-3">
                    <p className='text-xs uppercase font-bold'>Bitnost</p>
                    <Slider
                        min={0}
                        max={2}
                        className=''
                        onChange={(newValue) => setFormData({ ...formData, importantLever: newValue })}
                        defaultValue={typeof formData.importantLever === 'number' ? formData.importantLever : 0}
                    />
                </div>
            </Modal>
            {
                activeTask ? (
                    <Modal title="Izmjenite zadatak" open={activeTask} onOk={() => setActiveTask(null)} onCancel={() => setActiveTask(null)} cancelText='Otkazi' okText='Potvrdi' okType='default' >
                        <Input value={zadaci.filter((zadatak) => zadatak._id.trim() === activeTask.trim()).map((zd) => zd.title)} placeholder='Naziv zadataka' className='p-2' />
                        <Input value={zadaci.filter((zadatak) => zadatak._id.trim() === activeTask.trim()).map((zd) => zd.description)} placeholder='Opis zadatka' className='p-2 mt-3' />
                        <Select value={zadaci.filter((zadatak) => zadatak._id.trim() === activeTask.trim()).map((zd) => zd.sektor)} options={sections.map((sektor) => {
                            return {
                                label: sektor.title,
                                value: sektor._id.trim()
                            }
                        })} placeholder='Izaberite korisnika' className='w-full mt-3' style={{ height: 45 }} />
                        {
                            zadaci.filter((zadatak) => zadatak._id.trim() === activeTask.trim()).map((zd) => zd.users).length < 1 ? (
                                <Select value={zadaci.filter((zadatak) => zadatak._id.trim() === activeTask.trim()).map((zadatak) => zadatak.user)} options={users.map((user) => {
                                    return {
                                        label: user._id.trim() === currentUser._id.trim() ? `Vi (${user.name})` : user.name,
                                        value: user._id.trim()
                                    }
                                })} placeholder='Izaberite korisnika' className='w-full mt-3' style={{ height: 45 }} />
                            ) : (
                                    zadaci.filter((zadatak) => zadatak._id.trim() === activeTask.trim()).map((zd) => {
                                        return (
                                            <div className='mt-2'>
                                                {zd.users.map((user) => {
                                                    return (
                                                        <li className='list-decimal'>{users.filter((us) => us._id === user).map((us) => us.email)}</li>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })
                            )
                        }

                        <DatePicker
                            placeholder="Izaberite rok zadataka"
                            className='w-full p-3 mt-3'
                            showTime
                            onChange={(value, dateString) => {
                                console.log('Selected Time: ', value);
                                console.log('Formatted Selected Time: ', dateString);
                            }}
                            onOk={(value) => {
                                const formDate = new Date(value).toISOString()
                                setDueDateToZadatak(formDate)
                            }}
                        />
                        {
                            zadaci.filter(zadatak => zadatak._id.trim() === activeTask.trim()).map(zadatak => zadatak.createdAt)[0] ? (
                                <h1 className='uppercase text-sm mt-3 flex items-center gap-1'>
                                    <strong>Kreirao:</strong>
                                    {
                                        users
                                            .filter(user => user._id === zadaci
                                                .filter(zadatak => zadatak._id.trim() === activeTask.trim())[0].creator // Access the creator directly
                                            )
                                            .map(user => user.name)
                                    }
                                    <span>u</span>
                                    <span>
                                        {
                                            zadaci
                                                .filter(zadatak => zadatak._id.trim() === activeTask.trim())
                                                .map(zadatak => zadatak.createdAt)[0] ?
                                                formatDate(new Date(zadaci
                                                    .filter(zadatak => zadatak._id.trim() === activeTask.trim())
                                                    .map(zadatak => zadatak.createdAt)[0]
                                                )) : null
                                        }
                                    </span>
                                </h1>
                            ) : null
                        }
                        {
                            zadaci.filter(zadatak => zadatak._id.trim() === activeTask.trim()).map(zadatak => zadatak.endedAt)[0] ? (
                                <h1 className='uppercase text-sm mt-3 flex items-center gap-1'>
                                    <strong>Završio ga:</strong>
                                    <span>
                                        {zadaci
                                            .filter(zadatak => zadatak._id.trim() === activeTask.trim())
                                            .map(zadatak =>
                                                users.find(user => user._id === zadatak.finishedBy)?.name
                                            )}
                                    </span>
                                    <span>u</span>
                                    <span>
                                        {
                                            zadaci
                                                .filter(zadatak => zadatak._id.trim() === activeTask.trim())
                                                .map(zadatak => zadatak.createdAt)[0] ?
                                                formatDate(new Date(zadaci
                                                    .filter(zadatak => zadatak._id.trim() === activeTask.trim())
                                                    .map(zadatak => zadatak.endedAt)[0]
                                                )) : null
                                        }
                                    </span>
                                </h1>
                            ) : null
                        }
                        {
                            zadaci.filter(zadatak => zadatak._id.trim() === activeTask.trim()).map(zadatak => zadatak.dueDate)[0] ? (
                                <h1 className='uppercase text-sm mt-3 flex items-center gap-1'>
                                    <strong>Rok zadatka:</strong>
                                    <span>
                                        {
                                            zadaci
                                                .filter(zadatak => zadatak._id.trim() === activeTask.trim())
                                                .map(zadatak => zadatak.dueDate)[0] ?
                                                formatDate(new Date(zadaci
                                                    .filter(zadatak => zadatak._id.trim() === activeTask.trim())
                                                    .map(zadatak => zadatak.dueDate)[0]
                                                )) : null
                                        }
                                    </span>
                                </h1>
                            ) : null
                        }
                        <div className='flex items-center mt-5 gap-3'>
                            {
                                zadaci.filter(zadatak => zadatak._id.trim() === activeTask.trim()).map(zadatak => zadatak.endedAt)[0] ? null : <button className='p-3 w-1/3 text-center rounded text-white bg-blue-500' style={{ }} onClick={() => zavrsiZadatak(activeTask)}>ZAVRŠI ZADATAK</button>
                            }
                            {
                                zadaci.filter(zadatak => zadatak._id.trim() === activeTask.trim()).map(zadatak => zadatak.endedAt)[0] ? null : <Link to={`/user/logged-in/uredi-zadatak/${activeTask}`} className='p-3 w-1/3 text-center rounded text-white bg-orange-500' style={{ }}>UREDI ZADATAK</Link>
                            }
                            <button className='p-3 w-1/3 rounded text-white text-center bg-red-500' style={{ }} onClick={() => obrisiZadatak(activeTask)}>OBRISI ZADATAK</button>
                        </div>
                        
                    </Modal>
                ) : null
            }
            {
                selectedPage === 1 || zadaciType === "mojiZadaci" ? (
                    <>
                        {
                            currentUser.role !== "administrator" || zadaciType === "mojiZadaci" ? (
                                <>
                                    <h1 className='mb-4 font-semibold'>Vaši zadaci</h1>
                                    {
                                        sections.map((sektor) => {
                                            return (
                                                <div className='mb-3'>
                                                    <h3 className='mb-0'>- {sektor.title}</h3>
                                                    <div className="tasks flex flex-col pb-3">
                                                        {
                                                            zadaci.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).filter((zadatak) => (zadatak.user
                                                                === currentUser._id || zadatak.users.includes(currentUser._id)) && zadatak.status !== "Završeno" && zadatak.sektor === sektor._id).map((zadatak, i) => {
                                                                    return (
                                                                        <button key={i} onClick={() => setActiveTask(zadatak._id)} className={`p-1 flex border-b bg-gray-100 items-center justify-between hover:shadow-xl hover:shadow-gray-400 ${zadatak.importantLever === 0 ? 'bg-green-500' : zadatak.importantLever === 1 ? 'bg-orange-500' : 'bg-red-500'} ${zadatak.user === currentUser._id || zadatak.users.includes(currentUser._id) ? 'shadow-lg' : null}`}>
                                                                            {zadatak.status === "Završeno" ? <h1 className='flex items-center gap-2 text-sm'><CheckCircle size={25} fill="#d22829" stroke='white' /> {zadatak.staus || zadatak.status}</h1> :

                                                                                (
                                                                                    <div className='flex items-center gap-3 line-clamp-1' style={{ width: '70%' }}>
                                                                                        <button onClick={() => zavrsiZadatak(zadatak._id, zadatak.title)}>
                                                                                            <VscLayersActive size={24} color='green' />
                                                                                        </button>
                                                                                        <h1 style={{ minWidth: 'fit-content' }} className='uppercase text-xs font-semibold'>{zadatak.title}</h1>|
                                                                                        <h3 className='text-xs'>{zadatak.description}</h3>
                                                                                    </div>)}
                                                                            <div className='flex items-center gap-3'>
                                                                                {
                                                                                    zadatak.dueDate ? <h3 className='line-clamp-1 text-xs'>{formatDate(new Date(zadatak.dueDate))}</h3> : null
                                                                                }
                                                                                {
                                                                                    zadatak.users.length >= 1 ? (
                                                                                        zadatak.users.map((userz) => {
                                                                                            return (
                                                                                                <h3 className='text-white rounded-full flex items-center justify-center text-xs' style={{ width: 25, height: 25, backgroundColor: '#d22829' }}>{users.filter((user) => user._id === userz).map(user => {
                                                                                                    const nameParts = user.name.split(" ");
                                                                                                    const initials = nameParts.length >= 2 ?
                                                                                                        `${nameParts[0].charAt(0).toUpperCase()}${nameParts[1].charAt(0).toUpperCase()}` :
                                                                                                        nameParts[0].slice(0, 2).toUpperCase();
                                                                                                    return initials;
                                                                                                })}</h3>
                                                                                            )
                                                                                        })
                                                                                    ) : (
                                                                                        <h3 className='text-white rounded-full flex items-center justify-center text-xs' style={{ width: 25, height: 25, backgroundColor: '#d22829' }}>{users.filter((user) => user._id === zadatak.user).map(user => {
                                                                                            const nameParts = user.name.split(" ");
                                                                                            const initials = nameParts.length >= 2 ?
                                                                                                `${nameParts[0].charAt(0).toUpperCase()}${nameParts[1].charAt(0).toUpperCase()}` :
                                                                                                nameParts[0].slice(0, 2).toUpperCase();
                                                                                            return initials;
                                                                                        })}</h3>
                                                                                    )
                                                                                }
                                                                                <h3 className='text-right uppercase text-sm'>{sections.filter((sektor) => sektor._id === zadatak.sektor).map((sektor) => sektor.title)}</h3>
                                                                            </div>
                                                                        </button>
                                                                    )
                                                                })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className='w-full bg-gray-200 my-5' style={{ height: 1 }}></div>
                                    <div className="tasks flex flex-col pb-3">
                                        <h1>Kreirani zadaci</h1>
                                        {
                                            zadaci.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).filter((zadatak) => zadatak.creator
                                                === currentUser._id && zadatak.status !== "Završeno").map((zadatak, i) => {
                                                    if (zadatak.user === currentUser._id) {
                                                        return null
                                                    } else {
                                                        return (
                                                            <button key={i} onClick={() => setActiveTask(zadatak._id)} className={`p-1 flex border-b bg-gray-100 items-center justify-between hover:shadow-xl hover:shadow-gray-400 ${zadatak.importantLever === 0 ? 'bg-green-500' : zadatak.importantLever === 1 ? 'bg-orange-500' : 'bg-red-500'} ${zadatak.user === currentUser._id || zadatak.users.includes(currentUser._id) ? 'shadow-lg' : null}`}>
                                                                {zadatak.status === "Završeno" ? <h1 className='flex items-center gap-2 text-sm'><CheckCircle size={25} fill="#d22829" stroke='white' /> {zadatak.staus || zadatak.status}</h1> :

                                                                    (
                                                                        <div className='flex items-center gap-3 line-clamp-1' style={{ width: '70%' }}>
                                                                            <button onClick={() => zavrsiZadatak(zadatak._id, zadatak.title)}>
                                                                                <VscLayersActive size={24} color='green' />
                                                                            </button>
                                                                            <h1 style={{ minWidth: 'fit-content' }} className='uppercase text-xs font-semibold'>{zadatak.title}</h1>|
                                                                            <h3 className='text-xs'>{zadatak.description}</h3>
                                                                        </div>)}
                                                                <div className='flex items-center gap-3'>
                                                                    {
                                                                        zadatak.dueDate ? <h3 className='line-clamp-1 text-xs'>{formatDate(new Date(zadatak.dueDate))}</h3> : null
                                                                    }
                                                                    {
                                                                        zadatak.users.length >= 1 ? (
                                                                            zadatak.users.map((userz) => {
                                                                                return (
                                                                                    <h3 className='text-white rounded-full flex items-center justify-center text-xs' style={{ width: 25, height: 25, backgroundColor: '#d22829' }}>{users.filter((user) => user._id === userz).map(user => {
                                                                                        const nameParts = user.name.split(" ");
                                                                                        const initials = nameParts.length >= 2 ?
                                                                                            `${nameParts[0].charAt(0).toUpperCase()}${nameParts[1].charAt(0).toUpperCase()}` :
                                                                                            nameParts[0].slice(0, 2).toUpperCase();
                                                                                        return initials;
                                                                                    })}</h3>
                                                                                )
                                                                            })
                                                                        ) : (
                                                                            <h3 className='text-white rounded-full flex items-center justify-center text-xs' style={{ width: 25, height: 25, backgroundColor: '#d22829' }}>{users.filter((user) => user._id === zadatak.user).map(user => {
                                                                                const nameParts = user.name.split(" ");
                                                                                const initials = nameParts.length >= 2 ?
                                                                                    `${nameParts[0].charAt(0).toUpperCase()}${nameParts[1].charAt(0).toUpperCase()}` :
                                                                                    nameParts[0].slice(0, 2).toUpperCase();
                                                                                return initials;
                                                                            })}</h3>
                                                                        )
                                                                    }
                                                                    <h3 className='text-right uppercase text-sm'>{sections.filter((sektor) => sektor._id === zadatak.sektor).map((sektor) => sektor.title)}</h3>
                                                                </div>
                                                            </button>
                                                        )
                                                    }
                                                })
                                        }
                                    </div>
                                    <div className='w-full bg-gray-200 my-5' style={{ height: 1 }}></div>
                                    <div className="tasks flex flex-col pb-3">
                                        <h1>Završeni zadaci</h1>
                                        {
                                            zadaci.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).filter((zadatak) => (zadatak.user === currentUser._id || !zadatak.finishedBy) && zadatak.creator === currentUser._id && zadatak.status === "Završeno").map((zadatak, i) => {
                                                return (
                                                    <button key={i} onClick={() => setActiveTask(zadatak._id)} className={`p-1 flex border-b bg-gray-100 items-center justify-between hover:shadow-xl hover:shadow-gray-400 ${zadatak.importantLever === 0 ? 'bg-green-500' : zadatak.importantLever === 1 ? 'bg-orange-500' : 'bg-red-500'} ${zadatak.user === currentUser._id || zadatak.users.includes(currentUser._id) ? 'shadow-lg' : null}`}>
                                                        {zadatak.status === "Završeno" ? <h1 className='flex items-center gap-2 text-sm'><CheckCircle size={25} fill="#d22829" stroke='white' /> {zadatak.staus || zadatak.status}</h1> :

                                                            (
                                                                <div className='flex items-center gap-3 line-clamp-1' style={{ width: '70%' }}>
                                                                    <button onClick={() => zavrsiZadatak(zadatak._id, zadatak.title)}>
                                                                        <VscLayersActive size={24} color='green' />
                                                                    </button>
                                                                    <h1 style={{ minWidth: 'fit-content' }} className='uppercase text-xs font-semibold'>{zadatak.title}</h1>|
                                                                    <h3 className='text-xs'>{zadatak.description}</h3>
                                                                    <h3 className='text-xs'>{zadatak.description}</h3>
                                                                </div>)}
                                                        <div className='flex items-center gap-3'>
                                                            {
                                                                zadatak.dueDate ? <h3 className='line-clamp-1 text-xs'>{formatDate(new Date(zadatak.dueDate))}</h3> : null
                                                            }
                                                            {
                                                                zadatak.users.length >= 1 ? (
                                                                    zadatak.users.map((userz) => {
                                                                        return (
                                                                            <h3 className='text-white rounded-full flex items-center justify-center text-xs' style={{ width: 25, height: 25, backgroundColor: '#d22829' }}>{users.filter((user) => user._id === userz).map(user => {
                                                                                const nameParts = user.name.split(" ");
                                                                                const initials = nameParts.length >= 2 ?
                                                                                    `${nameParts[0].charAt(0).toUpperCase()}${nameParts[1].charAt(0).toUpperCase()}` :
                                                                                    nameParts[0].slice(0, 2).toUpperCase();
                                                                                return initials;
                                                                            })}</h3>
                                                                        )
                                                                    })
                                                                ) : (
                                                                    <h3 className='text-white rounded-full flex items-center justify-center text-xs' style={{ width: 25, height: 25, backgroundColor: '#d22829' }}>{users.filter((user) => user._id === zadatak.user).map(user => {
                                                                        const nameParts = user.name.split(" ");
                                                                        const initials = nameParts.length >= 2 ?
                                                                            `${nameParts[0].charAt(0).toUpperCase()}${nameParts[1].charAt(0).toUpperCase()}` :
                                                                            nameParts[0].slice(0, 2).toUpperCase();
                                                                        return initials;
                                                                    })}</h3>
                                                                )
                                                            }
                                                            <h3 className='text-right uppercase text-sm'>{sections.filter((sektor) => sektor._id === zadatak.sektor).map((sektor) => sektor.title)}</h3>
                                                        </div>
                                                    </button>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="tasks flex flex-col pb-3">
                                        <h1 className='mb-3 font-semibold'>Svi aktivni zadaci</h1>
                                        {
                                            sections.map((sektor) => {
                                                return (
                                                    <div onDragOver={(e) => handleDragOver(e)} onDrop={() => handleDrop(sektor._id)} className='w-full'>
                                                        <h3 className='mb-0 mt-3 mb-1'>- {sektor.title}</h3>
                                                        {
                                                            zadaci.sort(sortFunc).filter((zadatak) => zadatak.status !== "Završeno" && zadatak.sektor === sektor._id).map((zadatak, i) => {
                                                                return (
                                                                    <button draggable
                                                                        onDragStart={(e) => handleDragStart(e, zadatak._id)}
                                                                        key={i}
                                                                        onClick={() => setActiveTask(zadatak._id)}
                                                                        className={`p-1 w-full flex border-b bg-gray-100 items-center justify-between hover:shadow-xl hover:shadow-gray-400 ${zadatak.importantLever === 0 ? 'bg-green-500' : zadatak.importantLever === 1 ? 'bg-orange-500' : 'bg-red-500'} ${zadatak.user === currentUser._id || zadatak.users.includes(currentUser._id) ? 'shadow-lg' : ''}`}
                                                                        onDragOver={(e) => handleDragOver(e)}
                                                                        onDrop={() => handleDrop(sektor._id)}>
                                                                        {zadatak.status === "Završeno" ? <h1 className='flex items-center gap-2 text-sm'><CheckCircle size={25} fill="#d22829" stroke='white' /> {zadatak.staus || zadatak.status}</h1> :

                                                                            (
                                                                                <div className='flex items-center gap-3 line-clamp-1' style={{ width: '70%' }}>
                                                                                    <button onClick={() => zavrsiZadatak(zadatak._id, zadatak.title)}>
                                                                                        <VscLayersActive size={24} color='green' />
                                                                                    </button>
                                                                                    <h1 style={{ minWidth: 'fit-content' }} className='uppercase text-xs font-semibold'>{zadatak.title}</h1>|
                                                                                    <h3 className='text-xs'>{zadatak.description}</h3>
                                                                                </div>)}
                                                                        <div className='flex items-center gap-3'>
                                                                            {
                                                                                zadatak.dueDate ? <h3 className='line-clamp-1 text-xs'>{formatDate(new Date(zadatak.dueDate))}</h3> : null
                                                                            }
                                                                            {
                                                                                zadatak.users.length >= 1 ? (
                                                                                    zadatak.users.map((userz) => {
                                                                                        return (
                                                                                            <h3 className='text-white rounded-full flex items-center justify-center text-xs' style={{ width: 25, height: 25, backgroundColor: '#d22829' }}>{users.filter((user) => user._id === userz).map(user => {
                                                                                                const nameParts = user.name.split(" ");
                                                                                                const initials = nameParts.length >= 2 ?
                                                                                                    `${nameParts[0].charAt(0).toUpperCase()}${nameParts[1].charAt(0).toUpperCase()}` :
                                                                                                    nameParts[0].slice(0, 2).toUpperCase();
                                                                                                return initials;
                                                                                            })}</h3>
                                                                                        )
                                                                                    })
                                                                                ) : (
                                                                                    <h3 className='text-white rounded-full flex items-center justify-center text-xs' style={{ width: 25, height: 25, backgroundColor: '#d22829' }}>{users.filter((user) => user._id === zadatak.user).map(user => {
                                                                                        const nameParts = user.name.split(" ");
                                                                                        const initials = nameParts.length >= 2 ?
                                                                                            `${nameParts[0].charAt(0).toUpperCase()}${nameParts[1].charAt(0).toUpperCase()}` :
                                                                                            nameParts[0].slice(0, 2).toUpperCase();
                                                                                        return initials;
                                                                                    })}</h3>
                                                                                )
                                                                            }
                                                                            <h3 className='text-right uppercase text-sm'>{sections.filter((sektor) => sektor._id === zadatak.sektor).map((sektor) => sektor.title)}</h3>
                                                                        </div>
                                                                    </button>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className='w-full bg-gray-200 my-5' style={{ height: 1 }}></div>
                                    <div className="tasks flex flex-col gap-3 pb-3">
                                        <h1>Završeni zadaci</h1>
                                        {
                                            zadaci.sort((a, b) => {
                                                if (a.endedAt && b.endedAt) {
                                                    return new Date(b.endedAt) - new Date(a.endedAt)
                                                } else if (!a.endedAt && b.endedAt) {
                                                    return 0;
                                                } else if (!a.endedAt) {
                                                    return 1;
                                                } else if (!b.endedAt) {
                                                    return -1;
                                                }
                                            }).filter((zadatak) => zadatak.status === "Završeno").map((zadatak, i) => {
                                                return (
                                                    <button key={i} onClick={() => setActiveTask(zadatak._id)} className={`py-1 px-3 flex bg-gray-100 items-center justify-between hover:bg-gray-200 border ${zadatak.user === currentUser._id || zadatak.users.includes(currentUser._id) ? 'shadow-lg' : null}`}>
                                                        {zadatak.status === "Završeno" ? <h1 className='flex items-center gap-2 text-xs'><CheckCircle size={25} fill="#d22829" stroke='white' /> {zadatak.title} | {zadatak.description}</h1> :

                                                            (
                                                                <div className='flex items-center gap-3'>
                                                                    <button onClick={() => zavrsiZadatak(zadatak._id, zadatak.title)}>
                                                                        <VscLayersActive size={24} color='green' />
                                                                    </button>
                                                                    <h1 className='uppercase text-xs'>{zadatak.title}</h1>
                                                                    <h3 className='text-xs'>{zadatak.description}</h3>
                                                                </div>)}
                                                        <div className='flex items-center gap-3'>
                                                            {
                                                                zadatak.users.length >= 1 ? (
                                                                    zadatak.users.map((userz) => {
                                                                        return (
                                                                            <h3 className='text-white text-sm rounded-full flex items-center justify-center' style={{ width: 35, height: 35, backgroundColor: '#d22829' }}>{users.filter((user) => user._id === userz).map(user => {
                                                                                const nameParts = user.name.split(" ");
                                                                                const initials = nameParts.length >= 2 ?
                                                                                    `${nameParts[0].charAt(0).toUpperCase()}${nameParts[1].charAt(0).toUpperCase()}` :
                                                                                    nameParts[0].slice(0, 2).toUpperCase();
                                                                                return initials;
                                                                            })}</h3>
                                                                        )
                                                                    })
                                                                ) : (
                                                                    <h3 className='text-white text-sm rounded-full flex items-center justify-center' style={{ width: 35, height: 35, backgroundColor: '#d22829' }}>{users.filter((user) => user._id === zadatak.user).map(user => {
                                                                        const nameParts = user.name.split(" ");
                                                                        const initials = nameParts.length >= 2 ?
                                                                            `${nameParts[0].charAt(0).toUpperCase()}${nameParts[1].charAt(0).toUpperCase()}` :
                                                                            nameParts[0].slice(0, 2).toUpperCase();
                                                                        return initials;
                                                                    })}</h3>
                                                                )
                                                            }
                                                            <h3 className='text-right uppercase text-sm'>{sections.filter((sektor) => sektor._id === zadatak.sektor).map((sektor) => sektor.title)}</h3>
                                                        </div>
                                                    </button>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                            )
                        }
                    </>
                ) : (
                    <>
                        <h1>Dolazi uskoro</h1>
                    </>
                )
            }


        </div>
    )
}

export default Tasks