import React, { createRef, useEffect, useRef, useState } from 'react';
import { Space, Image } from 'antd';
import { CiCircleCheck, CiCircleRemove } from "react-icons/ci";
import { InboxIcon } from 'lucide-react';
import {
    DownloadOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import html2pdf from 'html2pdf.js';
import { Link } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ReactToPrint from "react-to-print";


function PrijemNaZalihu({ data, status }) {
    const formatDate = (date) => {
        const dati = new Date(date);
        return `${dati.getDate()}.${dati.getMonth() + 1}.${dati.getFullYear()} u ${dati.getHours()}h i ${dati.getMinutes()}min`;
    };

    // const [image, takeScreenshot] = useScreenshot()


    const jsxContentRef = useRef(null);

    return (
        <div style={{ height: '90vh', width: 800, right: 50, top: '50%', transform: 'translateY(-50%)' }} className='bg-white absolute border rounded-lg overflow-auto'>
            {
                data ? (
                    <div className='relative'>
                        <div className='flex items-center'>
                            {/* <button className='p-3 w-1/2 text-white' style={{ backgroundColor: '#d22829', height: 45 }} onClick={() => window.print()}>Preuzmi PDF dokument</button> */}
                            <ReactToPrint
                                documentTitle={`${data.vozilo} | ${data.sasija} - Arena Motors`}
                                bodyClass="print-agreement"
                                content={() => jsxContentRef.current}
                                trigger={() => (
                                    <button className='p-3 w-1/2 text-white' style={{ backgroundColor: '#d22829', height: 45 }}>Preuzmi PDF</button>
                                )}
                            />
                            <Link to={`/user/logged-in/prijem-na-zalihu-edit/${data._id}`} className='py-1 border-l px-3 flex items-center justify-center text-center bg-white hover:bg-gray-200 w-1/2' style={{ color: '#d22829', height: 45, zIndex: 999999 }}>Uredite prijem</Link>
                        </div>
                        <div ref={jsxContentRef}>
                            <div className="zaliha-header bg-black p-3 text-white flex flex-row items-center justify-between">
                                <h1 className='text-xs'>{data.vozilo}</h1>
                                <h1 className='text-xs'>{data.sasija}</h1>
                                <h1 className='text-xs'>{data.createdAt ? formatDate(data.createdAt) : 'NEMA DATUMA'}</h1>
                            </div>
                            <div className='p-3 flex items-center text-black justify-between flex-wrap'>
                                <h1 className='text-xs p-3 border-b-2' style={{ width: '32%', fontSize: 12 }}><strong>Kilometraža: </strong>{data.kilometraza ? data.kilometraza.toLocaleString(void 0, { maximumFragmentDigits: 2 }).replace(",", ".") : null}</h1>
                                <h1 className='text-xs p-3 border-b-2' style={{ width: '32%', fontSize: 12 }}><strong>Transmisija: </strong>{data.mjenjac}</h1>
                                <h1 className='text-xs p-3 border-b-2' style={{ width: '32%', fontSize: 12 }}><strong>Zapremina: </strong>{data.zapremina}</h1>
                                <h1 className='text-xs p-3 border-b-2' style={{ width: '32%', fontSize: 12 }}><strong>Snaga: </strong>{data.snaga}kW</h1>
                                <h1 className='text-xs p-3 border-b-2' style={{ width: '32%', fontSize: 12 }}><strong>Boja: </strong>{data.boja}</h1>
                                <h1 className='text-xs p-3 border-b-2' style={{ width: '32%', fontSize: 12 }}><strong>Godište: </strong>{data.godiste}</h1>
                                <h1 className='text-xs p-3 border-b-2' style={{ width: '32%', fontSize: 12 }}><strong>Gorivo: </strong>{data.gorivo}</h1>
                                <h1 className='text-xs p-3 border-b-2' style={{ width: '32%', fontSize: 12 }}><strong>Dobavljač: </strong>{data.dobavljac}</h1>
                                <h1 className='text-xs p-3 border-b-2' style={{ width: '32%', fontSize: 12 }}><strong>Reg. oznake: </strong>{data.regOznake}</h1>

                                <h1 className='text-xs p-3 border-b-2 flex items-center justify-between' style={{ width: '32%', fontSize: 12 }}><strong>Došle uz vozilo: </strong>{data.dosleUzVozilo === true || data.regOznake.length > 1 ? <CiCircleCheck size={20} color='#31ad41' /> : <CiCircleRemove size={20} color='#bf2828' />}</h1>
                                <h1 className='text-xs p-3 border-b-2 flex items-center justify-between' style={{ width: '32%', fontSize: 12 }}><strong>Vlasnička knjižica: </strong>{data.vlasnickaKnjizica === true ? <CiCircleCheck size={20} color='#31ad41' /> : <CiCircleRemove size={20} color='#bf2828' />}</h1>
                                <h1 className='text-xs p-3 border-b-2 flex items-center justify-between' style={{ width: '32%', fontSize: 12 }}><strong>Saobraćajna dozvola: </strong>{data.saobracajnaDozvola === true ? <CiCircleCheck size={20} color='#31ad41' /> : <CiCircleRemove size={20} color='#bf2828' />}</h1>
                                <h1 className='text-xs p-3 border-b-2 flex items-center justify-between' style={{ width: '32%', fontSize: 12 }}><strong>Registrovan: </strong>{data.registrovan === true ? <CiCircleCheck size={20} color='#31ad41' /> : <CiCircleRemove size={20} color='#bf2828' />}</h1>
                                <h1 className='text-xs p-3 border-b-2 flex items-center justify-between' style={{ width: '32%', fontSize: 12 }}><strong>Polisa osiguranja: </strong>{data.polisaOsiguranja === true ? <CiCircleCheck size={20} color='#31ad41' /> : <CiCircleRemove size={20} color='#bf2828' />}</h1>
                                <h1 className='text-xs p-3 border-b-2' style={{ width: '32%', fontSize: 12 }}><strong>Registrovan do: </strong>{data.registrovanDo}</h1>
                                <h1 className='text-xs p-3 border-b-2' style={{ width: '32%', fontSize: 12 }}><strong>Broj ključeva: </strong>{data.brojKljuceva}</h1>
                                <h1 className='text-xs p-3 border-b-2 flex items-center justify-between' style={{ width: '32%', fontSize: 12 }}><strong>3 Ovjerena otkupna ugovora: </strong>{data.triOvjerenaOtkupnaUgovora === true ? <CiCircleCheck size={20} color='#31ad41' /> : <CiCircleRemove size={20} color='#bf2828' />}</h1>
                                <h1 className='text-xs p-3 border-b-2 flex items-center justify-between' style={{ width: '32%', fontSize: 12 }}><strong>Faktura s' fiskalnim računom: </strong>{data.fakturaSaFiskalnimRacunom === true ? <CiCircleCheck size={20} color='#31ad41' /> : <CiCircleRemove size={20} color='#bf2828' />}</h1>
                                <h1 className='text-xs p-3 border-b-2 flex items-center justify-between' style={{ width: '32%', fontSize: 12 }}><strong>Odluka o prodaji: </strong>{data.odlukaOprodaji === true ? <CiCircleCheck size={20} color='#31ad41' /> : <CiCircleRemove size={20} color='#bf2828' />}</h1>
                                <div className='w-full bg-black text-white p-3'>
                                    <h3 className='text-xs uppercase'>Pneumatici</h3>
                                </div>
                                <h1 className='text-xs p-3 border-b-2' style={{ width: '25%', fontSize: 12 }}><strong>Brend gume: </strong>{data.brendGume}</h1>
                                <h1 className='text-xs p-3 border-b-2' style={{ width: '29%', fontSize: 12 }}><strong>Dimenzije gume: </strong>{data.dimenzijeGume}</h1>
                                <h1 className='text-xs p-3 border-b-2' style={{ width: '13%', fontSize: 12 }}><strong>DOT: </strong>{data.dot}</h1>
                                <h1 className='text-xs p-3 border-b-2' style={{ width: '26%', fontSize: 12 }}><strong>Sezona gume: </strong>{data.tockoviZona}</h1>
                                <h1 className='text-xs p-3 border-b-2 flex items-center justify-between' style={{ width: '100%' }}><strong>Drugi set pneumatika: </strong>{data.drugeGume === true ? <CiCircleCheck size={20} color='#31ad41' /> : <CiCircleRemove size={20} color='#bf2828' />}</h1>
                                {
                                    data.drugeGume ? (
                                        <>
                                            <h1 className='text-xs p-3 border-b-2' style={{ width: '25%', fontSize: 12 }}><strong>Brend gume: </strong>{data.brendDrugeGume}</h1>
                                            <h1 className='text-xs p-3 border-b-2' style={{ width: '29%', fontSize: 12 }}><strong>Dimenzije gume: </strong>{data.dimenzijeDrugeGume}</h1>
                                            <h1 className='text-xs p-3 border-b-2' style={{ width: '13%', fontSize: 12 }}><strong>DOT: </strong>{data.dotDrugeGume}</h1>
                                            <h1 className='text-xs p-3 border-b-2' style={{ width: '26%', fontSize: 12 }}><strong>Sezona gume: </strong>{data.drugeGume === false ? null : data.tockoviZonaDrugeGume}</h1>
                                        </>
                                    ) : null
                                }
                                <div className='w-full bg-black text-white p-3'>
                                    <h3 className='text-xs uppercase'>Dodatno</h3>
                                </div>
                                <h1 className='text-xs p-3 border-b-2' style={{ width: '32%', fontSize: 12 }}><strong>Prostirači: </strong>{data.prostiraci}</h1>
                                <h1 className='text-xs p-3 border-b-2 flex items-center justify-between' style={{ width: '32%', fontSize: 12 }}><strong>Servisna knjižica: </strong>{data.odlukaOprodaji === true ? <CiCircleCheck size={20} color='#31ad41' /> : <CiCircleRemove size={20} color='#bf2828' />}</h1>
                                <h1 className='text-xs p-3 border-b-2 flex items-center justify-between' style={{ width: '32%', fontSize: 12 }}><strong>Veliki servis: </strong>{data.velikiServis === true ? <CiCircleCheck size={20} color='#31ad41' /> : <CiCircleRemove size={20} color='#bf2828' />}</h1>
                                {
                                    data.velikiServis ? <h1 className='text-xs p-3 border-b-2' style={{ width: '32%', fontSize: 12 }}><strong>Kilometraža: </strong>{data.velikiServisKm}</h1> : null
                                }
                                <h1 className='text-xs p-3 border-b-2 flex items-center justify-between' style={{ width: '32%', fontSize: 12 }}><strong>Mali servis: </strong>{data.maliServis === true ? <CiCircleCheck size={20} color='#31ad41' /> : <CiCircleRemove size={20} color='#bf2828' />}</h1>
                                {
                                    data.maliServis ? <h1 className='text-xs p-3 border-b-2' style={{ width: '32%', fontSize: 12 }}><strong>Kilometraža: </strong>{data.maliServisKm}</h1> : null
                                }
                                <h1 className='text-xs p-3 border-b-2 flex items-center justify-between' style={{ width: '32%', fontSize: 12 }}><strong>Rezervni točak: </strong>{data.rezervniTocak === true ? <CiCircleCheck size={20} color='#31ad41' /> : <CiCircleRemove size={20} color='#bf2828' />}</h1>
                                <h1 className='text-xs p-3 border-b-2 flex items-center justify-between' style={{ width: '32%', fontSize: 12 }}><strong>Dizalica + Ključ: </strong>{data.dizalicaPlusKljuc === true ? <CiCircleCheck size={20} color='#31ad41' /> : <CiCircleRemove size={20} color='#bf2828' />}</h1>
                                <h1 className='text-xs p-3 border-b-2 flex items-center justify-between' style={{ width: '32%', fontSize: 12 }}><strong>Prva pomoć: </strong>{data.prvaPomoc === true ? <CiCircleCheck size={20} color='#31ad41' /> : <CiCircleRemove size={20} color='#bf2828' />}</h1>
                                <h1 className='text-xs p-3 border-b-2 flex items-center justify-between' style={{ width: '32%', fontSize: 12 }}><strong>Kuka: </strong>{data.kuka === true ? <CiCircleCheck size={20} color='#31ad41' /> : <CiCircleRemove size={20} color='#bf2828' />}</h1>
                                <h1 className='text-xs p-3 border-b-2 flex items-center justify-between' style={{ width: '32%', fontSize: 12 }}><strong>Lopov šarafi: </strong>{data.lopovSarafi === true ? <CiCircleCheck size={20} color='#31ad41' /> : <CiCircleRemove size={20} color='#bf2828' />}</h1>
                                <h1 className='text-xs p-3 border-b-2 flex items-center justify-between' style={{ width: '32%', fontSize: 12 }}><strong>Lopov nastavak: </strong>{data.lopovNastavak === true ? <CiCircleCheck size={20} color='#31ad41' /> : <CiCircleRemove size={20} color='#bf2828' />}</h1>
                                <h1 className='text-xs p-3 border-b-2 flex items-center justify-between' style={{ width: '32%', fontSize: 12 }}><strong>Polica u gepeku: </strong>{data.policaUgepeku === true ? <CiCircleCheck size={20} color='#31ad41' /> : <CiCircleRemove size={20} color='#bf2828' />}</h1>
                                <h1 className='text-xs p-3 border-b-2' style={{ width: '32%', fontSize: 12 }}><strong>Alarm (vrsta i lokacija): </strong>{data.alarmVrstaAlarmaLokacija}</h1>
                                <div className='w-full bg-black text-white p-3'>
                                    <h3 className='text-xs uppercase'>Potrebni radovi</h3>
                                </div>
                                {
                                    data.potrebniRadovi.map((rad) => {
                                        return (
                                            <li className='w-full p-3 border-b-2' style={{ width: '49%', fontSize: 10 }}>{rad}</li>
                                        )
                                    })
                                }
                            </div>
                            <div className='flex items-center flex-wrap'>
                                {data.images.map((img, index) => (
                                    <img
                                        key={index}
                                        src={img ? img.replaceAll('http', 'https') : 'https://i0.wp.com/ourscene.org/wp-content/uploads/2022/01/placeholder-2.png?fit=1200%2C800&ssl=1'}
                                        alt={`Image ${index}`}
                                        className='image flex items-center justify-center mb-3 mx-3'
                                        style={{ height: 260, width: '46.5%' }}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>

                ) : (
                    <p>Loading...</p>
                )
            }
        </div>
    )
}

export default PrijemNaZalihu