import { Avatar, Modal, message, Input, Select, Slider, DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAppData } from '../../context/ApplicationProvider';
import axios from 'axios';
import io from 'socket.io-client';
import { Link } from 'react-router-dom';

const socket = io("https://app.arenabackend.org", {
    reconnection: true
})

function VozilaUpripremi() {

    const { zadaci, users, sections, currentUser, fetchZadaci, prijemi, fetchPrijemi } = useAppData();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeTask, setActiveTask] = useState(null)
    const [messageApi, contextHolder] = message.useMessage();
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        sektor: "",
        users: [],
        importantLever: 0
    })
    const [usersData, setUsers] = useState([])

    const showModal = (titles) => {
        setFormData((prev) => ({ ...prev, title: titles }))
        setIsModalOpen(true);
    };

    console.log("Pon", new Date("2024-05-13").toISOString())

    const submit = async () => {
        try {

            const response = await fetch(`https://app.arenabackend.org/zadaci/app-postavi-zadatak/${formData.sektor}/${currentUser._id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                // Handle non-successful response
                console.error(`Error: ${response}`);
                alert("Neuspješno kreiranje zadatka");
                return;
            }

            socket.emit('kreiranZadatak', usersData);

            messageApi.open({
                type: 'success',
                content: 'Uspiješno ste kreirali zadatak',
            });
            fetchZadaci()
        } catch (error) {
            console.error('Error:', error);
            alert("Greška prilikom slanja zahtjeva");
        }
    };


    const zavrsiZadatak = async (zadatakId) => {
        const finishedById = currentUser._id; // Assuming currentUser is the ID of the user who finished the task
        await axios.put(`https://app.arenabackend.org/zadaci/zavrsi-zadatak/${zadatakId}/${finishedById}`).then((res) => {
            socket.emit('finishedTask', `Zadatak "${zadaci.filter((zadatak) => zadatak._id.trim() === activeTask.trim()).map((zadatak) => zadatak.title)}" je uspješno završen! Završio ga je: ${currentUser.name}`);
            console.log('Zavrsen zadatak');
            fetchZadaci();
        }).catch((e) => console.log(e));
    }

    const obrisiZadatak = async (zadatakId) => {
        await axios.delete(`https://app.arenabackend.org/zadaci/obrisi-zadatak/${zadatakId}`).then((res) => {
            messageApi.open({
                type: 'success',
                content: 'Uspiješno ste obrisali zadatak',
            });
            fetchZadaci();
        }).catch((e) => console.log(e));
    }

    const setDueDateToZadatak = async (date) => {
        const apiUrl = `https://app.arenabackend.org/zadaci/postavi-rok-zadatka/${activeTask}`; // Replace with your API endpoint

        try {
            await axios.put(apiUrl, { dueDate: date });
            socket.emit('dodatRokZadatka', "Postavljen je rok zadatka");
            messageApi.open({
                type: 'success',
                content: 'Uspiješno ste postavili rok zadatka',
            });
            fetchZadaci();
        } catch (error) {
            console.error('Error setting reminder date:', error.response.data);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const formatDate = (date) => {
        const dati = new Date(date)
        return `${dati.getDate()}.${dati.getMonth() + 1}.${dati.getFullYear()}`;
    }

    useEffect(() => {
        setFormData(prevData => ({
            ...prevData,
            users: usersData
        }));
    }, [usersData]);


    return (
        <div className='p-3'>
            {contextHolder}
            <Modal title="Kreiraj zadatak" open={isModalOpen} onOk={submit} onCancel={handleCancel} cancelText='Otkazi' okText='Potvrdi' okType='default' >
                <Input value={formData.title} onChange={(e) => setFormData((prev) => ({ ...prev, title: e.target.value }))} placeholder='Unesite zadatak' className='p-2' />
                <Input onChange={(e) => setFormData((prev) => ({ ...prev, description: e.target.value }))} placeholder='Unesite opis zadatka' className='p-2 mt-3' />
                <Select className='w-full mt-3' style={{ height: 40 }} placeholder="Izaberite sektor" onChange={(e) => setFormData((prev) => ({ ...prev, sektor: e }))} options={sections.map((sector) => {
                    return {
                        label: sector.title,
                        value: sector._id
                    }
                })} />
                <Select
                    mode="multiple"
                    placeholder="Izaberi korisnike"
                    className='mt-3'
                    value={usersData}
                    onChange={(e) => setUsers(e)}
                    style={{ width: '100%' }}
                    options={users.map((user) => {
                        return {
                            label: user.name,
                            value: user._id
                        };
                    })}
                >
                </Select>
                <div className="w-full my-3">
                    <p className='text-xs uppercase font-bold'>Bitnost</p>
                    <Slider
                        min={0}
                        max={2}
                        className=''
                        onChange={(newValue) => setFormData({ ...formData, importantLever: newValue })}
                        defaultValue={typeof formData.importantLever === 'number' ? formData.importantLever : 0}
                    />
                </div>
            </Modal>
            {
                activeTask ? (
                    <Modal title="Izmjenite zadatak" open={activeTask} onOk={() => setActiveTask(null)} onCancel={() => setActiveTask(null)} cancelText='Otkazi' okText='Potvrdi' okType='default' >
                        <Input value={zadaci.filter((zadatak) => zadatak._id.trim() === activeTask.trim()).map((zd) => zd.title)} placeholder='Naziv zadataka' className='p-2' />
                        <Input value={zadaci.filter((zadatak) => zadatak._id.trim() === activeTask.trim()).map((zd) => zd.description)} placeholder='Opis zadatka' className='p-2 mt-3' />
                        <Select value={zadaci.filter((zadatak) => zadatak._id.trim() === activeTask.trim()).map((zd) => zd.sektor)} options={sections.map((sektor) => {
                            return {
                                label: sektor.title,
                                value: sektor._id.trim()
                            }
                        })} placeholder='Izaberite korisnika' className='w-full mt-3' style={{ height: 45 }} />
                        {
                            zadaci.filter((zadatak) => zadatak._id.trim() === activeTask.trim()).map((zd) => zd.users).length < 1 ? (
                                <Select value={zadaci.filter((zadatak) => zadatak._id.trim() === activeTask.trim()).map((zadatak) => zadatak.user)} options={users.map((user) => {
                                    return {
                                        label: user._id.trim() === currentUser._id.trim() ? `Vi (${user.name})` : user.name,
                                        value: user._id.trim()
                                    }
                                })} placeholder='Izaberite korisnika' className='w-full mt-3' style={{ height: 45 }} />
                            ) : (
                                zadaci.filter((zadatak) => zadatak._id.trim() === activeTask.trim()).map((zd) => {
                                    return (
                                        <div className='mt-2'>
                                            {zd.users.map((user) => {
                                                return (
                                                    <li className='list-decimal'>{users.filter((us) => us._id === user).map((us) => us.email)}</li>
                                                )
                                            })}
                                        </div>
                                    )
                                })
                            )
                        }

                        <DatePicker
                            placeholder="Izaberite rok zadataka"
                            className='w-full p-3 mt-3'
                            showTime
                            onChange={(value, dateString) => {
                                console.log('Selected Time: ', value);
                                console.log('Formatted Selected Time: ', dateString);
                            }}
                            onOk={(value) => {
                                const formDate = new Date(value).toISOString()
                                setDueDateToZadatak(formDate)
                            }}
                        />
                        {
                            zadaci.filter(zadatak => zadatak._id.trim() === activeTask.trim()).map(zadatak => zadatak.createdAt)[0] ? (
                                <h1 className='uppercase text-sm mt-3 flex items-center gap-1'>
                                    <strong>Kreirao:</strong>
                                    {
                                        users
                                            .filter(user => user._id === zadaci
                                                .filter(zadatak => zadatak._id.trim() === activeTask.trim())[0].creator // Access the creator directly
                                            )
                                            .map(user => user.name)
                                    }
                                    <span>u</span>
                                    <span>
                                        {
                                            zadaci
                                                .filter(zadatak => zadatak._id.trim() === activeTask.trim())
                                                .map(zadatak => zadatak.createdAt)[0] ?
                                                formatDate(new Date(zadaci
                                                    .filter(zadatak => zadatak._id.trim() === activeTask.trim())
                                                    .map(zadatak => zadatak.createdAt)[0]
                                                )) : null
                                        }
                                    </span>
                                </h1>
                            ) : null
                        }
                        {
                            zadaci.filter(zadatak => zadatak._id.trim() === activeTask.trim()).map(zadatak => zadatak.endedAt)[0] ? (
                                <h1 className='uppercase text-sm mt-3 flex items-center gap-1'>
                                    <strong>Završio ga:</strong>
                                    <span>
                                        {zadaci
                                            .filter(zadatak => zadatak._id.trim() === activeTask.trim())
                                            .map(zadatak =>
                                                users.find(user => user._id === zadatak.finishedBy)?.name
                                            )}
                                    </span>
                                    <span>u</span>
                                    <span>
                                        {
                                            zadaci
                                                .filter(zadatak => zadatak._id.trim() === activeTask.trim())
                                                .map(zadatak => zadatak.createdAt)[0] ?
                                                formatDate(new Date(zadaci
                                                    .filter(zadatak => zadatak._id.trim() === activeTask.trim())
                                                    .map(zadatak => zadatak.endedAt)[0]
                                                )) : null
                                        }
                                    </span>
                                </h1>
                            ) : null
                        }
                        {
                            zadaci.filter(zadatak => zadatak._id.trim() === activeTask.trim()).map(zadatak => zadatak.dueDate)[0] ? (
                                <h1 className='uppercase text-sm mt-3 flex items-center gap-1'>
                                    <strong>Rok zadatka:</strong>
                                    <span>
                                        {
                                            zadaci
                                                .filter(zadatak => zadatak._id.trim() === activeTask.trim())
                                                .map(zadatak => zadatak.dueDate)[0] ?
                                                formatDate(new Date(zadaci
                                                    .filter(zadatak => zadatak._id.trim() === activeTask.trim())
                                                    .map(zadatak => zadatak.dueDate)[0]
                                                )) : null
                                        }
                                    </span>
                                </h1>
                            ) : null
                        }
                        <div className='flex items-center mt-5 gap-3'>
                            {
                                zadaci.filter(zadatak => zadatak._id.trim() === activeTask.trim()).map(zadatak => zadatak.endedAt)[0] ? null : <button className='p-3 w-1/3 text-center rounded text-white bg-blue-500' style={{}} onClick={() => zavrsiZadatak(activeTask)}>ZAVRŠI ZADATAK</button>
                            }
                            {
                                zadaci.filter(zadatak => zadatak._id.trim() === activeTask.trim()).map(zadatak => zadatak.endedAt)[0] ? null : <Link to={`/user/logged-in/uredi-zadatak/${activeTask}`} className='p-3 w-1/3 text-center rounded text-white bg-orange-500' style={{}}>UREDI ZADATAK</Link>
                            }
                            <button className='p-3 w-1/3 rounded text-white text-center bg-red-500' style={{}} onClick={() => obrisiZadatak(activeTask)}>OBRISI ZADATAK</button>
                        </div>

                    </Modal>
                ) : null
            }
            <h1 className='pb-3 mb-3 border-b font-medium'>Vozila u pripremi ({prijemi.length} Vozila)</h1>
            <table className='w-full border border-black'>
                <thead className='w-full'>
                    <tr className='w-full border-b border-black'>
                        <th className='p-3 text-xs bg-gray-200 border-black font-normal text-left border-r' style={{ width: '6%' }}>Redni broj</th>
                        <th className='p-3 text-xs bg-gray-200 border-black font-normal text-left border-r' style={{ width: '5%' }}>Datum</th>
                        <th className='p-3 text-xs bg-gray-200 border-black font-normal text-left border-r' style={{ width: '15%' }}>Vozilo</th>
                        <th className='p-3 text-xs bg-gray-200 border-black font-normal text-left border-r' style={{ width: '10%' }}>Šasija</th>
                        <th className='p-3 text-xs bg-gray-200 border-black font-normal text-left border-r' style={{ width: '50%' }}>Rad</th>
                        <th className='p-3 text-xs bg-gray-200 border-black font-normal text-left' style={{ width: '15%' }}>Zadatak</th>
                    </tr>
                </thead>
                <tbody className='w-full'>
                    {
                        prijemi.filter((pr) => new Date(pr.createdAt).toISOString() > new Date("2024-05-13").toISOString()).map((prijem, i) => {
                            return (
                                <>
                                    <tr colSpan={7} className='p-3 border-b bg-blue-500 text-white' style={{ minWidth: '100%' }}>
                                        <td className='p-3 text-xs font-medium' colSpan={7}>{i + 1} {prijem.vozilo} {prijem.sasija}</td>
                                    </tr>
                                    {
                                        prijem.potrebniRadovi
                                            .sort((a, b) => {
                                                const pripremaA = `${a} - ${prijem.vozilo} - ${prijem.sasija.slice(-4)}`;
                                                const pripremaB = `${b} - ${prijem.vozilo} - ${prijem.sasija.slice(-4)}`;

                                                // Check if there are finished tasks and if they match the condition
                                                const aFinished = zadaci.some(task => task.title.replaceAll(" ", "") === pripremaA.replaceAll(" ", "") && task.status === "Završeno");
                                                const bFinished = zadaci.some(task => task.title.replaceAll(" ", "") === pripremaB.replaceAll(" ", "") && task.status === "Završeno");

                                                if (aFinished && !bFinished) {
                                                    return 1;
                                                } else if (!aFinished && bFinished) {
                                                    return -1;
                                                } else {
                                                    return 0;
                                                }
                                            })
                                            .map((rad, i) => {
                                                const filteredTasks = zadaci.filter((zadatak) => {
                                                    const priprema = `${rad} - ${prijem.vozilo} - ${prijem.sasija.slice(-4)}`;
                                                    return zadatak.title.replaceAll(" ", "") === priprema.replaceAll(" ", "");
                                                });

                                                const sortedTasks = filteredTasks.sort((a, b) => {
                                                    if (a.status === "Završeno" && b.status !== "Završeno") {
                                                        return 1; // Put finished tasks last
                                                    } else if (a.status !== "Završeno" && b.status === "Završeno") {
                                                        return -1; // Put unfinished tasks first
                                                    } else {
                                                        return 0;
                                                    }
                                                });

                                                let rowClass = '';

                                                if (sortedTasks.length === 0) {
                                                    rowClass = 'bg-red-400 hover:bg-red-300';
                                                } else {
                                                    const isAnyTaskNotFinished = sortedTasks.some(task => task.status !== "Završeno");
                                                    rowClass = isAnyTaskNotFinished ? 'bg-orange-400 hover:bg-orange-300' : 'bg-green-400 hover:bg-green-300';
                                                }

                                                return (
                                                    <tr onClick={() => sortedTasks.length === 0 ? showModal(`${rad} - ${prijem.vozilo} - ${prijem.sasija.slice(-4)}`) : null} key={i} className={`w-full border-b border-black cursor-pointer ${rowClass}`}>
                                                        <td className='px-3 py-1 text-xs font-normal border-black text-left border-r'># {i + 1}</td>
                                                        <td className='px-3 py-1 text-xs font-normal border-black text-left border-r'>{formatDate(new Date(prijem.createdAt))}</td>
                                                        <td className='px-3 py-1 text-xs font-normal border-black text-left border-r'>{prijem.vozilo}</td>
                                                        <td className='px-3 py-1 text-xs font-normal border-black text-left border-r'>{prijem.sasija}</td>
                                                        <td className='px-3 py-1 text-xs font-normal border-black text-left border-r'>{rad}</td>
                                                        <td className='px-3 py-1 text-xs font-normal border-black text-left overflow-hidden flex items-center gap-1 cursor-pointer'>
                                                            {sortedTasks.length === 0 ? 'Kreirajte zadatak' :
                                                                sortedTasks.map((zadatak) => (
                                                                    <button className='flex items-center gap-1' onClick={() => setActiveTask(zadatak._id)}>
                                                                        <Avatar key={zadatak._id} className='bg-red-600 text-white' style={{ minWidth: 20, maxWidth: 20, minHeight: 20, maxHeight: 20, fontSize: 11 }}>DO</Avatar>
                                                                        <h1 className='line-clamp-1' style={{ fontSize: 12 }}>{zadatak.title}</h1>
                                                                    </button>
                                                                ))
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                    }



                                </>
                            )
                        })

                    }


                </tbody>
            </table>
        </div>
    )
}

export default VozilaUpripremi