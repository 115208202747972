import { Progress } from 'antd'
import React from 'react'

import '../styles/components.css'

import { PlusCircle } from 'lucide-react'

function VehcilesStats() {
    return (
        <div className='component-container rounded border' style={{  width: 'calc(100% / 2 - 10px)' }}>
            <div className="tasks-header p-3 border-b flex items-center justify-between pr-1.5">
                <h3>Procesi na vozilima</h3>
                <button className='flex text-black text-xs gap-2 border items-center p-2 rounded-full hover:bg-gray-100 hover:shadow-xl'><PlusCircle size={15} /> Kreiraj proces</button>
            </div>
            <div className="tasks-body overflow-auto p-3 flex flex-col gap-2" style={{ maxHeight: 400 }}>
                <div className="task-container cursor-pointer text-sm flex items-center justify-between pl-3 py-1.5 pr-1.5 bg-gray-100 rounded hover:bg-gray-50 hover:shadow-md">
                    <div className="task-main flex gap-2">
                        <span>1.</span>
                        <h3 className='text-sm font-medium line-clamp-1	'>VW Caddy 75kw 4Motion VIN:1742</h3>
                    </div>
                    <div className="task-second hover:bg-gray-100 p-1.5 rounded flex items-center gap-2" onClick={() => console.log('DATUM I POSJETA')}>
                        <span className='flex items-center gap-2 upprecase text-xs'><Progress type="circle" percent={75} size={18} />5/10</span>
                    </div>
                </div>
                <div className="task-container cursor-pointer text-sm flex items-center justify-between pl-3 py-1.5 pr-1.5 bg-gray-100 rounded hover:bg-gray-50 hover:shadow-md">
                    <div className="task-main flex gap-2">
                        <span>2.</span>
                        <h3 className='text-sm font-medium line-clamp-1	'>Cupra Formentor</h3>
                    </div>
                    <div className="task-second hover:bg-gray-100 p-1.5 rounded flex items-center gap-2" onClick={() => console.log('DATUM I POSJETA')}>
                        <span className='flex items-center gap-2 upprecase text-xs'><Progress type="circle" percent={0} size={18} status="exception" />0/10</span>
                    </div>
                </div>
                <div className="task-container cursor-pointer text-sm flex items-center justify-between pl-3 py-1.5 pr-1.5 bg-gray-100 rounded hover:bg-gray-50 hover:shadow-md">
                    <div className="task-main flex gap-2">
                        <span>3.</span>
                        <h3 className='text-sm font-medium line-clamp-1	'>VW Caddy 75kw 4Motion VIN:1742</h3>
                    </div>
                    <div className="task-second hover:bg-gray-100 p-1.5 rounded flex items-center gap-2" onClick={() => console.log('DATUM I POSJETA')}>
                        <span className='flex items-center gap-2 upprecase text-xs'><Progress type="circle" percent={100} size={18} />10/10</span>
                    </div>
                </div>
                <div className="task-container cursor-pointer text-sm flex items-center justify-between pl-3 py-1.5 pr-1.5 bg-gray-100 rounded hover:bg-gray-50 hover:shadow-md">
                    <div className="task-main flex gap-2">
                        <span>1.</span>
                        <h3 className='text-sm font-medium line-clamp-1	'>Audi Q8 2024 Crni</h3>
                    </div>
                    <div className="task-second hover:bg-gray-100 p-1.5 rounded flex items-center gap-2" onClick={() => console.log('DATUM I POSJETA')}>
                        <span className='flex items-center gap-2 upprecase text-xs'><Progress type="circle" percent={75} size={18} />5/10</span>
                    </div>
                </div>
                <div className="task-container cursor-pointer text-sm flex items-center justify-between pl-3 py-1.5 pr-1.5 bg-gray-100 rounded hover:bg-gray-50 hover:shadow-md">
                    <div className="task-main flex gap-2">
                        <span>2.</span>
                        <h3 className='text-sm font-medium line-clamp-1	'>Cupra Formentor</h3>
                    </div>
                    <div className="task-second hover:bg-gray-100 p-1.5 rounded flex items-center gap-2" onClick={() => console.log('DATUM I POSJETA')}>
                        <span className='flex items-center gap-2 upprecase text-xs'><Progress type="circle" percent={0} size={18} status="exception" />0/10</span>
                    </div>
                </div>
                <div className="task-container cursor-pointer text-sm flex items-center justify-between pl-3 py-1.5 pr-1.5 bg-gray-100 rounded hover:bg-gray-50 hover:shadow-md">
                    <div className="task-main flex gap-2">
                        <span>3.</span>
                        <h3 className='text-sm font-medium line-clamp-1	'>VW Caddy 75kw 4Motion VIN:1742</h3>
                    </div>
                    <div className="task-second hover:bg-gray-100 p-1.5 rounded flex items-center gap-2" onClick={() => console.log('DATUM I POSJETA')}>
                        <span className='flex items-center gap-2 upprecase text-xs'><Progress type="circle" percent={100} size={18} />10/10</span>
                    </div>
                </div>
                <div className="task-container cursor-pointer text-sm flex items-center justify-between pl-3 py-1.5 pr-1.5 bg-gray-100 rounded hover:bg-gray-50 hover:shadow-md">
                    <div className="task-main flex gap-2">
                        <span>1.</span>
                        <h3 className='text-sm font-medium line-clamp-1	'>Audi Q8 2024 Crni</h3>
                    </div>
                    <div className="task-second hover:bg-gray-100 p-1.5 rounded flex items-center gap-2" onClick={() => console.log('DATUM I POSJETA')}>
                        <span className='flex items-center gap-2 upprecase text-xs'><Progress type="circle" percent={75} size={18} />5/10</span>
                    </div>
                </div>
                <div className="task-container cursor-pointer text-sm flex items-center justify-between pl-3 py-1.5 pr-1.5 bg-gray-100 rounded hover:bg-gray-50 hover:shadow-md">
                    <div className="task-main flex gap-2">
                        <span>2.</span>
                        <h3 className='text-sm font-medium line-clamp-1	'>Cupra Formentor</h3>
                    </div>
                    <div className="task-second hover:bg-gray-100 p-1.5 rounded flex items-center gap-2" onClick={() => console.log('DATUM I POSJETA')}>
                        <span className='flex items-center gap-2 upprecase text-xs'><Progress type="circle" percent={0} size={18} status="exception" />0/10</span>
                    </div>
                </div>
                <div className="task-container cursor-pointer text-sm flex items-center justify-between pl-3 py-1.5 pr-1.5 bg-gray-100 rounded hover:bg-gray-50 hover:shadow-md">
                    <div className="task-main flex gap-2">
                        <span>3.</span>
                        <h3 className='text-sm font-medium line-clamp-1	'>VW Caddy 75kw 4Motion VIN:1742</h3>
                    </div>
                    <div className="task-second hover:bg-gray-100 p-1.5 rounded flex items-center gap-2" onClick={() => console.log('DATUM I POSJETA')}>
                        <span className='flex items-center gap-2 upprecase text-xs'><Progress type="circle" percent={100} size={18} />10/10</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VehcilesStats