import axios from 'axios';
import React, { useRef, useState } from 'react'
import { read, utils, writeFile } from 'xlsx';
import { useAppData } from '../../context/ApplicationProvider';
import { ChevronDown, ChevronUp, X } from 'lucide-react';

function PrijemiNaZalihuTockovi() {

  const { pneumatici, activeWheel, setActiveWheel } = useAppData();

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('');
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  const [ selectedWheel, setSelectedWheel] = useState(null);

  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleSort = (field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(field);
      setSortOrder('asc');
    }
  };

  const filteredData = pneumatici.filter(item => {
    return Object.values(item).some(value =>
      typeof value === 'string' && value.toLowerCase().includes(filter.toLowerCase())
    );
  });

  const sortedData = sortBy ? [...filteredData].sort((a, b) => {
    if (sortBy === 'lotBroj') {
      return sortOrder === 'asc' ? a[sortBy] - b[sortBy] : b[sortBy] - a[sortBy];
    } else if (sortBy === 'velicina') {
      const aValue = (a[sortBy] || '').toString().trim() || 'zzz'; // Handle empty values
      const bValue = (b[sortBy] || '').toString().trim() || 'zzz'; // Handle empty values
      return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    } else {
      const aValue = (a[sortBy] || '').toString().toLowerCase();
      const bValue = (b[sortBy] || '').toString().toLowerCase();
      return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }
  }) : filteredData;



  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          setData(rows)
        }
      }
      reader.readAsArrayBuffer(file);
    }
  }

  // const handleArraySubmit = async () => {
  //   const postData = data.map((data) => {
  //     return {
  //       lotBroj: data["Lot Broj"],
  //       tip: data["Točkovi komplet/felge/gume"],
  //       brojKomada: data["Broj (količina)"],
  //       brend: data.BREND,
  //       vozilo: data["VOZILO"],
  //       velicina: data["Veličina \""],
  //       rasponSarafa: data["raspon šarafa"],
  //       etJotFelgi: data["ET / JOT Felgi"],
  //       centralnaRupa: data["Centralna rupa"],
  //       markaTipGuma: data["Marka / Tip guma: ljetne/zimske/allseason"],
  //       sezonaGuma: data["Ljetne/Zimske/Allseason"],
  //       dimenzija: data["Dimenzija"],
  //       dot: data["DOT"],
  //       dubinaSare: data["Dubina šare"],
  //       ekseri: data["Ekseri / DA / NE / BILI"],
  //       skladiste: data["Skladisteno"],
  //       napomena: data["Napomena"],
  //       dostupnost: data["Dostupnost"]
  //     }
  //   })

  //   try {
  //     const response = await axios.post('http://localhost:3001/administration/pneumatici', postData);
  //     console.log(response.data);
  //   } catch (error) {
  //     console.error('Greška prilikom slanja zahtjeva:', error);
  //   }
  // };

  return (
    <div className='pb-3 px-3 relative' onClick={() => setSelectedWheel(null)}>
      <input ref={fileInputRef} type="file" className='' hidden required onChange={handleImport} />
      <div className='fixed bottom-5 right-5 flex flex-col items-end gap-3' style={{ zIndex: 99999 }}>
        <button className='bg-black text-white py-3 px-6 rounded-full text-sm'>Postavi pneumatik</button>
      </div>
      {
        !pneumatici && data ? (
          <div className="noviPneumatici">
            <h1 className='font-semibold text-xl'>Lista novih pneumatika</h1>
            <div className='overflow-x-auto'>
              <table className='border relative'>
                <thead className='thead-static border bg-white'>
                  <tr className='bg-black text-white border border-white'>
                    <th id='felgeiGumeTH' style={{ minWidth: 200, }} onClick={() => handleSort("Lot Broj")} className='text-sm font-normal text-center p-3 border-r border-r-white cursor-pointer'>LOT broj</th>
                    <th id='felgeiGumeTH' style={{ minWidth: 200, }} className='text-sm font-normal text-center p-3 border-r border-r-white'>Točkovi komplet/gume</th>
                    <th id='felgeiGumeTH' style={{ minWidth: 200, }} className='text-sm font-normal text-center p-3 border-r border-r-white'>Količina</th>
                    <th id='felgeiGumeTH' style={{ minWidth: 200, }} className='text-sm font-normal text-center p-3 border-r border-r-white'>Brend</th>
                    <th id='felgeiGumeTH' style={{ minWidth: 200, }} className='text-sm font-normal text-center p-3 border-r border-r-white'>Vozilo</th>
                    <th id='felgeiGumeTH' style={{ minWidth: 200, }} onClick={() => handleSort(`Veličina \"`)} className='text-sm font-normal text-center p-3 border-r border-r-white cursor-pointer'>Veličina</th>
                    <th id='felgeiGumeTH' style={{ minWidth: 200, }} className='text-sm font-normal text-center p-3 border-r border-r-white'>Raspon šarafa</th>
                    <th id='felgeiGumeTH' style={{ minWidth: 200, }} className='text-sm font-normal text-center p-3 border-r border-r-white'>ET / JOT Felgi</th>
                    <th id='felgeiGumeTH' style={{ minWidth: 200, }} className='text-sm font-normal text-center p-3 border-r border-r-white'>Centralna rupa</th>
                    <th id='felgeiGumeTH' style={{ minWidth: 200, }} className='text-sm font-normal text-center p-3 border-r border-r-white'><span>Marka / tip guma:</span><span>ljetne/zimske/allseason</span></th>
                    <th id='felgeiGumeTH' style={{ minWidth: 200, }} className='text-sm font-normal text-center p-3 border-r border-r-white'>Ljetne/Zimske/Allseason</th>
                    <th id='felgeiGumeTH' style={{ minWidth: 200, }} className='text-sm font-normal text-center p-3 border-r border-r-white'>Dimenzije</th>
                    <th id='felgeiGumeTH' style={{ minWidth: 200, }} className='text-sm font-normal text-center p-3 border-r border-r-white'>DOT</th>
                    <th id='felgeiGumeTH' style={{ minWidth: 200, }} className='text-sm font-normal text-center p-3 border-r border-r-white'>Dubina šare</th>
                    <th id='felgeiGumeTH' style={{ minWidth: 200, }} className='text-sm font-normal text-center p-3 border-r border-r-white'>Ekseri (DA, NE, BILI)</th>
                    <th id='felgeiGumeTH' style={{ minWidth: 200, }} className='text-sm font-normal text-center p-3 border-r border-r-white'>Skladišteno</th>
                    <th id='felgeiGumeTH' style={{ minWidth: 200, }} className='text-sm font-normal text-center p-3 border-r border-white'>Napomena</th>
                    <th id='felgeiGumeTH' style={{ minWidth: 200, }} className='text-sm font-normal text-center p-3'>Dostupnost</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => (
                    <tr className='border-b' key={i}>
                      <td className='p-3 border-r text-sm' style={{ minWidth: 200, }}>{item["Lot Broj"]}</td>
                      <td className='p-3 border-r text-sm' style={{ minWidth: 200, }}>{item["Točkovi komplet/felge/gume"]}</td>
                      <td className='p-3 border-r text-sm' style={{ minWidth: 200, }}>{item["Broj (količina)"]}</td>
                      <td className='p-3 border-r text-sm' style={{ minWidth: 200, }}>{item.BREND}</td>
                      <td className='p-3 border-r text-sm' style={{ minWidth: 200, }}>{item["VOZILO"]}</td>
                      <td className='p-3 border-r text-sm' style={{ minWidth: 200, }}>{item["Veličina \""]}</td>
                      <td className='p-3 border-r text-sm' style={{ minWidth: 200, }}>{item["raspon šarafa"]}</td>
                      <td className='p-3 border-r text-sm' style={{ minWidth: 200, }}>{item["ET / JOT Felgi"]}</td>
                      <td className='p-3 border-r text-sm' style={{ minWidth: 200, }}>{item["Centralna rupa"]}</td>
                      <td className='p-3 border-r text-sm' style={{ minWidth: 200, }}>{item["Marka / Tip guma: ljetne/zimske/allseason"]}</td>
                      <td className='p-3 border-r text-sm' style={{ minWidth: 200, }}>{item["Ljetne/Zimske/Allseason"]}</td>
                      <td className='p-3 border-r text-sm' style={{ minWidth: 200, }}>{item["Dimenzija"]}</td>
                      <td className='p-3 border-r text-sm' style={{ minWidth: 200, }}>{item["DOT"]}</td>
                      <td className='p-3 border-r text-sm' style={{ minWidth: 200, }}>{item["Dubina šare"]}</td>
                      <td className='p-3 border-r text-sm' style={{ minWidth: 200, }}>{item["Ekseri / DA / NE / BILI"]}</td>
                      <td className='p-3 border-r text-sm' style={{ minWidth: 200, }}>{item["Skladisteno"]}</td>
                      <td className='p-3 border-r text-sm' style={{ minWidth: 200, }}>{item["Napomena"]}</td>
                      <td className='p-3 border-r text-sm' style={{ minWidth: 200, }}>{item["Dostupnost"]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="noviPneumatici">
            <div className='flex items-center justify-between py-4'>
              <h1 className='font-semibold text-xl'>Lista pneumatika</h1>
              <div>
                <input
                  className='p-3 bg-gray-100 text-sm'
                  style={{ width: 400 }}
                  type="text"
                  value={filter}
                  onChange={handleFilterChange}
                  placeholder="Pretrazite felge i gume pomoću riječi..."
                />
              </div>
            </div>
              <div className='tableFixHead'>
                <table className='border relative pneumatici-tabela' style={{ zIndex: 999 }} onClick={(e) => e.stopPropagation()}>
                  <thead className='thead-static border bg-white'>
                    <tr className='bg-black text-white border border-white'>
                      <th id='felgeiGumeTH' style={{ minWidth: 50, fontSize: 12 }} onClick={() => handleSort("lotBroj")} className='font-normal text-center p-3 border-r border-r-white cursor-pointer'>
                        <div style={{ display: 'flex', alignItems: 'center' }} className='justify-between'>
                          <span>LOT broj</span>
                          {sortBy === 'lotBroj' ? (
                            sortOrder === 'asc' ? <ChevronDown /> : <ChevronUp />
                          ) : (
                            <ChevronDown />
                          )}
                        </div>
                      </th>
                      <th id='felgeiGumeTH' style={{ minWidth: 110, fontSize: 12 }} className=' font-normal text-center p-3 border-r border-r-white'>Točkovi komplet/gume</th>
                      <th id='felgeiGumeTH' style={{ minWidth: 70, fontSize: 12 }} className=' font-normal text-center p-3 border-r border-r-white'>Količina</th>
                      <th id='felgeiGumeTH' style={{ minWidth: 80, fontSize: 12 }} className=' font-normal text-center p-3 border-r border-r-white'>Brend</th>
                      <th id='felgeiGumeTH' style={{ minWidth: 130, fontSize: 12 }} className=' font-normal text-center p-3 border-r border-r-white'>Vozilo</th>
                      <th id='felgeiGumeTH' style={{ minWidth: 90, fontSize: 12 }} onClick={() => handleSort("velicina")} className='font-normal text-center p-3 border-r border-r-white cursor-pointer'>
                        <div style={{ display: 'flex', alignItems: 'center' }} className='justify-between'>
                          <span>Veličina</span>
                          {sortBy === 'velicina' ? (
                            sortOrder === 'asc' ? <ChevronDown /> : <ChevronUp />
                          ) : (
                            <ChevronDown />
                          )}
                        </div>
                      </th>
                      <th id='felgeiGumeTH' style={{ minWidth: 60, fontSize: 12 }} className=' font-normal text-center p-3 border-r border-r-white'>Raspon šarafa</th>
                      <th id='felgeiGumeTH' style={{ minWidth: 80, fontSize: 12 }} className=' font-normal text-center p-3 border-r border-r-white'>ET / JOT <br/> Felgi</th>
                      <th id='felgeiGumeTH' style={{ minWidth: 75, fontSize: 12 }} className=' font-normal text-center p-3 border-r border-r-white'>Centralna rupa</th>
                      <th id='felgeiGumeTH' style={{ minWidth: 170, fontSize: 12 }} className=' font-normal text-center p-3 border-r border-r-white'><span>Marka / tip guma: <br/></span><span>ljetne/zimske/allseason</span></th>
                      <th id='felgeiGumeTH' style={{ minWidth: 100, fontSize: 12 }} className=' font-normal text-center p-3 border-r border-r-white'>Ljetne/<br/>Zimske/<br/>Allseason</th>
                      <th id='felgeiGumeTH' style={{ minWidth: 80, fontSize: 12 }} className=' font-normal text-center p-3 border-r border-r-white'>Dimenzije</th>
                      <th id='felgeiGumeTH' style={{ minWidth: 70, fontSize: 12 }} className=' font-normal text-center p-3 border-r border-r-white'>DOT</th>
                      <th id='felgeiGumeTH' style={{ minWidth: 130, fontSize: 12 }} className=' font-normal text-center p-3 border-r border-r-white'>Dubina šare</th>
                      <th id='felgeiGumeTH' style={{ minWidth: 90, fontSize: 12 }} className=' font-normal text-center p-3 border-r border-r-white'>Ekseri <br/>(DA, NE, BILI)</th>
                      <th id='felgeiGumeTH' style={{ minWidth: 100, fontSize: 12 }} className=' font-normal text-center p-3 border-r border-r-white'>Skladišteno</th>
                      <th id='felgeiGumeTH' style={{ minWidth: 160, fontSize: 12 }} className=' font-normal text-center p-3 border-r border-white'>Napomena</th>
                      <th id='felgeiGumeTH' style={{ minWidth: 130, fontSize: 12 }} className=' font-normal text-center p-3'>Dostupnost</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedData.map((item, i) => (
                      <tr className={`border-b hover:bg-gray-100 ${selectedWheel === item._id ? 'text-red-600 bg-red-100 font-semibold hover:bg-red-100' : null}`} onClick={() => setSelectedWheel(item._id)} onDoubleClick={() => setActiveWheel(item._id)} key={i}>
                        <td className='p-3 border-r ' style={{ minWidth: 50, fontSize: 12 }}>{item.lotBroj || <X size={18} stroke='black' />}</td>
                        <td className='p-3 border-r ' style={{ minWidth: 110, fontSize: 12 }}>{item.tip || <X size={18} stroke='black' />}</td>
                        <td className='p-3 border-r ' style={{ minWidth: 70, fontSize: 12 }}>{item.brojKomada || <X size={18} stroke='black' />}</td>
                        <td className='p-3 border-r ' style={{ minWidth: 80, fontSize: 12 }}>{item.brend || <X size={18} stroke='black' />}</td>
                        <td className='p-3 border-r ' style={{ minWidth: 130, fontSize: 12 }}>{item.vozilo || <X size={18} stroke='black' />}</td>
                        <td className='p-3 border-r ' style={{ minWidth: 90, fontSize: 12 }}>{item.velicina || <X size={18} stroke='black' />}</td>
                        <td className='p-3 border-r ' style={{ minWidth: 60, fontSize: 12 }}>{item.rasponSarafa || <X size={18} stroke='black' />}</td>
                        <td className='p-3 border-r break-words ' style={{ minWidth: 80, fontSize: 12 }}>{item.etJotFelgi || <X size={18} stroke='black' />}</td>
                        <td className='p-3 border-r ' style={{ minWidth: 75, fontSize: 12 }}>{item.centralnaRupa || <X size={18} stroke='black' />}</td>
                        <td className='p-3 border-r ' style={{ minWidth: 170, fontSize: 12 }}>{item.markaTipGuma || <X size={18} stroke='black' />}</td>
                        <td className='p-3 border-r ' style={{ minWidth: 100, fontSize: 12 }}>{item.sezonaGuma || <X size={18} stroke='black' />}</td>
                        <td className='p-3 border-r ' style={{ minWidth: 80, fontSize: 12 }}>{item.dimenzija || <X size={18} stroke='black' />}</td>
                        <td className='p-3 border-r ' style={{ minWidth: 70, fontSize: 12 }}>{item.dot || <X size={18} stroke='black' />}</td>
                        <td className='p-3 border-r ' style={{ minWidth: 130, fontSize: 12 }}>{item.dubinaSare || <X size={18} stroke='black' />}</td>
                        <td className='p-3 border-r ' style={{ minWidth: 90, fontSize: 12 }}>{item.ekseri || <X size={18} stroke='black' />}</td>
                        <td className='p-3 border-r ' style={{ minWidth: 100, fontSize: 12 }}>{item.skladiste || <X size={18} stroke='black' />}</td>
                        <td className='p-3 border-r ' style={{ minWidth: 160, fontSize: 12 }}>{item.napomena || <X size={18} stroke='black' />}</td>
                        <td className='p-3 border-r ' style={{ minWidth: 130, fontSize: 12 }}>{item.dostupnost || <X size={18} stroke='black' />}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                
              </div>
          </div>
        )
      }
    </div>
  )
}

export default PrijemiNaZalihuTockovi