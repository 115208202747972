import { Input, InputNumber, Modal, Select, Slider, Space, Table, message, notification, Upload, Carousel, Image } from 'antd';
import React, { useEffect, useState } from 'react'
import Lottie from "lottie-react";
import erroranimation from '../../assets/erroranim.json'
import loadinganimation from '../../assets/loadinganim.json'
import confirmanimation from '../../assets/confirmanimation.json'
import { useAppData } from '../../context/ApplicationProvider';
import Column from 'antd/es/table/Column';
import io from 'socket.io-client';
import axios from 'axios';
import { InboxIcon } from 'lucide-react';
import {
    DownloadOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons';


const socket = io.connect("https://app.arenabackend.org")
const { Dragger } = Upload;

function Admin() {

    const audioSound = require('../../assets/sound/notification.mp3');
    const { users, currentUser, sections, zadaci, fetchZadaci, vozila, fetchVehicles } = useAppData();
    const [messageApi, contextHolder] = message.useMessage();
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [voziloSaProcesimaData, setVoziloSaProcesimaData] = useState({
        title: '',
        vin: '',
        spremnoZaProdaju: false,
        // other properties for VoziloSaProcesima
    });
    const [procesData, setProcesData] = useState({
        title: '',
        status: false,
        zavrsioGa: '',
        importantLevel: 0,
        // other properties for Proces
    });
    const [data, setData] = useState([])
    const [showAdminActions, setShowAdminActions] = useState(null)
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [vin, setVin] = useState('');
    const [images, setImages] = useState([]);
    const [postCarModal, setPostCarModal] = useState(null)
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [voziloZadaci, setVoziloZadaci] = useState("")

    // kreiraj zadatak za vozilo
    const [izabranoVozilo, setIzabranoVozilo] = useState("")
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        sektor: "",
        vozilo: ""
    })
    const [user, setUser] = useState("")

    const customRequest = ({ onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const beforeUpload = file => {
        // Add the uploaded file to the images state
        setImages(prevImages => [...prevImages, file]);

        // Prevent the default behavior of uploading to the server
        return false;
    };

    const handleRemove = file => {
        // Remove the file from the images state when deleted
        setImages(prevImages => prevImages.filter(img => img !== file));
    };

    const props = {
        multiple: true,
        beforeUpload,
        customRequest,
        onRemove: handleRemove,
        fileList: images,
    };



    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        fetchVehicles()
        socket.on('postaviVoziloNaPripremu', (data) => {
            notification.open({
                message: 'ARENA NOTIFIKACIJA',
                description: data.message
            });
            const audio = new Audio(audioSound)
            audio.play()

        });


        return () => {
            socket.disconnect();
        };
    }, [])


    const handleSubmit = async (e) => {
        e.preventDefault();


        try {
            const response = await fetch('https://app.arenabackend.org/vozilo-u-pripremi/postavi-vozilo', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ voziloSaProcesima: voziloSaProcesimaData, proces: procesData }),
            });


            if (response.ok) {
                messageApi.open({
                    type: 'success',
                    content: 'Uspiješno ste postavili vozilo u pripremi',
                });

                fetchVehicles()
            } else {

            }
        } catch (error) {
            messageApi.open({
                type: 'error',
                content: 'Greska prilikom postavljanja vozila u pripremu',
            });
        }
    };

    // const handleDelete = async (e) => {
    //     try {
    //         const response = await axios.delete(`https://app.arenabackend.org/vozilo-u-pripremi/vozilo-upp/${e}`).then((res) => console.log(res)).catch((e) => console.log(e));

    //         if (!response) {
    //             messageApi.open({
    //                 type: 'error',
    //                 content: 'Greška prilikom brisanja vozila',
    //             });
    //         }

    //         messageApi.open({
    //             type: 'success',
    //             content: 'Uspiješno ste obrisali vozilo',
    //         });
    //         fetchVozilaUPripremi()
    //     } catch (error) {
    //         messageApi.open({
    //             type: 'error',
    //             content: 'Greska prilikom brisanja vozila',
    //         });
    //     }
    // };


    const onDownload = (src) => {
        console.log(src)
    };

    const submit = async () => {
        try {
            const response = await fetch(`https://app.arenabackend.org/zadaci/postavi-zadatak-za-vozilo/${user}/${formData.sektor}/${izabranoVozilo}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                // Handle non-successful response
                console.error(`Error: ${response}`);
                alert("Neuspješno kreiranje zadatka");
                return;
            }

            socket.emit('kreiranZadatak', user);

            const data = await response.json();

            messageApi.open({
                type: 'success',
                content: 'Uspiješno ste kreirali zadatak',
            });
            fetchVehicles()
            fetchZadaci()
        } catch (error) {
            console.error('Error:', error);
            alert("Greška prilikom slanja zahtjeva");
        }
    };


    const handlePostVehicle = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        for (let i = 0; i < images.length; i++) {
            formData.append('images', images[i]);
        }
        formData.append('name', name);
        formData.append('price', price);
        formData.append('vin', vin);

        try {
            const response = await axios.post('https://app.arenabackend.org/administration/post-vehicle', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                setSuccess(true);
                // Reset success after 2 seconds
                setTimeout(() => {
                    setSuccess(false);
                }, 2000);

                messageApi.open({
                    type: 'success',
                    content: 'Uspiješno ste dodali vozilo u bazu',
                });
                // Handle success (e.g., show a success message)
            } else {
                // Handle errors
                setError('GRESKA' || 'An error occurred');
                setTimeout(() => {
                    setError(false);
                }, 2000);
                console.error('Error saving vehicle:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error.message);
            // Handle errors
        } finally {
            setLoading(false);

        }
    };

    return (
        <div className='px-3 pt-2 pb-3'>
            {contextHolder}
            <div className={`admin-actions absolute bottom-3 right-3 flex flex-col items-end`}>
                <div style={{ width: !showAdminActions ? 0 : null, height: !showAdminActions ? 0 : null, overflow: 'hidden' }} className='aactions flex flex-col items-end justify-end gap-3 mb-3'>
                    <button className='p-3 rounded-full text-white text-xs uppercase' style={{ backgroundColor: '#C93030', minWidth: 150 }}>Kreiraj korisnika</button>
                    <button onClick={() => setPostCarModal(true)} className='p-3 rounded-full text-white text-xs uppercase' style={{ backgroundColor: '#C93030', minWidth: 130 }}>Kreiraj vozilo</button>
                </div>
                <button onClick={() => setShowAdminActions(!showAdminActions)} style={{ width: 60, height: 60, backgroundImage: `url("https://res.cloudinary.com/dxo3z5off/image/upload/f_auto,q_auto/v1/topc/xjtz6alde1eibswovm7z")`, backgroundSize: 'cover', backgroundPosition: 'center' }} className={`bg-gray-200 rounded-full ${showAdminActions ? 'shadow-xl shadow-gray-400' : null}`}></button>
            </div>
            <Modal title="Kreiraj vozilo u pripremi" open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel} cancelText='Otkaži' okText='Potvrdi' okType='default' >
                <Input onChange={(e) => setVoziloSaProcesimaData({ ...voziloSaProcesimaData, title: e.target.value })} placeholder='Unesite naziv vozila' className='p-3' />
                <InputNumber maxLength={4} min={0} onChange={(value) => setVoziloSaProcesimaData({ ...voziloSaProcesimaData, vin: value })} placeholder='Unesite VIN' className='p-2 mt-3 w-full text-xs' />
                <div className='w-full bg-black opacity-35 my-3' style={{ height: 1 }}></div>
                <Input onChange={(e) => setProcesData({ ...voziloSaProcesimaData, title: e.target.value })} placeholder='Unesite zadatak' className='p-3' />
                <Select defaultValue={currentUser._id.trim()} options={users.map((user) => {
                    return {
                        label: user._id.trim() === currentUser._id.trim() ? `Vi (${user.name})` : user.name,
                        value: user._id.trim()
                    }
                })} onChange={(value) => setProcesData({ ...voziloSaProcesimaData, zavrsioGa: value })} placeholder='Izaberite korisnika' className='w-full mt-3' style={{ height: 45 }} />
                <div className="w-full my-3">
                    <p className='text-xs uppercase font-bold'>Bitnost</p>
                    <Slider
                        min={1}
                        max={3}
                        className=''
                        onChange={(newValue) => setProcesData({ ...voziloSaProcesimaData, importantLevel: newValue })}
                        defaultValue={typeof procesData.importantLevel === 'number' ? procesData.importantLevel : 0}
                    />
                </div>
            </Modal>
            <Modal open={postCarModal} onCancel={() => setPostCarModal(null)} onOk={handlePostVehicle} okText='Potvrdi' okType='default'>
                <h1 className='text-xl font-bold mb-4'>Postavi vozilo</h1>
                <Dragger {...props}>
                    <p className="ant-upload-drag-icon flex items-center justify-center">
                        <InboxIcon size={30} />
                    </p>
                    <p className="ant-upload-text">Pritisnite ili prevucite fajlove za upload</p>
                    <p className="ant-upload-hint">
                        Podrška za jedno ili grupno otpremanje. Strogo je zabranjeno učitavanje podataka kompanije ili drugog
                    </p>
                </Dragger>
                <Input placeholder='Unesite naziv vozila' className='mt-2 w-full' onChange={(e) => setName(e.target.value)} />
                <Input placeholder='Unesite VIN vozila' maxLength={17} minLength={17} className='mt-2 w-full' onChange={(e) => setVin(e.target.value)} />
                <InputNumber min={0} defaultValue={0} className='mt-2 w-full' onChange={(value) => setPrice(value)} />
            </Modal>
            <Modal title="Kreiraj zadatak" open={izabranoVozilo} onOk={submit} onCancel={() => setIzabranoVozilo("")} cancelText='Otkazi' okText='Potvrdi' okType='default' >
                <Input onChange={(e) => setFormData((prev) => ({ ...prev, title: e.target.value }))} placeholder='Unesite zadatak' className='p-2' />
                <Input onChange={(e) => setFormData((prev) => ({ ...prev, description: e.target.value }))} placeholder='Unesite opis zadatka' className='p-2 mt-3' />
                <Select className='w-full mt-3' style={{ height: 40 }} placeholder="Izaberite sektor" onChange={(e) => setFormData((prev) => ({ ...prev, sektor: e }))} options={sections.map((sector) => {
                    return {
                        label: sector.title,
                        value: sector._id
                    }
                })} />
                <Select className='w-full mt-3' style={{ height: 40 }} placeholder="Izaberite korisnike" onChange={(e) => setUser(e)} options={users.map((user) => {
                    return {
                        label: user.name,
                        value: user._id
                    }
                })} />
                <Select className='w-full mt-3' defaultValue={izabranoVozilo} style={{ height: 40 }} placeholder="Izaberite vozilo" onChange={(e) => setIzabranoVozilo(e)} options={vozila.map((vozilo) => {
                    return {
                        label: vozilo.name,
                        value: vozilo._id
                    }
                })} />
            </Modal>
            <Modal open={voziloZadaci} onCancel={() => setVoziloZadaci("")} cancelText="Otkaži" okText="Zatvori" onOk={() => setVoziloZadaci("")} okType='default'>
                <h1 className='text-xl mb-4'>Lista zadataka za: <strong>{vozila.filter((vozilo) => vozilo._id === voziloZadaci).map((vozilo) => vozilo.name)}</strong></h1>
                {
                    zadaci.filter((zadatak) => zadatak.vozilo === voziloZadaci).length >= 1 ?
                        zadaci.filter((zadatak) => zadatak.vozilo === voziloZadaci).map((zadatak) => {
                            return (
                                <li className='list-decimal mb-2 flex items-end justify-between bg-gray-100 p-2 rounded'>
                                    <span className='w-1/2'>{zadatak.title}</span>|
                                    <span className='w-1/2 text-right'>{users.filter((user) => user._id === zadatak.user).map((user) => user._id === currentUser._id ? 'Vaš zadatak' : user.name)}</span>
                                </li>
                            )
                        }) : <p>NEMA ZADATAKA</p>
                }
            </Modal>
            {
                loading === true || error === true || success === true ? (
                    <div className='absolute top-0 left-0 flex items-center justify-center' style={{ width: '100vw', height: '100vh', backgroundColor: 'rgba(0,0,0,.5)' }}>
                        <div className='w-2/5 h-2/5 bg-white rounded-xl flex items-center justify-center' style={{ minWidth: 300, minHeight: 300, maxWidth: 600, maxHeight: 500 }}>
                            {
                                loading ? <Lottie className='w-1/5' style={{ minWidth: 150 }} animationData={loadinganimation} loop={true} /> :
                                    error ? <Lottie className='w-1/5' style={{ minWidth: 150 }} animationData={erroranimation} loop={false} /> :
                                        <Lottie className='w-1/5' style={{ minWidth: 150 }} animationData={confirmanimation} loop={false} />
                            }
                        </div>
                    </div>
                ) : null
            }
            <div className="vehicles w-full">
                <h1 className='text-xl font-bold mb-5'>Lista vozila: </h1>
                <div className='w-full flex items-start flex-wrap' style={{ gap: 10 }}>
                    {
                        vozila.map((vozilo) => {
                            return (
                                <div className='bg-gray-50 rounded-lg' style={{ width: 'calc(100% / 5 - 10px)', overflow: 'hidden', minWidth: 300 }}>
                                    <Carousel autoplay className='overflow-hidden flex items-center justify-center' style={{ height: 200 }}>
                                        {
                                            vozilo.images.length > 0 ?
                                                vozilo.images.map((image, i) => {
                                                    return (
                                                        <div style={{ width: '100%', height: 200, overflow: 'hidden' }} className='flex items-center justify-center bg-gray-100' key={i}>
                                                            <Image
                                                                placeholder="Pogledaj"
                                                                className='flex items-center justify-center'
                                                                style={{ width: '100%' }}
                                                                src={image.url ? image.url : 'https://i0.wp.com/ourscene.org/wp-content/uploads/2022/01/placeholder-2.png?fit=1200%2C800&ssl=1'}
                                                                preview={{
                                                                    toolbarRender: (
                                                                        _,
                                                                        {
                                                                            transform: { scale },
                                                                            actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                                                                        },
                                                                    ) => (
                                                                        <Space size={12} className="toolbar-wrapper">
                                                                            <DownloadOutlined onClick={onDownload(image.url)} />
                                                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                            <SwapOutlined onClick={onFlipX} />
                                                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                                                            <RotateRightOutlined onClick={onRotateRight} />
                                                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                        </Space>
                                                                    ),
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                }) : <img style={{ width: '100%', height: 200 }} src="https://i0.wp.com/ourscene.org/wp-content/uploads/2022/01/placeholder-2.png?fit=1200%2C800&ssl=1" alt="" />
                                        }
                                    </Carousel>
                                    <div className='p-2'>
                                        <h1 className='text-sm text-black'>Vozilo: <strong>{vozilo.name}</strong></h1>
                                        <div className='w-full bg-gray-200 my-2' style={{ height: 1 }}></div>
                                        <h1 className='text-xs text-black'>VIN: <strong>{vozilo.vin}</strong></h1>
                                        <div className='w-full bg-gray-200 my-2' style={{ height: 1 }}></div>
                                        <h1 className='text-xs text-black'>Cijena: <strong>{vozilo.price.toLocaleString(void 0, { maximumFragmentDigits: 2 }).replace(",", ".")}KM</strong></h1>
                                        <div className='w-full bg-gray-200 my-2' style={{ height: 1 }}></div>
                                        <div onClick={() => setVoziloZadaci(vozilo._id)} className={`text-xs ${vozilo.tasks.length >= 1 ? 'text-blue-400 cursor-pointer' : 'cursor-default text-gray-400'}`}>{vozilo.tasks.length ? (<span>Zadaci: <strong>{vozilo.tasks.length}</strong> </span>) : 'Nema zadataka'}</div>
                                        <div className='w-full bg-gray-200 my-2' style={{ height: 1 }}></div>
                                        <div className="buttons flex items-center gap-3">
                                            <button onClick={() => setIzabranoVozilo(vozilo._id)} className='bg-blue-400 hover:bg-blue-600 text-white w-1/2 text-xs uppercase p-3 rounded'>Kreiraj zadatak</button>
                                            <button className='bg-gray-200 hover:bg-gray-300 text-black w-1/2 text-xs uppercase p-3 rounded'>Pogledaj</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            {/* <Table dataSource={data}>
                <Column className='w-1/3' title="Vozilo" dataIndex="title" key="title" />
                <Column className='w-1/3' title="VIN" dataIndex="vin" key="vin" />
                <Column className='w-1/3' title="Action" key="action" render={(_, record, i) => (
                    <Space size="middle">
                        <button key={1} className='text-blue-400 hover:text-blue-600 text-xs uppercase'>Uredi <strong>{record.title}</strong></button>
                        <button key={2} className='text-red-400 hover:text-red-600 text-xs uppercase' onClick={() => handleDelete(record._id)}>Izbriši</button>
                    </Space>
                )}
                />
            </Table> */}
        </div>
    )
}

export default Admin