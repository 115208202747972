import { Ban } from "lucide-react";

const TaskTable = ({ zadaci, selectedUser }) => {
    const renderTableRows = () => {
        return zadaci
            .filter((zadatak) => zadatak.user === selectedUser)
            .sort((a, b) => {
                // Sort by status "Preuzeto" first
                if (a.status === "Preuzeto" && b.status !== "Preuzeto") {
                    return -1;
                } else if (a.status !== "Preuzeto" && b.status === "Preuzeto") {
                    return 1;
                } else {
                    // If status is the same or both are "Preuzeto", maintain the original order
                    return 0;
                }
            })
            .map((zadatak, index) => {
                const startDate = zadatak.createdAt ? new Date(zadatak.createdAt) : null;
                const endDate = zadatak.endedAt ? new Date(zadatak.endedAt) : null;
                const duration = startDate && endDate ? endDate.getTime() - startDate.getTime() : null;

                function formatDate(date) {
                    if (!date) return "";
                    // Dohvati dan, mjesec i godinu
                    const day = date.getDate();
                    const month = date.getMonth() + 1; // Mjeseci počinju od 0, pa se dodaje 1
                    const year = date.getFullYear();

                    // Dohvati sate i minute
                    const hours = date.getHours();
                    const minutes = date.getMinutes();

                    // Formatiraj datum
                    return `${day}.${month}.${year} u ${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
                }

                const startTime = startDate ? formatDate(startDate) : <Ban size={20} stroke="#d22829" />;
                const endTime = endDate ? formatDate(endDate) : <Ban size={20} stroke="#d22829" />;
                const formattedDuration = duration ? formatDuration(duration) : <Ban size={20} stroke="#d22829" />;

                return (
                    <tr className="border-b hover:bg-gray-100 hover:bg-gray-100 transition ease-linear duration-300" key={index}>
                        <td style={{ width: 'calc(30%)' }} className="text-sm text-left p-3 font-normal border-r">{zadatak.title}</td>
                        <td style={{ width: 'calc(20%)' }} className="text-sm text-left p-3 font-normal border-r">{startTime}</td>
                        <td style={{ width: 'calc(20%)' }} className="text-sm text-left p-3 font-normal border-r">{endTime}</td>
                        <td style={{ width: 'calc(20%)' }} className="text-sm text-left p-3 font-normal border-r">{formattedDuration}</td>
                        <td style={{ width: 'calc(10%)' }} className="text-sm text-left p-3 font-normal">{zadatak.status}</td>
                    </tr>
                );
            });
    };

    const formatDuration = (duration) => {
        const days = Math.floor(duration / (1000 * 60 * 60 * 24));
        const hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));

        return `${days} dana ${hours} sati ${minutes} minuta`;
    };

    return (
        <div className="w-full overflow-hidden mt-3">
            <table className="w-full border-t border-x">
                <thead className="w-full">
                    <tr className="w-full bg-black text-white">
                        <th style={{ width: 'calc(30%)' }} className="text-sm text-left p-3 font-normal border-r border-r-white">Ime zadatka</th>
                        <th style={{ width: 'calc(20%)' }} className="text-sm text-left p-3 font-normal border-r border-r-white">Vrijeme kreiranja</th>
                        <th style={{ width: 'calc(20%)' }} className="text-sm text-left p-3 font-normal border-r border-r-white">Vrijeme završenja</th>
                        <th style={{ width: 'calc(20%)' }} className="text-sm text-left p-3 font-normal border-r border-r-white">Trajanje</th>
                        <th style={{ width: 'calc(10%)' }} className="text-sm text-left p-3 font-normal">Status</th>
                    </tr>
                </thead>
            </table>
            <div className="overflow-y-auto w-full" style={{ maxHeight: 500 }}>
                <table className="w-full border-t border-x">
                    <tbody>{renderTableRows()}</tbody>
                </table>
            </div>
        </div>

    );
};

export default TaskTable;