import React, { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom';
import { Car, Truck, CalendarDays, Fuel, Gauge, Clock, MapPin, Edit2, Trash2 } from 'lucide-react';
import { TbManualGearbox, TbEngine } from 'react-icons/tb';
import { MdOutlineNewReleases } from "react-icons/md";

function ProdajaDashboard() {


    const [tab, setTab] = useState("vozila");
    const [ cars, setCars ] = useState([])
    const [ teretnaCars, setTeretnaCars ] = useState([])

    const fetchCars = async () => {
        await fetch('https://api.arenabackend.org/cars/all-vehicles', {
            method: 'GET',
        }).then((res) => res.json()).then((response) => setCars(response))
    }

    const fetchTeretnaCars = async () => {
        await fetch('https://api.arenabackend.org/cars/all-teretna-vehicles', {
            method: 'GET',
        }).then((res) => res.json()).then((response) => setTeretnaCars(response))
    }


    useEffect(() => {
        fetchCars()
        fetchTeretnaCars()
    })

    return (
        <div className='p-3'>
            <div className="buttons flex items-center">
                <button onClick={() => setTab("vozila")} className={`flex items-center justify-center gap-3 w-1/2 bg-gray-100 p-3 border-r border-b-2 ${tab === "vozila" ? "border-b-red-500 text-black" : "text-gray-400"}`}><Car size={22} />Vozila</button>
                <button onClick={() => setTab("teretna-vozila")} className={`flex items-center justify-center gap-3 w-1/2 bg-gray-100 p-3 border-r border-b-2 ${tab === "teretna-vozila" ? "border-b-red-500 text-black" : "text-gray-400"}`}><Truck size={22} />Teretna vozilo</button>
            </div>
            <div className='flex'>
                {
                    tab === "vozila" ? (
                        <div className='mt-3'>
                            <div className='flex flex-wrap items-start'>
                                {
                                    cars.filter((car) => car.advertisement_status !== 'Prodato').map((car) => {
                                        return (
                                            <div
                                                className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 border border-gray-300 shadow-lg overflow-hidden hover:bg-gray-100 relative"
                                                key={car.id}
                                            >
                                                <div className='actions_menu flex items-center gap-2 absolute top-3 right-3' style={{ zIndex: 999 }}>
                                                    <Link to={`/user/logged-in/edit-vozilo/${car.id}`} className='text-white rounded-full flex items-center justify-center bg-blue-600 opacity-75 hover:opacity-100' style={{ width: 35, height: 35 }}><Edit2 size={15} /></Link>
                                                    <button className='text-white rounded-full flex items-center justify-center bg-red-600 opacity-75 hover:opacity-100' style={{ width: 35, height: 35 }}><Trash2 size={15} /></button>
                                                </div>
                                                <div

                                                    className="car-card-image relative"
                                                    style={{
                                                        height: 260,
                                                        overflow: 'hidden',
                                                        backgroundImage: `url(${car.images[0].url.startsWith('http')
                                                            ? car.images[0].url
                                                            : `https://api.arenabackend.org/uploads/${car.images[0].url}`
                                                            })`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center',
                                                        backgroundRepeat: 'no-repeat',
                                                    }}
                                                >
                                                    <div className='absolute top-2 left-2 flex items-center justify-between gap-2'>
                                                        {car.title.includes('GRATIS') && (
                                                            <div className="rounded-full border flex items-center justify-center full bg-red-600 text-white text-xs py-1 px-3 text-white" style={{ height: 25 }}>
                                                                Akcija
                                                            </div>
                                                        )}
                                                        {car.mileage <= 15000 && (
                                                            <div className="rounded-full border flex items-center justify-center full bg-blue-600 text-white" style={{ width: 25, height: 25 }}>
                                                                <MdOutlineNewReleases size={17} />
                                                            </div>
                                                        )}
                                                        {car.actionPrice > 0 && (
                                                            <div className="badge bg-red-600 text-white py-1 px-3 rounded-full  flex items-center justify-center border border-white text-xs" style={{ height: 25 }}>
                                                                Na popustu
                                                            </div>
                                                        )}
                                                    </div>
                                                    <p style={{ bottom: 0, height: 30 }} className={`text-xs px-3 py-2 absolute right-0 rounded-tl-lg flex items-center gap-2 uppercase font-semibold ${car.advertisement_status === "U dolasku" ? 'bg-purple-500 text-white' : car.advertisement_status === "Prodato" ? 'bg-red-500 text-white' : 'bg-green-500 text-white'}`}>{car.advertisement_status === "U dolasku" ? <Clock size={15} /> : null}{car.advertisement_status}</p>
                                                </div>
                                                <div className="p-4 flex flex-col gap-4">
                                                    <h2 className="line-clamp-2 font-medium text-lg mb-2" style={{ height: 50 }}>
                                                        {car.title}
                                                    </h2>
                                                    <div className='mt-2 flex items-center justify-between flex-wrap'>
                                                        <p style={{ fontSize: 10 }} className='opacity-50 flex items-center gap-2 w-1/3 '><CalendarDays size={18} /> {car.year} god.</p>
                                                        <p style={{ fontSize: 10 }} className='opacity-50 flex items-center gap-2 w-1/3 '><Gauge size={18} /> {car.mileage ? car.mileage.toLocaleString(void 0, { maximumFractionDigits: 2 }) : 0} km</p>
                                                        <p style={{ fontSize: 10 }} className='opacity-50 flex items-center gap-2 w-1/3 '><TbManualGearbox size={18} /> {car.transmission === "Manuelac" ? "Manuelni" : car.transmission}</p>
                                                        <p style={{ fontSize: 10 }} className='opacity-50 flex items-center gap-2 w-1/3 mt-2'><Fuel size={18} /> {car.fuel}</p>
                                                        <p style={{ fontSize: 10 }} className='opacity-50 flex items-center gap-2 w-1/3 mt-2'><TbEngine size={18} /> {car.engine} / {car.power}kW</p>
                                                        <p style={{ fontSize: 10 }} className='opacity-50 flex items-center gap-2 w-1/3 mt-2 opacity-0'><Fuel size={18} /> {car.fuel}</p>
                                                    </div>
                                                    <div>
                                                        <p className="text-sm mt-3">
                                                            Cijena sa PDV-om
                                                        </p>
                                                        {car.actionPrice > 0 ? (
                                                            <div className="flex items-center gap-3">
                                                                <h3 className="font-bold text-xl text-red-500">
                                                                    {car.actionPrice.toLocaleString(
                                                                        void 0,
                                                                        {
                                                                            maximumFractionDigits: 2,
                                                                        }
                                                                    )}
                                                                    KM
                                                                </h3>
                                                                <p className="font-regular line-through text-base">
                                                                    {car.price.toLocaleString(void 0, {
                                                                        maximumFractionDigits: 2,
                                                                    })}{' '}
                                                                    KM
                                                                </p>
                                                            </div>
                                                        ) : (
                                                            <h3 className="font-bold text-xl">
                                                                {car.price.toLocaleString(void 0, {
                                                                    maximumFractionDigits: 2,
                                                                })}
                                                                KM
                                                            </h3>
                                                        )}
                                                    </div>
                                                </div>
                                                <p className='text-xs flex items-center gap-2 text-gray-400 absolute bottom-3 right-4'><MapPin size={17} /> I. Sarajevo</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    ) : (
                            <div className='mt-3'>
                                {/* <div className='flex flex-wrap items-start' style={{ gap: 10 }}>
                                    {
                                        teretnaCars.filter((car) => car.advertisement_status !== 'Prodato').map((car) => {
                                            return (
                                                <div
                                                    className="car-card border border-gray-300 shadow-lg overflow-hidden hover:bg-gray-100 relative"
                                                    key={car.id}
                                                    style={{ width: 'calc(100% / 4 - 7.5px)', minWidth: 300 }}
                                                >
                                                    <div className='actions_menu flex items-center gap-2 absolute top-3 right-3' style={{ zIndex: 999 }}>
                                                        <Link to={`/user/logged-in/edit-vozilo/${car.id}`} className='text-white rounded-full flex items-center justify-center bg-blue-600 opacity-75 hover:opacity-100' style={{ width: 35, height: 35 }}><Edit2 size={15} /></Link>
                                                        <button className='text-white rounded-full flex items-center justify-center bg-red-600 opacity-75 hover:opacity-100' style={{ width: 35, height: 35 }}><Trash2 size={15} /></button>
                                                    </div>
                                                    <div

                                                        className="car-card-image relative"
                                                        style={{
                                                            height: 260,
                                                            overflow: 'hidden',
                                                            backgroundImage: `url(${car.images[0].url.startsWith('http')
                                                                ? car.images[0].url
                                                                : `https://api.arenabackend.org/uploads/${car.images[0].url}`
                                                                })`,
                                                            backgroundSize: 'cover',
                                                            backgroundPosition: 'center',
                                                            backgroundRepeat: 'no-repeat',
                                                        }}
                                                    >
                                                        <div className='absolute top-2 left-2 flex items-center justify-between gap-2'>
                                                            {car.title.includes('GRATIS') && (
                                                                <div className="rounded-full border flex items-center justify-center full bg-red-600 text-white text-xs py-1 px-3 text-white" style={{ height: 25 }}>
                                                                    Akcija
                                                                </div>
                                                            )}
                                                            {car.mileage <= 15000 && (
                                                                <div className="rounded-full border flex items-center justify-center full bg-blue-600 text-white" style={{ width: 25, height: 25 }}>
                                                                    <MdOutlineNewReleases size={17} />
                                                                </div>
                                                            )}
                                                            {car.actionPrice > 0 && (
                                                                <div className="badge bg-red-600 text-white py-1 px-3 rounded-full  flex items-center justify-center border border-white text-xs" style={{ height: 25 }}>
                                                                    Na popustu
                                                                </div>
                                                            )}
                                                        </div>
                                                        <p style={{ bottom: 0, height: 30 }} className={`text-xs px-3 py-2 absolute right-0 rounded-tl-lg flex items-center gap-2 uppercase font-semibold ${car.advertisement_status === "U dolasku" ? 'bg-purple-500 text-white' : car.advertisement_status === "Prodato" ? 'bg-red-500 text-white' : 'bg-green-500 text-white'}`}>{car.advertisement_status === "U dolasku" ? <Clock size={15} /> : null}{car.advertisement_status}</p>
                                                    </div>
                                                    <div className="p-4 flex flex-col gap-4">
                                                        <h2 className="line-clamp-2 font-medium text-lg mb-2" style={{ height: 50 }}>
                                                            {car.title}
                                                        </h2>
                                                        <div className='mt-2 flex items-center justify-between flex-wrap'>
                                                            <p style={{ fontSize: 10 }} className='opacity-50 flex items-center gap-2 w-1/3 '><CalendarDays size={18} /> {car.year} god.</p>
                                                            <p style={{ fontSize: 10 }} className='opacity-50 flex items-center gap-2 w-1/3 '><Gauge size={18} /> {car.mileage ? car.mileage.toLocaleString(void 0, { maximumFractionDigits: 2 }) : 0} km</p>
                                                            <p style={{ fontSize: 10 }} className='opacity-50 flex items-center gap-2 w-1/3 '><TbManualGearbox size={18} /> {car.transmission === "Manuelac" ? "Manuelni" : car.transmission}</p>
                                                            <p style={{ fontSize: 10 }} className='opacity-50 flex items-center gap-2 w-1/3 mt-2'><Fuel size={18} /> {car.fuel}</p>
                                                            <p style={{ fontSize: 10 }} className='opacity-50 flex items-center gap-2 w-1/3 mt-2'><TbEngine size={18} /> {car.engine} / {car.power}kW</p>
                                                            <p style={{ fontSize: 10 }} className='opacity-50 flex items-center gap-2 w-1/3 mt-2 opacity-0'><Fuel size={18} /> {car.fuel}</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-sm mt-3">
                                                                Cijena sa PDV-om
                                                            </p>
                                                            {car.actionPrice > 0 ? (
                                                                <div className="flex items-center gap-3">
                                                                    <h3 className="font-bold text-xl text-red-500">
                                                                        {car.actionPrice.toLocaleString(
                                                                            void 0,
                                                                            {
                                                                                maximumFractionDigits: 2,
                                                                            }
                                                                        )}
                                                                        KM
                                                                    </h3>
                                                                    <p className="font-regular line-through text-base">
                                                                        {car.price.toLocaleString(void 0, {
                                                                            maximumFractionDigits: 2,
                                                                        })}{' '}
                                                                        KM
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                <h3 className="font-bold text-xl">
                                                                    {car.price.toLocaleString(void 0, {
                                                                        maximumFractionDigits: 2,
                                                                    })}
                                                                    KM
                                                                </h3>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <p className='text-xs flex items-center gap-2 text-gray-400 absolute bottom-3 right-4'><MapPin size={17} /> I. Sarajevo</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div> */}
                                <div className='flex flex-wrap items-start'>
                                    {
                                        teretnaCars.filter((car) => car.advertisement_status !== 'Prodato').map((car) => {
                                            return (
                                                <div
                                                    className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 border border-gray-300 shadow-lg overflow-hidden hover:bg-gray-100 relative"
                                                    key={car.id}
                                                >
                                                    <div className='actions_menu flex items-center gap-2 absolute top-3 right-3' style={{ zIndex: 999 }}>
                                                        <Link to={`/user/logged-in/edit-vozilo/${car.id}`} className='text-white rounded-full flex items-center justify-center bg-blue-600 opacity-75 hover:opacity-100' style={{ width: 35, height: 35 }}><Edit2 size={15} /></Link>
                                                        <button className='text-white rounded-full flex items-center justify-center bg-red-600 opacity-75 hover:opacity-100' style={{ width: 35, height: 35 }}><Trash2 size={15} /></button>
                                                    </div>
                                                    <div
                                                        className="car-card-image relative"
                                                        style={{
                                                            height: 260,
                                                            overflow: 'hidden',
                                                            backgroundImage: `url(${car.images[0].url.startsWith('http')
                                                                ? car.images[0].url
                                                                : `https://api.arenabackend.org/uploads/${car.images[0].url}`
                                                                })`,
                                                            backgroundSize: 'cover',
                                                            backgroundPosition: 'center',
                                                            backgroundRepeat: 'no-repeat',
                                                        }}
                                                    >
                                                        <div className='absolute top-2 left-2 flex items-center justify-between gap-2'>
                                                            {car.title.includes('GRATIS') && (
                                                                <div className="rounded-full border flex items-center justify-center full bg-red-600 text-white text-xs py-1 px-3" style={{ height: 25 }}>
                                                                    Akcija
                                                                </div>
                                                            )}
                                                            {car.mileage <= 15000 && (
                                                                <div className="rounded-full border flex items-center justify-center full bg-blue-600 text-white" style={{ width: 25, height: 25 }}>
                                                                    <MdOutlineNewReleases size={17} />
                                                                </div>
                                                            )}
                                                            {car.actionPrice > 0 && (
                                                                <div className="badge bg-red-600 text-white py-1 px-3 rounded-full flex items-center justify-center border border-white text-xs" style={{ height: 25 }}>
                                                                    Na popustu
                                                                </div>
                                                            )}
                                                        </div>
                                                        <p style={{ bottom: 0, height: 30 }} className={`text-xs px-3 py-2 absolute right-0 rounded-tl-lg flex items-center gap-2 uppercase font-semibold ${car.advertisement_status === "U dolasku" ? 'bg-purple-500 text-white' : car.advertisement_status === "Prodato" ? 'bg-red-500 text-white' : 'bg-green-500 text-white'}`}>{car.advertisement_status === "U dolasku" ? <Clock size={15} /> : null}{car.advertisement_status}</p>
                                                    </div>
                                                    <div className="p-4 flex flex-col gap-4">
                                                        <h2 className="line-clamp-2 font-medium text-lg mb-2" style={{ height: 50 }}>
                                                            {car.title}
                                                        </h2>
                                                        <div className='mt-2 flex items-center justify-between flex-wrap'>
                                                            <p style={{ fontSize: 10 }} className='opacity-50 flex items-center gap-2 w-1/3 '><CalendarDays size={18} /> {car.year} god.</p>
                                                            <p style={{ fontSize: 10 }} className='opacity-50 flex items-center gap-2 w-1/3 '><Gauge size={18} /> {car.mileage ? car.mileage.toLocaleString(void 0, { maximumFractionDigits: 2 }) : 0} km</p>
                                                            <p style={{ fontSize: 10 }} className='opacity-50 flex items-center gap-2 w-1/3 '><TbManualGearbox size={18} /> {car.transmission === "Manuelac" ? "Manuelni" : car.transmission}</p>
                                                            <p style={{ fontSize: 10 }} className='opacity-50 flex items-center gap-2 w-1/3 mt-2'><Fuel size={18} /> {car.fuel}</p>
                                                            <p style={{ fontSize: 10 }} className='opacity-50 flex items-center gap-2 w-1/3 mt-2'><TbEngine size={18} /> {car.engine} / {car.power}kW</p>
                                                            <p style={{ fontSize: 10 }} className='opacity-50 flex items-center gap-2 w-1/3 mt-2 opacity-0'><Fuel size={18} /> {car.fuel}</p>
                                                        </div>
                                                        <div>
                                                            <p className="text-sm mt-3">
                                                                Cijena sa PDV-om
                                                            </p>
                                                            {car.actionPrice > 0 ? (
                                                                <div className="flex items-center gap-3">
                                                                    <h3 className="font-bold text-xl text-red-500">
                                                                        {car.actionPrice.toLocaleString(
                                                                            void 0,
                                                                            {
                                                                                maximumFractionDigits: 2,
                                                                            }
                                                                        )}
                                                                        KM
                                                                    </h3>
                                                                    <p className="font-regular line-through text-base">
                                                                        {car.price.toLocaleString(void 0, {
                                                                            maximumFractionDigits: 2,
                                                                        })}{' '}
                                                                        KM
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                <h3 className="font-bold text-xl">
                                                                    {car.price.toLocaleString(void 0, {
                                                                        maximumFractionDigits: 2,
                                                                    })}
                                                                    KM
                                                                </h3>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <p className='text-xs flex items-center gap-2 text-gray-400 absolute bottom-3 right-4'><MapPin size={17} /> I. Sarajevo</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </div>
                    )
                }
            </div>
        </div>
    )
}

export default ProdajaDashboard