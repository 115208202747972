import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { read, utils, writeFile } from 'xlsx';
import { useAppData } from '../../context/ApplicationProvider';


function PrijemNaZalihuVozila() {

    const [data, setData] = useState([]);
    const { evidencije } = useAppData();
    const [visibleYears, setVisibleYears] = useState([]);
    const [monthlyTotal, setMonthlyTotal] = useState({});


    const toggleYearVisibility = year => {
        setVisibleYears(currentVisibleYears => {
            if (currentVisibleYears.includes(year)) {
                return currentVisibleYears.filter(y => y !== year);
            } else {
                return [...currentVisibleYears, year];
            }
        });
    };

    // Function to set initially visible years
    const setInitialVisibleYears = () => {
        const initialYears = [];
        evidencije.forEach(item => {
            const date = parseDate(item["Datum RaËuna"]);
            if (date) {
                const year = date.getFullYear();
                if (!initialYears.includes(year)) {
                    initialYears.push(year);
                }
            }
        });
        setVisibleYears(initialYears);
    };

    // Call setInitialVisibleYears when evidencije changes
    const handleImport = ($event) => {
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    setData(rows)
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }

    function formatDate(date) {
        if (!date) return "";
        // Dohvati dan, mjesec i godinu
        const day = date.getDate();
        const month = date.getMonth() + 1; // Mjeseci počinju od 0, pa se dodaje 1
        const year = date.getFullYear();

        // Dohvati sate i minute
        const hours = date.getHours();
        const minutes = date.getMinutes();

        // Formatiraj datum
        return `${day}.${month}.${year}`;
    }


    function getRandomColor() {
        return '#' + Math.floor(Math.random() * 16777215).toString(16);
    }

    const monthColors = [
        {
            month: 1,
            color: '#f5d167'
        },
        {
            month: 2,
            color: '#a0f567'
        },
        {
            month: 3,
            color: '#67a7f5'
        },
        {
            month: 4,
            color: '#c690d6'
        },
        {
            month: 5,
            color: '#f567ae'
        },
        {
            month: 6,
            color: '#f56775'
        },
        {
            month: 7,
            color: '#cff567'
        },
        {
            month: 8,
            color: '#f59667'
        },
        {
            month: 9,
            color: '#999593'
        },
        {
            month: 10,
            color: '#a0ad92'
        },
        {
            month: 11,
            color: '#b8a7b5'
        },
        {
            month: 12,
            color: '#bffeff'
        },
    ];

    function parseDate(dateString) {
        if (typeof dateString === 'string' && dateString.includes('.')) {
            // For format DD.MM.YYYY
            const parts = dateString.split('.');
            const day = parseInt(parts[0]);
            const month = parseInt(parts[1]) - 1; // Month is 0-indexed
            const year = parseInt(parts[2]);
            return new Date(year, month, day);
        } else if (dateString && typeof dateString === 'string') {
            // For other formats like YYYY-MM-DD
            return new Date(dateString);
        } else {
            return null; // Return null if dateString is not a string or is null/undefined
        }
    }

    data.forEach(item => {
        const date = parseDate(item.__EMPTY);
        if (date) {
            const month = date.getMonth() + 1; // Month is 0-indexed, so add 1

            // Assign a random color if not already assigned for this month
            if (!monthColors[month]) {
                monthColors[month] = getRandomColor();
            }
        }
    });

    const handleArraySubmit = async () => {
        const postData = data.slice(2).map((item) => {
            return {
                redniBroj: item[`" EVIDENCIJA UPLATA ZA PRODATA VOZILA 2024.GODINA"`] || item["                                                                          EVIDENCIJA   UPLATA  ZA   PRODATA   VOZILA  2024.GODINA"] || "",
                datum: item.__EMPTY ?
                    (typeof item.__EMPTY === 'string' && item.__EMPTY.includes(".") ? item.__EMPTY : new Date(item.__EMPTY).toLocaleDateString('en-GB'))
                    : "",
                nazivKupca: item["__EMPTY_1"] || "",
                brojRacuna: item["__EMPTY_2"] || "",
                vozilo: item["__EMPTY_3"] || "",
                iznisRacuna: item["__EMPTY_4"] || "",
                virman: item["__EMPTY_5"] || "",
                kes: item["__EMPTY_6"] || "",
                ukupno: item["__EMPTY_7"] || "",
                napomena: item["__EMPTY_8"] || "",
            }
        })

        try {
            const response = await axios.post('https://app.arenabackend.org/administration/evidencija-uplata', postData);
            console.log(response.data);
        } catch (error) {
            console.error('Greška prilikom slanja zahtjeva:', error);
        }
    };

    const monthNames = [
        {
            month: 1,
            title: "Jan."
        },
        {
            month: 2,
            title: "Feb."
        },
        {
            month: 3,
            title: "Mart"
        },
        {
            month: 4,
            title: "April"
        },
        {
            month: 5,
            title: "Maj"
        },
        {
            month: 6,
            title: "Jun"
        },
        {
            month: 7,
            title: "Jul"
        },
        {
            month: 8,
            title: "Aug."
        },
        {
            month: 9,
            title: "Sept."
        },
        {
            month: 10,
            title: "Okt."
        },
        {
            month: 11,
            title: "Nov."
        },
        {
            month: 12,
            title: "Dec."
        },
    ]

    const isYearVisible = year => visibleYears.includes(year);
    
    
    useEffect(() => {
        setInitialVisibleYears()
        const calculateMonthlyTotal = () => {
            const monthlyTotal = {};
            evidencije.forEach(item => {
                const date = parseDate(item["Datum RaËuna"]);
                if (date) {
                    const year = date.getFullYear();
                    const month = date.getMonth() + 1;
                    const total = parseFloat(item["Vrijednost sa PDV-om"]);

                    if (!monthlyTotal[year]) {
                        monthlyTotal[year] = {};
                    }
                    if (!monthlyTotal[year][month]) {
                        monthlyTotal[year][month] = 0;
                    }

                    monthlyTotal[year][month] += total;
                }
            });
            setMonthlyTotal(monthlyTotal);
        };

        const calculateTotal = () => {
            const monthlyTotal = {};
            evidencije.forEach(item => {
                const date = parseDate(item["Datum RaËuna"]);
                if (date) {
                    const year = date.getFullYear();
                    const month = date.getMonth() + 1;
                    const total = parseFloat(item["Vrijednost sa PDV-om"]);

                    if (!monthlyTotal[year]) {
                        monthlyTotal[year] = {};
                    }
                    if (!monthlyTotal[year][month]) {
                        monthlyTotal[year][month] = 0;
                    }

                    monthlyTotal[year][month] += total;
                }
            });
            setMonthlyTotal(monthlyTotal);
        };

        calculateMonthlyTotal();
    }, [evidencije]);

    const arr = evidencije.map((ev) => parseFloat(ev["Vrijednost sa PDV-om"]))
    const sum = arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);


    // useEffect(() => {
    //     setInitialVisibleYears();
    // }, [evidencije]);
    

    return (
        <div className='p-3'>
            <div className='flex items-center justify-between pb-6 pt-3'>
                <h1>Prodata vozila 2022 - 2024:</h1>
                <div>
                    <button className={`py-2 px-3 border rounded text-sm ml-3 ${isYearVisible(2022) ? '' : 'opacity-50'}`} onClick={() => toggleYearVisibility(2022)}>2022 god.</button>
                    <button className={`py-2 px-3 border rounded text-sm ml-3 ${isYearVisible(2023) ? '' : 'opacity-50'}`} onClick={() => toggleYearVisibility(2023)}>2023 god.</button>
                    <button className={`py-2 px-3 border rounded text-sm ml-3 ${isYearVisible(2024) ? '' : 'opacity-50'}`} onClick={() => toggleYearVisibility(2024)}>2024 god.</button>
                </div>
            </div>
            <div className='bg-gray-200 p-3'>Ukupan promet od Novembra 2022god: <strong>{sum.toLocaleString(void 0, { maximumFractionDigits: 2 }).replaceAll(",",".")} KM</strong></div>
            <div className='my-3'>
                <h2 className='mb-2 font-semibold'>Promet po mjesecima</h2>
                <div className='flex items-center gap-3 overflow-x-auto'>
                    {Object.keys(monthlyTotal).reverse().map((year) => {
                        return Object.keys(monthlyTotal[year]).reverse().map((month) => {
                            const currDate = `${new Date().getFullYear()}.${new Date().getMonth() + 1}`
                            const profitDate = `${Number(year)}.${Number(month)}`
                            return (
                                <div className='p-3 text-white' style={{ minWidth: 280, backgroundColor: profitDate === currDate ? '#d22829' : 'black' }} key={`${year}-${month}`}>
                                    {`${profitDate === currDate ? 'Trenutno za' : 'Ukupno za'} ${monthNames.filter((mnt) => mnt.month === Number(month)).map((mnt) => mnt.title)} ${year} god.`}
                                    <br/>
                                    {
                                        ` ${monthlyTotal[year][month].toLocaleString(void 0, { maximumFractionDigits: 2 }).replaceAll(",", ".")} KM`
                                    }
                                </div>
                            );
                        });
                    })}
                </div>
            </div>
            {evidencije ? (
                    <table className='w-full border-x'>
                        <thead className='w-full border-t border-x'>
                            <tr className='w-full bg-black'>
                                <th style={{ width: '2%' }} className='text-white px-3 py-1 border-r font-normal text-left text-xs'>R. broj</th>
                                <th style={{ width: '5%' }} className='text-white px-3 py-1 border-r font-normal text-left text-xs'>Datum</th>
                                <th style={{ width: '10%' }} className='text-white px-3 py-1 border-r font-normal text-left text-xs'>Naziv kupca</th>
                                <th style={{ width: '5%' }} className='px-3 py-1 border-r text-white font-normal text-left text-xs'>Faktura</th>
                                <th style={{ width: '4%' }} className='text-white px-3 py-1 border-r font-normal text-left text-xs'>Broj računa</th>
                                <th style={{ width: '16%' }} className='text-white px-3 py-1 border-r font-normal text-left text-xs'>Vozilo</th>
                                <th style={{ width: '5%' }} className='text-white px-3 py-1 border-r font-normal text-left text-xs'>Broj šasije</th>
                                <th style={{ width: '5%' }} className='text-white px-3 py-1 border-r font-normal text-left text-xs'>Iznos računa</th>
                                <th style={{ width: '8%' }} className='text-white px-3 py-1 border-r font-normal text-left text-xs'>Način plaćanja</th>
                            </tr>
                        </thead>
                        <tbody>
                            {evidencije.sort((a, b) => {
                                const dateA = parseDate(a["Datum RaËuna"]);
                                const dateB = parseDate(b["Datum RaËuna"]);

                                if (dateA && dateB) {
                                    return dateB - dateA;
                                } else {
                                    // Handle invalid dates
                                    return 0;
                                }
                            }).map((item, i) => {
                                const date = parseDate(item["Datum RaËuna"]);
                                if (date) {
                                    const year = date.getFullYear();
                                    const isVisible = visibleYears.includes(year);
                                    const month = date.getMonth() + 1;
                                    const backgroundColor = monthColors.filter((m) => m.month === month).map((m) => m.color) || 'transparent';

                                    const lines = item.Opis.split('\r\n');

                                    const brojSasijeLine = lines.find(line => line.includes('Broj šasije'));

                                    const brojSasijeValue = brojSasijeLine ? brojSasijeLine.split(':')[1] : ' ';

                                    return (
                                        <tr className='w-full border-b' key={item._id} style={{ display: isVisible ? 'table-row' : 'none', backgroundColor }}>
                                            <td style={{ width: '2%' }} className='px-3 py-1 border-r font-normal text-left text-xs'>{i+1}</td>
                                            <td style={{ width: '5%' }} className='w-1/9 px-3 py-1 border-r font-normal text-left text-xs'>
                                                {item["Datum RaËuna"] ?
                                                    (typeof item["Datum RaËuna"] === 'string' && item["Datum RaËuna"].includes(".") ? item["Datum RaËuna"] : formatDate(new Date(item["Datum RaËuna"])))
                                                    : ""}
                                            </td>

                                            <td style={{ width: '10%' }} className='w-1/9 px-3 py-1 border-r font-normal text-left text-xs'>{item["Naziv kupca"] || ""}</td>
                                            <td style={{ width: '5%' }} className='w-1/9 px-3 py-1 border-r font-normal text-left text-xs'>{item.Dokument}</td>
                                            <td style={{ width: '4%' }} className='w-1/9 px-3 py-1 border-r font-normal text-left text-xs'>{item["Broj raËuna"] || ""}</td>
                                            <td style={{ width: '16%' }} className='w-1/9 px-3 py-1 border-r font-normal text-left text-xs'>{item["Naziv artikla"] || ""}</td>
                                            <td style={{ width: '5%' }} className='w-1/9 px-3 py-1 border-r font-normal text-left text-xs font-semibold'>{brojSasijeValue}</td>
                                            <td style={{ width: '5%' }} className='w-1/9 px-3 py-1 border-r font-normal text-left text-xs font-semibold'>{parseFloat(item["Vrijednost sa PDV-om"]).toLocaleString(void 0, { maximumFractionDigits: 2 }).replace(",", ".")} KM</td>
                                            <td style={{ width: '8%' }} className='w-1/9 px-3 py-1 border-r font-normal text-left text-xs'>{item["NaËin plaÊanja"]}</td>
                                        </tr>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </tbody>
                    </table>
            ) : null}
        </div>
    )
}

export default PrijemNaZalihuVozila