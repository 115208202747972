import { Captions, Diamond, PercentCircle, Tag, Calendar, GaugeCircle, Gauge, Tv2, CarFront, Fan, ParkingCircle, Video, Plus } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TbEngine } from "react-icons/tb";
import CarForm from '../../forms/CarForm';
function EditCar() {

    const { voziloId } = useParams();

    const [state, setState] = useState({
        loading: true,
        formData: {
            brand_id: '',
            model_id: '',
            title: '',
            price: 0,
            mileage: 0,
            year: new Date().getFullYear(),
            type: '',
            fuel: '',
            transmission: '',
            drive: '',
            carColor: '',
            car_status: '',
            doors: '',
            advertisement_status: '',
            engine: '',
            power: null,
            horsePowers: null,
            weight: null,
            climate: '',
            sound: '',
            parkingSenzori: '',
            parkingCamera: '',
            interior: '',
            roloZavjese: '',
            lights: '',
            seats: null,
            transmissionLength: '',
            wheels: '',
            numberOfOwners: null,
            emmisionStandad: '',
            yearOfFirstRegistration: null,
            registeredTo: null,
            warranty: null,
            description: '',
            images: [],
            specifications: []
        }
    });

    const fetchVozilo = async () => {
        try {
            const response = await fetch(`https://api.arenabackend.org/cars/vehicle/${voziloId}`);
            const data = await response.json();
            setState({
                loading: false,
                formData: {
                    ...data
                }
            });
        } catch (err) {
            console.error(err);
            setState((prevState) => ({
                ...prevState,
                loading: false
            }));
        }
    };

    useEffect(() => {
        fetchVozilo();
    }, [voziloId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }));
    };

    const { loading, formData } = state;

    return (
        <div className='p-3'>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div>
                    <div className='images-gallery p-3 rounded-lg border flex flex-wrap items-center gap-3'>
                        {formData.images.map((voziloImage, i) => (
                            <div key={voziloImage.id} className='relative bg-gray-200 cursor-grab rounded' draggable style={{ width: 'calc(100% / 8 - 10.5px)', height: 140, overflow: 'hidden', backgroundImage: `url(${voziloImage.url})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                <p className='text-white absolute top-2 right-2 bg-red-600 rounded-md text-center flex items-center justify-center text-sm' style={{ height: 25, width: 25 }}>{i + 1}</p>
                            </div>
                        ))}
                        <div className='relative bg-gray-100 cursor-pinter flex flex-col items-center justify-center gap-3 rounded' style={{ width: 'calc(100% / 8 - 10.5px)', height: 140, }}>
                            <Plus size={32} />
                            <p>Dodaj sliku</p>
                        </div>
                    </div>
                        <CarForm formData={formData} setState={setState} state={state} />
                </div>
            )}
        </div>
    );
}

export default EditCar;
