import { message, notification } from 'antd';
import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
const socket = io("https://app.arenabackend.org", {
    reconnection: true
})

const AppContext = createContext();

export const ApplicationProvider = ({ children }) => {

    const audioSound = require('../assets/sound/notification.mp3');

    const [messageApi, contextHolder] = message.useMessage();
    const [api] = notification.useNotification();
    const [zadaci, setZadaci] = useState([])
    const [users, setUsers] = useState([])
    const [sections, setSections] = useState([])
    const [currentUser, setCurrentUser] = useState([])
    const [vozila, setVozila] = useState([]);
    const [pneumatici, setPneumatici] = useState([]);
    const [activeWheel, setActiveWheel] = useState(null);
    const [evidencije, setEvidencije] = useState([])
    const [prijemi, setPrijemi] = useState([]);

    const fetchPrijemi = async () => {
        await fetch('https://app.arenabackend.org/zapisnici/svi-izvjestaji').then((res) => res.json()).then((response) => setPrijemi(response)).catch((err) => console.log(err))
    }


    const fetchVehicles = async () => {
        await fetch('https://app.arenabackend.org/administration/all-vehicles').then((res) => res.json()).then((response) => setVozila(response)).catch((e) => console.log(e))
    }

    const fetchUsers = async () => {
        await fetch("https://app.arenabackend.org/korisnici/svi-korisnici")
            .then((res) => res.json())
            .then((response) => {
                setUsers(response)
            })
            .catch((e) => {
                messageApi.open({
                    type: 'error',
                    content: 'Greska prilikom izvlacenja korisnika',
                });
            })
    }

    const fetchZadaci = async () => {
        await fetch("https://app.arenabackend.org/zadaci/svi-zadaci")
            .then((res) => res.json())
            .then((response) => {
                setZadaci(response)
            })
            .catch((e) => {
                messageApi.open({
                    type: 'error',
                    content: 'Greska prilikom izvlacenja zapisnika',
                });
            })
    }

    const fetchEvidencije = async () => {
        await fetch("https://app.arenabackend.org/administration/prodaja")
            .then((res) => res.json())
            .then((response) => {
                setEvidencije(response)
            })
            .catch((e) => {
                messageApi.open({
                    type: 'error',
                    content: 'Greska prilikom izvlacenja zapisnika',
                });
            })
    }

    const fetchPneumatici = async () => {
        await fetch("https://app.arenabackend.org/administration/sve-pneumatici")
            .then((res) => res.json())
            .then((response) => {
                setPneumatici(response)
            })
            .catch((e) => {
                messageApi.open({
                    type: 'error',
                    content: 'Greska prilikom izvlacenja zapisnika',
                });
            })
    }

    const fetchSections = async () => {
        await fetch("https://app.arenabackend.org/administration/svi-sektori")
            .then((res) => res.json())
            .then((response) => {
                setSections(response)
            })
            .catch((e) => {
                messageApi.open({
                    type: 'error',
                    content: 'Greska prilikom izvlacenja sektora',
                });
            })
    }

    const fetchCurrentUser = async () => {
        try {
            const userId = Cookies.get('user');
            if (!userId) {
                throw new Error('User ID not found in cookies');
            }
            const response = await fetch(`https://app.arenabackend.org/korisnici/korisnik/${userId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }
            const userData = await response.json();
            setCurrentUser(userData);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };


    // const getCurrentUser = async () => {
    //     const user = sessionStorage.getItem('user')
    //     await fetch(`https://app.arenabackend.org/korisnici/korisnik/${user}`)
    //         .then((res) => res.json())
    //         .then((response) => {
    //             setCurrentUser(response)
    //         })
    //         .catch((e) => {
    //             messageApi.open({
    //                 type: 'error',
    //                 content: 'Greska prilikom izvlacenja sektora',
    //             });
    //         })
    // }

    useEffect(() => {
        fetchZadaci();
        fetchPneumatici();
        fetchUsers();
        fetchSections();
        fetchVehicles();
        fetchCurrentUser(); 
        fetchEvidencije();
        fetchPrijemi()

        socket.on('kolegaPrijavljen', (data) => {
            messageApi.open({
                message: 'ARENA NOTIFIKACIJA',
                description: data.message
            });
            const audio = new Audio(audioSound)
            audio.play()
            // Update your React state or perform other actions
        });

        socket.on('dodatRokZadatka', (data) => {
            fetchZadaci()
        })

        socket.on('dodaniKorisnici', (msg) => {
            messageApi.open({
                message: 'ARENA NOTIFIKACIJA',
                description: msg
            });
            const audio = new Audio(audioSound)
            audio.play()
        });

        socket.on('prihvacenaProcjena', (msg) => {
            messageApi.open({
                message: 'ARENA NOTIFIKACIJA',
                description: msg
            });
            const audio = new Audio(audioSound)
            audio.play()
        });

        socket.on('odbijenaProcjena', (msg) => {
            messageApi.open({
                message: 'ARENA NOTIFIKACIJA',
                description: msg
            });
            const audio = new Audio(audioSound)
            audio.play()
        });


        socket.on('novaProcjena', (msg) => {
            api.info({
                message: `Notifikacija`,
                description: msg,
                placement: 'topRight',
            });
            const audio = new Audio(audioSound)
            audio.play()
        });

        socket.on('finishedTask', (msg) => {
            api.info({
                message: `Notifikacija`,
                description: msg,
                placement: 'topRight',
            });
            fetchPrijemi();
            fetchZadaci();
            const audio = new Audio(audioSound)
            audio.play()
        });

        socket.on('kreiranNoviZapisnik', (msg) => {
            api.info({
                message: `Notifikacija`,
                description: msg,
                placement: 'topRight',
            });
            const audio = new Audio(audioSound)
            audio.play()
        });

        socket.on('finishedTask', (msg) => {
            fetchPrijemi();
            fetchZadaci();
        });

        socket.on('kreiranZadatak', (msg) => {
            const isMe = currentUser._id === msg

            if (isMe === true) {
                api.info({
                    message: `Notifikacija`,
                    description: `Dobili ste novi zadatak`,
                    placement: 'topRight',
                });
                const audio = new Audio(audioSound)
                audio.play()
                fetchZadaci()
            } else {
                return null
            }
        });


        // Clean up the socket connection when the component unmounts
        return () => {
            socket.disconnect();
        };
    }, [])

    return (
        <AppContext.Provider value={{
            zadaci,
            users,
            sections,
            fetchZadaci,
            currentUser,
            vozila,
            setCurrentUser,
            fetchVehicles,
            pneumatici,
            activeWheel,
            setActiveWheel,
            evidencije,
            prijemi,
            fetchPrijemi
        }}>
            {contextHolder}
            {children}
        </AppContext.Provider>
    );
};

export const useAppData = () => {
    return useContext(AppContext);
};
