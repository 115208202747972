import { Input, Switch } from 'antd';
import axios from 'axios';
import { Check, ChevronLeft, PlusCircle, Trash, X } from 'lucide-react';
import React, { useEffect, useState } from 'react'
import { useNavigate, useNavigation, useParams } from 'react-router-dom'
import io from 'socket.io-client';
const socket = io("https://app.arenabackend.org", {
    reconnection: true
})

function EditPrijem() {

    const navigate = useNavigate();

    const { prijemId } = useParams();

    const [prijem, setPrijem] = useState([])
    const [loading, setLoading] = useState(true)
    const [formData, setFormData] = useState({
        vozilo: "",
        sasija: "",
        kilometraza: null,
        zapremina: "",
        snaga: null,
        mjenjac: "",
        boja: "",
        godiste: null,
        gorivo: "",
        dobavljac: "",
        regOznake: "",
        dosleUzVozilo: null,
        vlasnickaKnjizica: null,
        saobracajnaDozvola: null,
        registrovan: null,
        polisaOsiguranja: null,
        registrovanDo: "",
        brojKljuceva: null,
        triOvjerenaOtkupnaUgovora: null,
        fakturaSaFiskalnimRacunom: null,

        odlukaOprodaji: null,
        brendGume: "",
        dimenzijeGume: "",
        dot: null,
        tockoviZona: "Ljetni set",
        drugeGume: null,
        brendDrugeGume: "",
        dimenzijeDrugeGume: "",
        dotDrugeGume: null,
        tockoviZonaDrugeGume: "Ljetni set",
        prostiraci: "Gumeni",
        servisnaKnjizica: null,

        velikiServis: null,
        velikiServisKm: null,
        maliServis: null,
        maliServisKm: null,
        rezervniTocak: null,
        dizalicaPlusKljuc: null,
        prvaPomoc: null,
        kuka: null,
        lopovSarafi: null,
        lopovNastavak: null,
        policaUgepeku: null,
        alarmVrstaAlarmaLokacija: "",
        potpisStranke: null,
        potrebniRadovi: [],
    })
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [textInputValue, setTextInputValue] = useState('');

    const fetchPrijem = () => {
        fetch(`https://app.arenabackend.org/zapisnici/izvjestaj/${prijemId}`)
            .then((res) => {
                return res.json();
            })
            .then((response) => {
                setPrijem(response);
                setFormData({
                    vozilo: response.vozilo,
                    sasija: response.sasija,
                    kilometraza: response.kilometraza,
                    zapremina: response.zapremina,
                    snaga: response.snaga,
                    mjenjac: response.mjenjac,
                    boja: response.boja,
                    godiste: response.godiste,
                    gorivo: response.gorivo,
                    dobavljac: response.dobavljac,
                    regOznake: response.regOznake,
                    dosleUzVozilo: response.dosleUzVozilo,
                    vlasnickaKnjizica: response.vlasnickaKnjizica,
                    saobracajnaDozvola: response.saobracajnaDozvola,
                    registrovan: response.registrovan,
                    polisaOsiguranja: response.polisaOsiguranja,
                    registrovanDo: response.registrovanDo,
                    brojKljuceva: response.brojKljuceva,
                    triOvjerenaOtkupnaUgovora: response.triOvjerenaOtkupnaUgovora,
                    fakturaSaFiskalnimRacunom: response.fakturaSaFiskalnimRacunom,

                    odlukaOprodaji: response.odlukaOprodaji,
                    brendGume: response.brendGume,
                    dimenzijeGume: response.dimenzijeGume,
                    dot: response.dot,
                    tockoviZona: response.tockoviZona,
                    drugeGume: response.drugeGume,
                    brendDrugeGume: response.brendDrugeGume,
                    dimenzijeDrugeGume: response.dimenzijeDrugeGume,
                    dotDrugeGume: response.dotDrugeGume,
                    tockoviZonaDrugeGume: response.tockoviZonaDrugeGume,
                    prostiraci: response.prostiraci,
                    servisnaKnjizica: response.servisnaKnjizica,

                    velikiServis: response.velikiServis,
                    velikiServisKm: response.velikiServisKm,
                    maliServis: response.maliServis,
                    maliServisKm: response.maliServisKm,
                    rezervniTocak: response.rezervniTocak,
                    dizalicaPlusKljuc: response.dizalicaPlusKljuc,
                    prvaPomoc: response.prvaPomoc,
                    kuka: response.kuka,
                    lopovSarafi: response.lopovSarafi,
                    lopovNastavak: response.lopovNastavak,
                    policaUgepeku: response.policaUgepeku,
                    alarmVrstaAlarmaLokacija: response.alarmVrstaAlarmaLokacija,
                    potpisStranke: response.potpisStranke,
                    potrebniRadovi: response.potrebniRadovi,
                });
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false); // Set loading to false even in case of error
            });
    };

    useEffect(() => {
        fetchPrijem()
    }, [loading])

    const handleAddText = () => {
        if (textInputValue.length > 0) {
            const updatedPotrebniRadovi = [...formData.potrebniRadovi, textInputValue];

            setFormData({
                ...formData,
                potrebniRadovi: updatedPotrebniRadovi,
            });

            setTextInputValue('');
            setIsModalVisible(false);
        }
    };

    const removeTask = (taskToRemove) => {
        const updatedPotrebniRadovi = formData.potrebniRadovi.filter(task => task !== taskToRemove);
        setFormData({
            ...formData,
            potrebniRadovi: updatedPotrebniRadovi,
        });
    };

    console.log(formData.potrebniRadovi);

    const applyChanges = async () => {
        try {
            const response = await axios.put(`https://app.arenabackend.org/zapisnici/uredi-prijem/${prijem._id}`, {
                vozilo: formData.vozilo,
                sasija: formData.sasija,
                kilometraza: formData.kilometraza,
                zapremina: formData.zapremina,
                snaga: formData.snaga,
                mjenjac: formData.mjenjac,
                boja: formData.boja,
                godiste: formData.godiste,
                gorivo: formData.gorivo,
                dobavljac: formData.dobavljac,
                regOznake: formData.regOznake,
                dosleUzVozilo: formData.dosleUzVozilo,
                vlasnickaKnjizica: formData.vlasnickaKnjizica,
                saobracajnaDozvola: formData.saobracajnaDozvola,
                registrovan: formData.registrovan,
                polisaOsiguranja: formData.polisaOsiguranja,
                registrovanDo: formData.registrovanDo,
                brojKljuceva: formData.brojKljuceva,
                triOvjerenaOtkupnaUgovora: formData.triOvjerenaOtkupnaUgovora,
                fakturaSaFiskalnimRacunom: formData.fakturaSaFiskalnimRacunom,

                odlukaOprodaji: formData.odlukaOprodaji,
                brendGume: formData.brendGume,
                dimenzijeGume: formData.dimenzijeGume,
                dot: formData.dot,
                tockoviZona: formData.tockoviZona,
                drugeGume: formData.drugeGume,
                brendDrugeGume: formData.brendDrugeGume,
                dimenzijeDrugeGume: formData.dimenzijeDrugeGume,
                dotDrugeGume: formData.dotDrugeGume,
                tockoviZonaDrugeGume: formData.tockoviZonaDrugeGume,
                prostiraci: formData.prostiraci,
                servisnaKnjizica: formData.servisnaKnjizica,

                velikiServis: formData.velikiServis,
                velikiServisKm: formData.velikiServisKm,
                maliServis: formData.maliServis,
                maliServisKm: formData.maliServisKm,
                rezervniTocak: formData.rezervniTocak,
                dizalicaPlusKljuc: formData.dizalicaPlusKljuc,
                prvaPomoc: formData.prvaPomoc,
                kuka: formData.kuka,
                lopovSarafi: formData.lopovSarafi,
                lopovNastavak: formData.lopovNastavak,
                policaUgepeku: formData.policaUgepeku,
                alarmVrstaAlarmaLokacija: formData.alarmVrstaAlarmaLokacija,
                potpisStranke: formData.potpisStranke,
                potrebniRadovi: formData.potrebniRadovi,
            });

            if(!response) {
                alert('GRESKA')
            }

            socket.emit('arzuriranPrijem', prijem._id);

            alert('Uspiješno ste aržurirali prijem na zalihu');
        } catch (error) {
            console.error('Error updating task:', error);
        }
    };

    return prijem ? (
        <div className='p-3 bg-white flex items-center justify-between flex-wrap relative'>
            <div className='w-full pb-6 pt-3 flex items-center justify-center'>
                <button onClick={() => navigate(-1)} className='absolute left-5 flex items-center gap-2 font-semibold' style={{ color: '#d22829' }}><ChevronLeft /> Vrati se nazad</button>
                <h1 className='text-center text-xl font-semibold'>Uredjivanje prijema: {formData.vozilo}</h1>
            </div>
            <input onChange={(e) => setFormData((prev) => ({...prev, vozilo: e.target.value}))} placeholder='Naziv vozila' className='bg-gray-100 p-2 border mb-2' style={{ width: '33%' }} defaultValue={formData.vozilo} />
            <input onChange={(e) => setFormData((prev) => ({...prev, sasija: e.target.value}))} placeholder='Šasija' className='bg-gray-100 p-2 border mb-2' style={{ width: '33%' }} defaultValue={formData.sasija} />
            <input onChange={(e) => setFormData((prev) => ({...prev, kilometraza: e.target.value}))} placeholder='Kilometraža' type='number-pad' className='bg-gray-100 p-2 border mb-2' style={{ width: '33%' }} defaultValue={formData.kilometraza} />

            <input onChange={(e) => setFormData((prev) => ({...prev, zapremina: e.target.value}))} placeholder='Zapremina' className='bg-gray-100 p-2 border mb-2' style={{ width: '33%' }} defaultValue={formData.zapremina} />
            <input onChange={(e) => setFormData((prev) => ({...prev, snaga: e.target.value}))} placeholder='Snaga' className='bg-gray-100 p-2 border mb-2' style={{ width: '33%' }} defaultValue={formData.snaga} />
            <div className='flex items-center gap-2' style={{ width: '33%' }}>
                <button onClick={() => setFormData((prev) => ({ ...prev, mjenjac: "automatik" }))} className={`bg-gray-100 p-2 border mb-2 w-1/2 ${formData.mjenjac === "automatik" ? 'bg-red-600 text-white' : null}`}>Automatik</button>
                <button onClick={() => setFormData((prev) => ({ ...prev, mjenjac: "manuelni" }))} className={`bg-gray-100 p-2 border mb-2 w-1/2 ${formData.mjenjac === "manuelni" ? 'bg-red-600 text-white' : null}`}>Manuelni</button>
            </div>
            <input onChange={(e) => setFormData((prev) => ({...prev, boja: e.target.value}))} placeholder='Boja' className='bg-gray-100 p-2 border mb-2' style={{ width: '33%' }} defaultValue={formData.boja} />
            <input onChange={(e) => setFormData((prev) => ({...prev, godiste: e.target.value}))} placeholder='Godište' className='bg-gray-100 p-2 border mb-2' style={{ width: '33%' }} defaultValue={formData.godiste} />
            <input onChange={(e) => setFormData((prev) => ({...prev, gorivo: e.target.value}))} placeholder='Gorivo' className='bg-gray-100 p-2 border mb-2' style={{ width: '33%' }} defaultValue={formData.gorivo} />

            <input onChange={(e) => setFormData((prev) => ({...prev, dobavljac: e.target.value}))} placeholder='Dobavljač' className='bg-gray-100 p-2 border mb-2' style={{ width: '33%' }} defaultValue={formData.dobavljac} />
            <input onChange={(e) => setFormData((prev) => ({...prev, regOznake: e.target.value}))} placeholder='Registarske oznake' className='bg-gray-100 p-2 border mb-2' style={{ width: '33%' }} defaultValue={formData.regOznake} />
            <div className='bg-gray-100 p-2 border mb-2 flex items-center justify-between' style={{ width: '33%' }}>
                <h1>Došle uz vozilo</h1>
                <Switch onChange={() => setFormData((prev) => ({...prev, dosleUzVozilo: !formData.dosleUzVozilo}))} className='bg-white' size="default" checked={formData.dosleUzVozilo} />
            </div>
            <div className='bg-gray-100 p-2 border mb-2 flex items-center justify-between' style={{ width: '33%' }}>
                <h1>Vlasnička knjižica</h1>
                <Switch onChange={() => setFormData((prev) => ({...prev, vlasnickaKnjizica: !formData.vlasnickaKnjizica}))} className='bg-white' size="default" checked={formData.vlasnickaKnjizica} />
            </div>
            <div className='bg-gray-100 p-2 border mb-2 flex items-center justify-between' style={{ width: '33%' }}>
                <h1>Saobraćajna dozvola</h1>
                <Switch onChange={() => setFormData((prev) => ({...prev, saobracajnaDozvola: !formData.saobracajnaDozvola}))} className='bg-white' size="default" checked={formData.saobracajnaDozvola} />
            </div>
            <div className='bg-gray-100 p-2 border mb-2 flex items-center justify-between' style={{ width: '33%' }}>
                <h1>Registrovan</h1>
                <Switch onChange={() => setFormData((prev) => ({...prev, registrovan: !formData.registrovan}))} className='bg-white' size="default" checked={formData.registrovan} />
            </div>
            <div className='bg-gray-100 p-2 border mb-2 flex items-center justify-between' style={{ width: '33%' }}>
                <h1>Polisa osiguranja</h1>
                <Switch onChange={() => setFormData((prev) => ({...prev, polisaOsiguranja: !formData.polisaOsiguranja}))} className='bg-white' size="default" checked={formData.polisaOsiguranja} />
            </div>
            <input onChange={(e) => setFormData((prev) => ({ ...prev, brojKljuceva: e.target.value }))} placeholder='Broj ključeva' className='bg-gray-100 p-2 border mb-2' style={{ width: '33%' }} defaultValue={formData.brojKljuceva} />
            <div className='bg-gray-100 p-2 border mb-2 flex items-center justify-between' style={{ width: '33%' }}>
                <h1>3 Ovjerena otkupna ugovora</h1>
                <Switch onChange={() => setFormData((prev) => ({...prev, triOvjerenaOtkupnaUgovora: !formData.triOvjerenaOtkupnaUgovora}))} className='bg-white' size="default" checked={formData.triOvjerenaOtkupnaUgovora} />
            </div>
            <div className='bg-gray-100 p-2 border mb-2 flex items-center justify-between' style={{ width: '33%' }}>
                <h1>Faktura s' fiskalnim računom</h1>
                <Switch onChange={() => setFormData((prev) => ({...prev, fakturaSaFiskalnimRacunom: !formData.fakturaSaFiskalnimRacunom}))} className='bg-white' size="default" checked={formData.fakturaSaFiskalnimRacunom} />
            </div>
            <div className='bg-gray-100 p-2 border mb-2 flex items-center justify-between' style={{ width: '33%' }}>
                <h1>Odluka o prodaji</h1>
                <Switch onChange={() => setFormData((prev) => ({...prev, odlukaOprodaji: !formData.odlukaOprodaji}))} className='bg-white' size="default" checked={formData.odlukaOprodaji} />
            </div>
            <div className='w-full bg-gray-300 mb-2' style={{ height: 1 }}></div>
            <input onChange={(e) => setFormData((prev) => ({...prev, brendGume: e.target.value}))} placeholder='Brend pneumatika' className='bg-gray-100 p-2 border mb-2' style={{ width: '24.5%' }} defaultValue={formData.brendGume} />
            <input onChange={(e) => setFormData((prev) => ({...prev, dimenzijeGume: e.target.value}))} placeholder='Dimenzije pneumatika' className='bg-gray-100 p-2 border mb-2' style={{ width: '24.5%' }} defaultValue={formData.dimenzijeGume} />
            <input onChange={(e) => setFormData((prev) => ({...prev, dot: e.target.value}))} placeholder='DOT' className='bg-gray-100 p-2 border mb-2' style={{ width: '24.5%' }} defaultValue={formData.dot} />
            <input onChange={(e) => setFormData((prev) => ({...prev, tockoviZona: e.target.value}))} placeholder='Sezona pneumatika' className='bg-gray-100 p-2 border mb-2' style={{ width: '24.5%' }} defaultValue={formData.tockoviZona} />
            <div className='w-full flex items-center justify-between py-1 mb-2 bg-gray-50 border-b'>
                <h3>Drugi set pneumatika</h3>
                <Switch onChange={() => setFormData((prev) => ({...prev, drugeGume: !formData.drugeGume}))} className='bg-white' size="default" checked={formData.drugeGume} />
            </div>

            <input onChange={(e) => setFormData((prev) => ({...prev, brendDrugeGume: e.target.value}))} placeholder='Brend drugi set pneumatika' className='bg-gray-100 p-2 border mb-2' style={{ width: '24.5%' }} defaultValue={formData.brendDrugeGume} />
            <input onChange={(e) => setFormData((prev) => ({...prev, dimenzijeDrugeGume: e.target.value}))} placeholder='Dimenzije drugi set pneumatika' className='bg-gray-100 p-2 border mb-2' style={{ width: '24.5%' }} defaultValue={formData.dimenzijeDrugeGume} />
            <input onChange={(e) => setFormData((prev) => ({...prev, dotDrugeGume: e.target.value}))} placeholder='DOT drugi set pneumatika' className='bg-gray-100 p-2 border mb-2' style={{ width: '24.5%' }} defaultValue={formData.dotDrugeGume} />
            <input onChange={(e) => setFormData((prev) => ({...prev, tockoviZonaDrugeGume: e.target.value}))} placeholder='Sezona drugi set pneumatika' className='bg-gray-100 p-2 border mb-2' style={{ width: '24.5%' }} defaultValue={formData.tockoviZonaDrugeGume} />
            <div className='w-full bg-gray-300 mb-2' style={{ height: 1 }}></div>
            <div className='bg-gray-100 p-2 border mb-2 flex items-center justify-between' style={{ width: '33%' }}>
                <h1>Servnisna knjižica</h1>
                <Switch onChange={() => setFormData((prev) => ({...prev, servisnaKnjizica: !formData.servisnaKnjizica}))} className='bg-white' size="default" checked={formData.servisnaKnjizica} />
            </div>
            <div className='bg-gray-100 p-2 border mb-2 flex items-center justify-between' style={{ width: '33%' }}>
                <h1>Veliki servis</h1>
                <Switch onChange={() => setFormData((prev) => ({...prev, velikiServis: !formData.velikiServis}))} className='bg-white' size="default" checked={formData.velikiServis} />
            </div>
            <input onChange={(e) => setFormData((prev) => ({...prev, velikiServisKm: e.target.value}))} placeholder='Veliki servis KM' className='bg-gray-100 p-2 border mb-2' style={{ width: '33%' }} defaultValue={formData.velikiServisKm} />
            <div className='bg-gray-100 p-2 border mb-2 flex items-center justify-between' style={{ width: '33%' }}>
                <h1>Mali servis</h1>
                <Switch onChange={() => setFormData((prev) => ({...prev, maliServis: !formData.maliServis}))} className='bg-white' size="default" checked={formData.maliServis} />
            </div>
            <input onChange={(e) => setFormData((prev) => ({...prev, maliServisKm: e.target.value}))} placeholder='Mali servis KM' className='bg-gray-100 p-2 border mb-2' style={{ width: '33%' }} defaultValue={formData.maliServisKm} />
            <div className='bg-gray-100 p-2 border mb-2 flex items-center justify-between' style={{ width: '33%' }}>
                <h1>Rezervni točak</h1>
                <Switch onChange={() => setFormData((prev) => ({...prev, rezervniTocak: !formData.rezervniTocak}))} className='bg-white' size="default" checked={formData.rezervniTocak} />
            </div>
            <div className='bg-gray-100 p-2 border mb-2 flex items-center justify-between' style={{ width: '33%' }}>
                <h1>Dizalica + ključ</h1>
                <Switch onChange={() => setFormData((prev) => ({...prev, dizalicaPlusKljuc: !formData.dizalicaPlusKljuc}))} className='bg-white' size="default" checked={formData.dizalicaPlusKljuc} />
            </div>
            <div className='bg-gray-100 p-2 border mb-2 flex items-center justify-between' style={{ width: '33%' }}>
                <h1>Prva pomoć</h1>
                <Switch onChange={() => setFormData((prev) => ({...prev, prvaPomoc: !formData.prvaPomoc}))} className='bg-white' size="default" checked={formData.prvaPomoc} />
            </div>
            <div className='bg-gray-100 p-2 border mb-2 flex items-center justify-between' style={{ width: '33%' }}>
                <h1>Kuka</h1>
                <Switch onChange={() => setFormData((prev) => ({...prev, kuka: !formData.kuka}))} className='bg-white' size="default" checked={formData.kuka} />
            </div>
            <div className='bg-gray-100 p-2 border mb-2 flex items-center justify-between' style={{ width: '33%' }}>
                <h1>Lopov šarafi</h1>
                <Switch onChange={() => setFormData((prev) => ({...prev, lopovSarafi: !formData.lopovSarafi}))} className='bg-white' size="default" checked={formData.lopovSarafi} />
            </div>
            <div className='bg-gray-100 p-2 border mb-2 flex items-center justify-between' style={{ width: '33%' }}>
                <h1>Lopov nastavak</h1>
                <Switch onChange={() => setFormData((prev) => ({...prev, lopovNastavak: !formData.lopovNastavak}))} className='bg-white' size="default" checked={formData.lopovNastavak} />
            </div>
            <div className='bg-gray-100 p-2 border mb-2 flex items-center justify-between' style={{ width: '33%' }}>
                <h1>Polica u gepeku</h1>
                <Switch onChange={() => setFormData((prev) => ({...prev, policaUgepeku: !formData.policaUgepeku}))} className='bg-white' size="default" checked={formData.policaUgepeku} />
            </div>
            <input onChange={(e) => setFormData((prev) => ({...prev, alarmVrstaAlarmaLokacija: e.target.value}))} placeholder='Alarm (vrsta, lokacija)' className='bg-gray-100 p-2 border mb-2' style={{ width: '33%' }} defaultValue={formData.alarmVrstaAlarmaLokacija} />
            <input onChange={(e) => setFormData((prev) => ({...prev, potpisStranke: e.target.value}))} placeholder='Potpis stranke' className='bg-gray-100 p-2 border mb-2' style={{ width: '33%' }} defaultValue={formData.potpisStranke} />
            <div className='w-full bg-gray-300 mb-2' style={{ height: 1 }}></div>
            <div className='flex items-center flex-wrap justify-between gap-1.5 w-full'>
                {
                    formData.potrebniRadovi ?
                        formData.potrebniRadovi.map((rad) => {
                            return (
                                <li className='p-2 bg-gray-100 list-decimal flex items-center flex items-center gap-2' style={{ width: '49.8%', minWidth: '49.8%', height: 45 }}>{rad}
                                    <button onClick={() => removeTask(rad)}>
                                        <Trash />
                                    </button></li>
                            )
                        }) : null
                }
                <button onClick={() => isModalVisible ? handleAddText() : setIsModalVisible(true)} className='border-2 border-dashed border-b-2 hover:border-red-600 hover:text-red-600 flex items-center p-2 gap-2' style={{ width: '49.8%', minWidth: '49.8%', height: 45 }}><PlusCircle size={20} /> {isModalVisible ? 'Potvrdi' : 'Dodaj zadatak'}</button>
                {
                    isModalVisible ? <input type="text" placeholder='Unesite zadatak' className='p-2 bg-gray-100 list-decimal flex items-center' style={{ width: '49.8%', minWidth: '49.8%', height: 45 }} onChange={(e) => setTextInputValue(e.target.value)} value={textInputValue} /> : null
                }
            </div>
            <button onClick={applyChanges} className='w-full p-3 text-white mt-5' style={{ backgroundColor: '#d22829' }}>Promjeni prijem</button>
        </div>
    ) : <p>Loading...</p>
}

export default EditPrijem