import React, { useRef, useState } from 'react'
import { Check, Ban } from 'lucide-react'
import { CiCircleRemove } from 'react-icons/ci'
import axios from 'axios'
import io from 'socket.io-client';
import { Image, Space, message } from 'antd'
import {
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons';
import ReactToPrint from "react-to-print";
import { Link } from 'react-router-dom';

const socket = io("https://app.arenabackend.org", {
    reconnection: true
})

function ProcjenaModal({ data, closeModal }) {

    const [files, setFiles] = useState([])
    const jsxContentRef = useRef(null);
    const [messageApi, contextHolder] = message.useMessage();


    const formatLabel = (key) => {
        return key
            .replace(/([A-Z])/g, ' $1') // Razdvoji camelCase riječi
            .replace(/^./, str => str.toUpperCase()) // Prvo slovo postavi na veliko
            .trim(); // Ukloni eventualne početne ili završne razmake
    };
    
    const renderCheckboxes = () => {
        return Object.keys(data).map((key) => {
            if (typeof data[key] === 'boolean') {
                // Generiraj checkbox input za boolean vrijednosti
                return (
                    <h3 style={{ minWidth: 'fit-content' }} className='text-sm p-3 flex items-center gap-2 flex-row w-1/2'>{formatLabel(key)} {data[key] === true ? <Check size={17} color='#51d12e' /> : <Ban size={17} color='#d12e2e' />}</h3>
                )
            } else if (key === 'images') {
                // Generiraj file input za 'images'
                return null
            } else if (key === '__v') {
                // Generiraj file input za 'images'
                return null
            } else if (key === '_id') {
                // Generiraj file input za 'images'
                return null
            } else {
                return null
            }
        });
    };

    const renderInputs = () => {
        return Object.keys(data).map((key) => {
            if (['_id', 'createdAt', 'images', '__v', 'zamjenaZaVozilo', 'vozilo', 'sasija', 'kilometraza', 'godiste', 'brojKljuceva'].includes(key)) {
                // Izostavi ove ključeve
                return null;
            } else if (typeof data[key] !== 'boolean') {
                // Generiraj tekstualni input za ne-boolean vrijednosti
                return (
                    <h1 key={key} className='text-sm uppercase w-1/2 p-3'>
                        {formatLabel(key)} <strong>{data[key]}</strong>
                    </h1>
                );
            } else {
               return null
            }
        });
    };

    function formatDate(date) {
        if (!date) return "";
        // Dohvati dan, mjesec i godinu
        const day = date.getDate();
        const month = date.getMonth() + 1; // Mjeseci počinju od 0, pa se dodaje 1
        const year = date.getFullYear();

        // Formatiraj datum
        return `${day}.${month}.${year}`;
    }

    const handleDelete = async (procjenaId) => {
        try {

            const response = await axios.delete(`https://app.arenabackend.org/procjene/obrisi-procjenu/${procjenaId}`);

            // Handle the response as needed
            messageApi.open({
                type: response.data.status,
                content: response.data.message,
            });
            closeModal()
        } catch (e) {
            // Handle errors
            console.error(e.error);
        }
    }

    const chooseFilesRef = useRef(null);

    const handleClick = () => {
        chooseFilesRef.current.click();
    };

    const handleChange = (event) => {
        setFiles(event.target.files);
    };

    const handleSubmitImages = async (event) => {
        event.preventDefault();

        if (files.length === 0) {
            alert('Please select a date and at least one file.');
            return;
        }

        const formData = new FormData();

        Array.from(files).forEach((file) => {
            formData.append('images', file);
        });

        try {
            const response = await fetch(`https://app.arenabackend.org/procjene/postavi-slike/${data._id}`, {
                method: 'POST',
                body: formData,
            });

            const result = await response.json();
            if (response.ok) {
                alert('Images uploaded successfully!');
            } else {
                alert(`Error: ${result.error}`);
            }
        } catch (error) {
            alert('Error uploading images');
            console.error('Error:', error);
        }
    };

    return (
        <div className="procjena-modal w-full h-full absolute top-0 left-0 flex items-center justify-center" style={{ backgroundColor: 'rgba(20,20,20,0.5)' }}>
            {contextHolder}
            <button onClick={closeModal} className='absolute top-3 right-3 text-white'><CiCircleRemove size={35} /></button>
            <div className='flex flex-col items-center gap-3' style={{ height: '85%' }}>
                <ReactToPrint
                    documentTitle={`${data.vozilo} | ${data.sasija} - Arena Motors`}
                    bodyClass="print-agreement"
                    content={() => jsxContentRef.current}
                    trigger={() => (
                        <button className='p-3 text-white w-2/4 rounded-full' style={{ backgroundColor: '#d22829', height: 45 }}>Preuzmi PDF</button>
                    )}
                />
                <div className='w-1/2 h-5/6'> 
                    <div ref={jsxContentRef} className={`rounded-lg w-full h-full shadow-xl shadow-gray-400 bg-white`} style={{ overflow: 'auto', borderStyle: 'dashed' }}>
                        <div className='flex flex-wrap'>
                            <div className='w-full bg-black text-white flex flex-wrap'>
                                <h1 className='text-sm uppercase w-1/2 p-3'>
                                    Vozilo <strong>{data.vozilo}</strong>
                                </h1>
                                <h1 className='text-sm uppercase w-1/2 p-3'>
                                    Zamjena za vozilo <strong>{data.zamjenaZaVozilo}</strong>
                                </h1>
                                <h1 className='text-sm uppercase w-1/2 p-3'>
                                    Šasija <strong>{data.sasija}</strong>
                                </h1>
                                <h1 className='text-sm uppercase w-1/2 p-3'>
                                    Kilometraža <strong>{data.kilometraza}</strong>
                                </h1>
                                <h1 className='text-sm uppercase w-1/2 p-3'>
                                    Godište <strong>{data.godiste}</strong>
                                </h1>
                                <h1 className='text-sm uppercase w-1/2 p-3'>
                                    Broj Ključeva <strong>{data.brojKljuceva}</strong>
                                </h1>
                                <h1 className='text-sm uppercase w-full p-3'>
                                    Datum procjene <strong>{formatDate(new Date(data.createdAt))}</strong>
                                </h1>
                            </div>
                            {renderInputs()}
                            <div className='mx-3 w-full bg-gray-300' style={{ height: 1 }}></div>
                            {renderCheckboxes()}
                            <div className='flex flex-wrap p-3 w-full' style={{ gap: 10 }}>
                                {
                                    data.images.length > 0 ?
                                        data.images.map((image, i) => {
                                            return (
                                                <div className='flex items-center justify-center' style={{ width: 'calc(100% / 3 - 7px)', overflow: 'hidden' }}>
                                                    <Image
                                                        placeholder="Pogledaj"
                                                        key={i}
                                                        className='flex items-center justify-center'
                                                        style={{ width: '100%', height: 'auto', minWidth: 300, maxWidth: 500 }}
                                                        src={image ? image : 'https://i0.wp.com/ourscene.org/wp-content/uploads/2022/01/placeholder-2.png?fit=1200%2C800&ssl=1'}
                                                        preview={{
                                                            toolbarRender: (
                                                                _,
                                                                {
                                                                    transform: { scale },
                                                                    actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                                                                },
                                                            ) => (
                                                                <Space size={12} className="toolbar-wrapper">
                                                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                </Space>
                                                            ),
                                                        }}
                                                    />
                                                </div>
                                            )
                                        }) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex w-1/2 gap-3 items-center justify-between'>
                    <Link className='p-3 text-white w-1/3 rounded-full text-center' style={{ backgroundColor: '#156adc', height: 45 }} to={`/user/logged-in/edit-procjena/${data._id}`}>Uredi procjenu</Link>
                    <button className='p-3 text-white w-1/3 rounded-full text-center' style={{ backgroundColor: '#8215dc', height: 45 }} onClick={handleClick}>Postavi slike</button>
                    <input ref={chooseFilesRef} type="file" name="files" multiple className='' hidden required onChange={handleChange} />
                    <button onClick={() => handleDelete(data._id)} className='p-3 text-white w-full rounded-full text-center' style={{ backgroundColor: '#d22829', height: 45 }}>Obriši procjenu</button>            
                </div>
                {
                    files.length > 0 ? (
                        <button onClick={handleSubmitImages} className='p-3 text-white w-1/2 rounded-full text-center' style={{ backgroundColor: '#d22829', height: 45 }}>Uploaduj slike</button>
                    ) : null
                }
            </div>        
        </div>
    )
}

export default ProcjenaModal