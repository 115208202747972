import React from 'react'
import UserTasks from '../../componets/UserTasks'
import UserMessages from '../../componets/UserMessages'
import VehcilesStats from '../../componets/VehcilesStats'
import ArenaMotorsTasks from '../../componets/ArenaMotorsTasks'
import Sidebar, { SidebarItem } from '../../layout/Sidebar'
import { LayoutDashboard } from 'lucide-react'

function Dashboard() {


  return (
    <div>
      <h1 className='m-3 pb-3 border-b font-medium'>Kontrolna tabla</h1>
      <section className="user-data px-3 pb-3 flex flex-wrap" style={{ gap: 20 }}>
        <UserTasks />
        <UserMessages />
        <VehcilesStats />
        <ArenaMotorsTasks />
      </section>
    </div>
  )
}

export default Dashboard