import React, { useRef, useState } from 'react'
import { useAppData } from '../../context/ApplicationProvider';
import { DownloadTableExcel, useDownloadExcel } from 'react-export-table-to-excel';
import TaskTable from '../../componets/TabelaZadataka';
import io from 'socket.io-client';





const socket = io("https://app.arenabackend.org", {
    reconnection: true
})

function AdminKorisnici() {
    const { zadaci, users, sections, currentUser, fetchZadaci } = useAppData();
    const [intervalId, setIntervalId] = useState(null);


    const [ selectedUser, setSelectedUser ] = useState("")

    const tableRef = useRef(null);

    const {onDownload} = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'zadaci_izvjestaj',
        sheet: "Izvještaj zadataka"
    })


    return (
        <div className='p-3'>
            <div className='flex items-center justify-between pb-3'>
                <h1 className='text-xl'>Tabela korisnika</h1>
                <button onClick={onDownload} className='py-2 px-4 text-sm text-white border' style={{ backgroundColor: '#d22829' }}>Preuzmite statistiku zadataka</button>
            </div>
            <div className="">
                <table ref={tableRef} className='arena-table w-full border-t border-x'>
                    <thead className=''>
                        <tr className='border-b bg-black text-white'>
                            <th className='text-left p-3 font-normal text-sm border-r'>ID</th>
                            <th className='text-left p-3 font-normal text-sm border-r'>Mail</th>
                            <th className='text-left p-3 font-normal text-sm border-r'>Ime i Prezime</th>
                            <th className='text-left p-3 font-normal bg-green-600 text-sm border-r'>Broj aktivnih zadataka</th>
                            <th className='text-left p-3 font-normal bg-blue-300 text-black text-sm border-r'>Broj kreiranih zadataka</th>
                            <th className='text-left p-3 font-normal bg-red-600 text-sm'>Broj završenih zadataka</th>
                            <th className='text-left p-3 font-normal bg-red-600 text-sm'>Samostalnih zadataka</th>
                        </tr>
                    </thead>
                    <tbody className=''>
                        {
                            users.map((user) => {
                                return (
                                    <tr onClick={() => setSelectedUser(user._id)} key={user._id} className={`cursor-pointer border-b hover:bg-gray-100 transition ease-linear duration-300 ${selectedUser === user._id ? 'text-red-600' : null}`}>
                                        <td className='text-left p-3 border-r'>{user._id}</td>
                                        <td className='text-left p-3 border-r'>{user.email}</td>
                                        <td className='text-left p-3 border-r'>{user.name}</td>
                                        <td className='text-left p-3 border-r'>{zadaci.filter((zadatak) => zadatak.user === user._id && zadatak.status !== "Završeno").length}</td>
                                        <td className='text-left p-3 border-r'>{zadaci.filter((zadatak) => zadatak.creator === user._id).length}</td>
                                        <td className='text-left p-3'>
                                            {zadaci.filter((zadatak) =>
                                                zadatak.finishedBy === user._id && 
                                                    zadatak.users ? zadatak.users.includes(user._id) : zadatak.user === user._id && 
                                                zadatak.status === 'Završeno'
                                            ).length}
                                        </td>
                                        <td className='text-left p-3'>{zadaci.filter((zadatak) => zadatak.finishedBy === user._id && zadatak.creator === user._id && zadatak.status === 'Završeno').length}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            {
                selectedUser ? (
                    <div className="selected-user-container border mt-5 p-3">
                        {
                            users.filter((user) => user._id === selectedUser).map((user) => {
                                const nameParts = user.name.split(' ');

                                // Uzimanje inicjala
                                const initials = nameParts.map(part => part.charAt(0)).join('');

                                // racunanje prosjecnog vremena za zavrsenje zadatka
                                const vrijemeProsjecno = () => {
                                    // Filtriranje zadataka
                                    const filteredTasks = zadaci.filter((zadatak) => zadatak.finishedBy === selectedUser && zadatak.endedAt);

                                    if (filteredTasks.length > 0) {
                                        // Racunanje vremena za sve zadatke
                                        const totalTime = filteredTasks.reduce((acc, zadatak) => {
                                            const startDate = new Date(zadatak.createdAt);
                                            const endDate = new Date(zadatak.endedAt);
                                            const timeDiff = endDate.getTime() - startDate.getTime();
                                            return acc + timeDiff;
                                        }, 0);

                                        // Racunanje prosjecnog vremena
                                        const averageTime = totalTime / filteredTasks.length;

                                        // Racunanje dana, sati i minuta
                                        const days = Math.floor(averageTime / (1000 * 60 * 60 * 24));
                                        const hours = Math.floor((averageTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                                        const minutes = Math.floor((averageTime % (1000 * 60 * 60)) / (1000 * 60));

                                        // Formatiranje vremena
                                        const formattedAverageTime = `${days} dana ${hours} sati i ${minutes} minuta`;

                                        return formattedAverageTime;
                                    } else {
                                        return "Nisu pronadjeni zadaci za korisnika.";
                                    }
                                };

                                const handleNotifyUser = () => {
                                    if (!intervalId) {
                                        const id = setInterval(() => {
                                            socket.emit('pocniteSaIspunjavanjem', user._id);
                                        }, 1500); // Emit every 2 seconds
                                        setIntervalId(id);
                                    }
                                };

                                const handleStopClick = () => {
                                    clearInterval(intervalId);
                                    setIntervalId(null);
                                };

                                
                                return (
                                    <>
                                        <div className='selectedUser-header flex items-center justify-between'>
                                            <div className='user-header flex items-center gap-2'>
                                                <div className='text-white rounded-full flex items-center justify-center' style={{ height: 40, width: 40, backgroundColor: '#d22829' }}>
                                                    <h1>{initials}</h1>
                                                </div>
                                                <h1>{user.name}</h1>
                                            </div>
                                            <div className='flex items-center gap-2'>
                                                <button onClick={intervalId ? handleStopClick : handleNotifyUser} className='text-white text-xs p-2' style={{ backgroundColor: '#d22829' }}>{intervalId ? 'Prestanite sa slanjem notifikacija' : 'Obavjesti korisnika da ispunjava zadatke'}</button>
                                                <a className='text-white text-xs p-2' style={{ backgroundColor: '#d22829' }} href={`mailto:${user.email}`}>Kontakt: {user.email}</a>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-between gap-3 mt-3'>
                                            <h1 className='p-3 w-1/2 bg-gray-100 border-b-2 border-b-green-600'>Aktivnih zadataka: {zadaci.filter((zadatak) => zadatak.user === user._id && zadatak.status !== "Završeno").length}</h1>
                                            <h1 className='p-3 w-1/2 bg-gray-100 border-b-2 border-b-blue-300'>Kreiranih zadataka: {zadaci.filter((zadatak) => zadatak.creator === user._id).length}</h1>
                                            <h1 className='p-3 w-1/2 bg-gray-100 border-b-2 border-b-red-600'>Završenih zadataka: {zadaci.filter((zadatak) => zadatak.user === user._id && zadatak.status === "Završeno").length}</h1>
                                        </div>
                                        <div className='mt-3'>
                                            <h1 className=''>Prosječno vrijeme za ispunjenje zadatka: <strong>{vrijemeProsjecno()}</strong></h1>
                                        </div>
                                        <TaskTable zadaci={zadaci} selectedUser={selectedUser} />
                                    </>
                                )
                            })
                        }
                    </div>
                ) : null
            }

        </div>
    )
}

export default AdminKorisnici