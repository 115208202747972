import { message } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { CiCircleCheck, CiCircleRemove } from "react-icons/ci";
import ProcjenaModal from '../../componets/modals/ProcjenaModal';
import { read, utils, writeFile } from 'xlsx';
import io from 'socket.io-client';
import axios from 'axios';
const socket = io("https://app.arenabackend.org", {
    reconnection: true
})


function ProcjenePage() {

    const [procjene, setProcjene] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [activeProcjena, setActiveProcjena] = useState({
        status: null,
        id: ''
    })
    
    const fetchProcjene = async () => {
        await fetch("https://app.arenabackend.org/procjene/sve-procjene")
            .then((res) => res.json())
            .then((response) => {
                messageApi.open({
                    type: 'success',
                    content: 'Uspiješno ste povukli podatke iz baze',
                });
                setProcjene(response)
            })
            .catch((e) => {
                messageApi.open({
                    type: 'error',
                    content: 'Greska prilikom izvlacenja podataka',
                });
            })
    }

    useEffect(() => {
        fetchProcjene();
        socket.on('prihvacenaProcjena', (data) => {
            fetchProcjene();
        })
        socket.on('odbijenaProcjena', (data) => {
            fetchProcjene();
        })
    }, [])

    const closeModal = () => {
        setActiveProcjena({
            status: false,
            id: ''
        });
    };

    function formatDate(date) {
        if (!date) return "";
        // Dohvati dan, mjesec i godinu
        const day = date.getDate();
        const month = date.getMonth() + 1; // Mjeseci počinju od 0, pa se dodaje 1
        const year = date.getFullYear();

        // Dohvati sate i minute
        const hours = date.getHours();
        const minutes = date.getMinutes();

        // Formatiraj datum
        return `${day}.${month}.${year} u ${hours}h i ${minutes}min`;
    }


    return (
        <div className='h-full'>
            {
                activeProcjena.status ? <ProcjenaModal data={procjene.filter((procjena) => procjena._id.trim() === activeProcjena.id.trim())[0]} closeModal={closeModal} /> : null
            }
            {contextHolder}
            <div className='flex items-center justify-between p-3 bg-gray-100 shadow-lg'>
                <h1 className='text-sm uppercase font-semibold'>Procjene vozila</h1>
                <Link className='bg-red-500 text-white px-3 py-2 text-xs rounded border' to='/user/logged-in/zapisnici/kreiraj-procjenu'>Kreiraj procjenu</Link>
            </div>
            <div className='m-3 pb-3'>
                <table className='w-full border-x border-t'>
                    <thead className='w-full'>
                        <tr className='bg-gray-100 w-full border-b'>
                            <th className='font-semibold p-3 text-sm text-left border-r'>Vozilo</th>
                            <th className='font-semibold p-3 text-sm text-left border-r'>Broj šasije</th>
                            <th className='font-semibold p-3 text-sm text-left border-r'>Kilometraža</th>
                            <th className='font-semibold p-3 text-sm text-left border-r'>Ulaganje</th>
                            <th className='font-semibold p-3 text-sm text-left border-r'>Datum izvjestaja</th>
                            <th className='font-semibold p-3 text-sm text-left'>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            procjene.map((procjena, i) => {
                                const calcUkupnoUlaganje = procjena.motorImjenjacUlaganja + procjena.trapUlaganja + procjena.limarijaUlaganja + procjena.lakiranjeUlaganja + procjena.poliranjeUlaganja + procjena.dubinskoUlaganja + procjena.presvlacenjeUlaganja + procjena.felgeIgumeUlaganja + procjena.ostalaUlaganja
                                return (
                                    <tr key={i} onClick={() => {
                                        setActiveProcjena((prev) => ({ ...prev, status: true }))
                                        setActiveProcjena((prev) => ({ ...prev, id: procjena._id }))
                                    }} className='border-b hover:bg-gray-100 cursor-pointer'>
                                        <td className='font-normal p-3 text-sm text-left border-r'>{procjena.vozilo}</td>
                                        <td className='font-normal p-3 text-sm text-left border-r'>{procjena.sasija}</td>
                                        <td className='font-normal p-3 text-sm text-left border-r'>{procjena.kilometraza ? procjena.kilometraza.toLocaleString(void 0, { maximumFractionDigits: 2 }).replace(',', '.') : 0}km</td>
                                        <td className='font-normal p-3 text-sm text-left border-r'>{calcUkupnoUlaganje.toLocaleString(void 0, { maximumFractionDigits: 2 }).replace(',', '.') || 0} KM</td>
                                        <td className='font-normal p-3 text-sm text-left border-r'>{formatDate(new Date(procjena.createdAt))}</td>
                                        <td className='font-normal p-3 text-sm text-left'>{procjena.isAllowed === false ? (
                                            <span className='text-xs uppercase flex items-center gap-1'><CiCircleRemove size={15} color='red' /> ODBIJENO</span>
                                        ) : (
                                            <span className='text-xs uppercase flex items-center gap-1'><CiCircleCheck size={15} color='green' /> PRIHVACENO</span>
                                        )}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ProcjenePage