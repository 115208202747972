import { Avatar, Input, Modal, Select, Space, Table, message } from 'antd'
import Column from 'antd/es/table/Column'
import React, { useEffect, useState } from 'react'
import { useAppData } from '../../context/ApplicationProvider'
import { Link } from 'react-router-dom'
import { Settings2, Trash } from 'lucide-react'
import axios from 'axios'
import io from 'socket.io-client';
const socket = io("https://app.arenabackend.org", {
    reconnection: true
})


function Sektori() {

    const [data, setData] = useState([])
    const { users, zadaci, currentUser } = useAppData();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSektorModalOpen, setIsSektorModalOpen] = useState(false);
    const [activeSektor, setActiveSektor] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();

    const [title, setTitle] = useState('');
    const [userIds, setUserIds] = useState([]);
    const [creatorId, setCreatorId] = useState(currentUser._id);

    const handleUserChange = (selectedUserIds) => {
        setSelectedUsers(selectedUserIds);
    };

    const handleSektorUserChange = (selectedUserIds) => {
        setUserIds(selectedUserIds);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const fetchSektori = async () => {
        await fetch('https://app.arenabackend.org/administration/svi-sektori').then((res) => res.json()).then((response) => setData(response)).catch((e) => console.log(e))
    }

    useEffect(() => {
        fetchSektori()
    }, [])

    const randomColor = function () {
        const hex = '0123456789ABCDEF'; //hex colors range
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += hex[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const handleUserSelection = async (selectedUserIds) => {
        try {

            const response = await axios.post(`https://app.arenabackend.org/administration/add-users-to-sektor/${activeSektor}`, {
                userIds: selectedUsers.map((user) => ({ _id: user }))
            });

            // Handle the response as needed
            messageApi.open({
                type: response.data.status,
                content: response.data.message,
            });
            socket.emit('dodaniKorisnici', `Dodati su novi korisnici u ${data.filter((data) => data._id.trim() === activeSektor).map((sektor) => sektor.title)}`, { content: selectedUsers })
        } catch (e) {
            // Handle errors
            console.error(e.error);
        }
    };

    const handleDelete = async (userId) => {
        try {

            const response = await axios.delete(`https://app.arenabackend.org/administration/remove-user-from-sector/${activeSektor}/${userId}`);

            // Handle the response as needed
            messageApi.open({
                type: response.data.status,
                content: response.data.message,
            });
            fetchSektori()
        } catch (e) {
            // Handle errors
            console.error(e.error);
        }
    }


    const createSektorshowModal = () => {
        setIsSektorModalOpen(true);
    };

    const closeModal = () => {
        setIsSektorModalOpen(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const sektorData = {
            title,
            userIds,
            creatorId,
        };

        try {
            const response = await fetch('https://app.arenabackend.org/administration/kreiraj-sektor', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(sektorData),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            messageApi.open({
                type: 'success',
                content: 'Uspijesno kreiran sektor',
            });
            fetchSektori()
            // Handle success scenario, like redirecting or showing a success message
        } catch (error) {
            console.error('Failed to create sektor:', error);
            // Handle failure scenario
        }
    };

    const deleteSektor = async (id) => {
        await axios.delete(`https://app.arenabackend.org/administration/obrisi-sektor/${id}`).then((res) => {
            messageApi.open({
                type: 'success',
                content: 'Uspiješno obrisan sektor',
            });
            fetchSektori()
        }).catch((e) => alert(e))
    }

    return (
        <div className='px-3 pb-3'>
            {contextHolder}
            <h1 className='text-center text-xl uppercase font-semibold py-5'>Sektori</h1>
            <Modal open={activeSektor} onOk={handleOk} okType='dashed' okText='Potvrdi' cancelText='Zatvori' onCancel={() => setActiveSektor('')}>
                {data.filter((data) => data._id.trim() === activeSektor).map((data, i) => {
                    return (
                        <div key={i}>
                            <h1 className='text-xl font-semibold pb-2'>{data.title}</h1>
                            <h1 className='text-xs uppercase'>Kreator: <strong>{users.filter((user) => user._id.trim() === data.creator.trim()).map((user) => user.name)}</strong></h1>
                            <div className="list-of-users flex flex-col gap-2 mt-5">
                                <div className='flex items-center justify-between'>
                                    <h3 className='font-semibold text-sm uppercase'>Korisnici</h3>
                                    <button onClick={showModal} className='text-blue-500 uppercase text-xs'>Dodaj korisnika</button>
                                </div>
                                {
                                    data.users.map((user, ic) => {
                                        return (
                                            <div key={ic} className='w-full p-2 bg-gray-50 rounded border flex items-center justify-between'>
                                                <div className='flex items-center gap-1'>
                                                    <span className='font-bold text-sm'>{ic + 1}.</span>
                                                    <h1>{users.filter((filteruser) => filteruser._id.trim() === user.trim()).map((filteruser) => filteruser.name)}</h1>
                                                </div>
                                                <div className='flex items-center gap-2'>
                                                    <button style={{ width: 25, height: 25 }} className='hover:bg-gray-200 flex items-center justify-center rounded'><Settings2 size={15} /></button>
                                                    <button onClick={() => handleDelete(user)} style={{ width: 25, height: 25 }} className='hover:bg-red-200 flex items-center justify-center rounded'><Trash size={15} /></button>
                                                </div>

                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="space-line w-full bg-gray-200 my-3" style={{ height: 1 }}></div>
                            <div className=''>
                                <div className='flex items-center justify-between'>
                                    <h3 className='font-semibold text-sm uppercase'>Zadaci</h3>
                                    <button className='text-blue-500 uppercase text-xs'>Dodaj zadatke</button>
                                </div>
                                <div className='flex flex-col gap-2 mt-2'>
                                    {
                                        zadaci.filter((zadatak) => zadatak.sektor.trim() === data._id.trim()).map((zadatak, iz) => {
                                            return (
                                                <div key={iz} className='w-full p-2 bg-gray-50 rounded border flex items-center justify-between'>
                                                    <div className='flex items-center gap-1'>
                                                        <span className='font-bold text-xs'>{iz + 1}.</span>
                                                        <h3 className='text-sm'>{zadatak.title}</h3>
                                                    </div>
                                                    <button style={{ width: 25, height: 25 }} className='hover:bg-gray-200 flex items-center justify-center rounded'><Settings2 size={15} /></button>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Modal>
            <Modal open={isModalOpen} okText='Dodaj korisnike' okType='default' onOk={handleUserSelection} onCancel={() => setIsModalOpen(null)} >
                <Select
                    mode="multiple"
                    placeholder="Izaberi korisnike"
                    value={selectedUsers}
                    onChange={handleUserChange}
                    style={{ width: '100%' }}
                    options={users.map((user) => {
                        return {
                            label: user.name,
                            value: user._id
                        };
                    })}
                >
                </Select>

            </Modal>
            <Table dataSource={data}>
                <Column className='w-1/3' title="Sektor" dataIndex="title" key="title" />
                <Column className='w-1/3' title="Korisnici" dataIndex="users" key="users" render={(_, record) => (
                    <Avatar.Group maxCount={5} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}>
                        {
                            record.users.map((user, i) => {
                                return (
                                    <Avatar key={i} style={{ backgroundColor: `${randomColor()}` }} className='font-bold'>
                                        {users.filter((filteruser) => filteruser._id.trim() === user.trim()).map((filteruser) => filteruser.name.charAt(0))}
                                    </Avatar>
                                )
                            })
                        }
                    </Avatar.Group>
                )} />
                <Column className='w-1/3' title="Upravljanje" key="action" render={(_, record) => (
                    <Space size="middle">
                        <button key={1} onClick={() => setActiveSektor(record._id)} className='text-green-400 hover:text-green-600 text-xs uppercase'>Pogledaj</button>
                        <button onClick={() => deleteSektor(record._id)} key={2} className='text-red-400 hover:text-red-600 text-xs uppercase'>Izbriši</button>
                    </Space>
                )}
                />
            </Table>
            {
                currentUser.role === "administrator" ? (
                    <button onClick={createSektorshowModal} className='p-3 rounded-full text-white text-xs uppercase absolute bottom-3 right-3' style={{ backgroundColor: '#C93030', minWidth: 150 }}>Kreiraj sektor</button>
                ) : null
            }
            <Modal open={isSektorModalOpen} onOk={handleSubmit} onCancel={closeModal} okType='dashed' okText='Potvrdi' cancelText='Zatvori'>
                <h1 className='text-xl font-bold mb-4'>Kreiraj sektor</h1>
                <Input onChange={(e) => setTitle(e.target.value)} placeholder='Unesite naziv sektora' />
                <Select
                    className='mt-2'
                    mode="multiple"
                    placeholder="Izaberi korisnike"
                    value={userIds}
                    onChange={handleSektorUserChange}
                    style={{ width: '100%' }}
                    options={users.map((user) => {
                        return {
                            label: user.name,
                            value: user._id
                        };
                    })}
                >
                </Select>
            </Modal>

        </div>
    )
}

export default Sektori