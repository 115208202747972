
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



function CarForm({ formData, setState, state }) {

    const [value, setValue] = useState('');

    const [brands, setBrands] = useState([])

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ];

    const fetchBrands = async () => {
        await fetch('https://api.arenabackend.org/cars/all-brands', {
            method: 'GET',
        }).then((res) => res.json()).then((response) => setBrands(response))
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }));
    };


    useEffect(() => {
        fetchBrands()
    }, [])


    return (
        <div className="car-form-container mt-3">
            <div className='flex items-start gap-3 justify-between'>
                <div className="main-details bg-gray-100 w-1/2">
                    <h3 className='p-4 border-b'>Osnovne specifikacije</h3>
                    <div className="content p-3 space-y-3">

                        <div>
                            <p className='text-xs font-semibold uppercase mb-1'>Proizvodjač</p>
                            <select className='outline-none w-full p-3 bg-white border rounded' defaultValue={formData.brand_id}>
                                <option>Izaberite proizvodjača</option>
                                {
                                    brands.map((brand) => {
                                        return (
                                            <option defaultValue={brand.id}>{brand.title}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                        <div>
                            <p className='text-xs font-semibold uppercase mb-1'>Model</p>
                            <select
                                className='outline-none w-full p-3 bg-white border rounded'
                                defaultValue={formData.model_id}
                            >
                                <option value="">Izaberite model</option>
                                {
                                    brands
                                        .filter((brand) => brand.id === formData.brand_id)
                                        .flatMap((brand) => brand.models)
                                        .map((model) => (
                                            <option key={model.id} defaultValue={model.id}>{model.title}</option>
                                        ))
                                }
                            </select>
                        </div>

                        <div>
                            <p className='text-xs font-semibold uppercase mb-1'>Kilometraža</p>
                            <input className='outline-none w-full p-3 bg-white border rounded' type="text" defaultValue={formData.mileage} placeholder='Kilometraža' />
                        </div>

                        <div className='flex gap-3'>

                            <div className='w-1/3'>
                                <p className='text-xs font-semibold uppercase mb-1'>Motor</p>
                                <select className='outline-none w-full p-3 bg-white border rounded' defaultValue={formData.engine}>
                                    <option>Izaberite motor</option>
                                </select>
                            </div>
                            <div className='w-1/3'>
                                <p className='text-xs font-semibold uppercase mb-1'>Snaga</p>
                                <input className='outline-none w-full p-3 bg-white border rounded' type="text" defaultValue={formData.power} placeholder='Kilometraža' />
                            </div>
                            <div className='w-1/3'>
                                <p className='text-xs font-semibold uppercase mb-1'>Konjske snage</p>
                                <input className='outline-none w-full p-3 bg-white border rounded' type="text" defaultValue={formData.horsePowers} placeholder='Kilometraža' />
                            </div>
                        </div>

                        <div>
                            <p className='text-xs font-semibold uppercase mb-1'>Godište</p>
                            <select
                                className='outline-none w-full p-3 bg-white border rounded'
                                defaultValue={formData.year}
                            >
                                <option value="">Izaberite godište</option>
                                <option defaultValue={formData.year}>{formData.year}</option>
                            </select>
                        </div>

                        <div>
                            <p className='text-xs font-semibold uppercase mb-1'>Sjedišta</p>
                            <input className='outline-none w-full p-3 bg-white border rounded' type="text" defaultValue={formData.seats} placeholder='Sjedišta' />
                        </div>

                        <div>
                            <p className='text-xs font-semibold uppercase mb-1'>Broj vrata</p>
                            <select
                                className='outline-none w-full p-3 bg-white border rounded'
                                defaultValue={formData.doors}
                            >
                                <option value="">Izaberite broj vrata</option>
                                <option value="4/5">4/5</option>
                                <option value="2/3">2/3</option>
                            </select>
                        </div>

                        <div>
                            <p className='text-xs font-semibold uppercase mb-1'>Transmisija</p>
                            <select
                                className='outline-none w-full p-3 bg-white border rounded'
                                defaultValue={formData.transmission}
                            >
                                <option value="">Izaberite transmisiju</option>
                                <option value="Automatik">Automatik</option>
                                <option value="Manuelno">Manuelni</option>
                            </select>
                        </div>

                    </div>
                </div>
                <div className="main-details bg-gray-100 w-1/2">
                    <h3 className='p-4 border-b'>Osnovne informacije</h3>
                    <div className="content p-3 space-y-3">

                        <div>
                            <p className='text-xs font-semibold uppercase mb-1'>Naslov oglasa</p>
                            <input className='outline-none w-full p-3 bg-white border rounded' type="text" defaultValue={formData.title} placeholder='Naslov oglasa' />
                        </div>

                        <div>
                            <p className='text-xs font-semibold uppercase mb-1'>Cijena</p>
                            <input className='outline-none w-full p-3 bg-white border rounded' type="text" defaultValue={formData.price} placeholder='Cijena' />
                        </div>

                        <div>
                            <p className='text-xs font-semibold uppercase mb-1'>Akciona cijena</p>
                            <input className='outline-none w-full p-3 bg-white border rounded' type="text" defaultValue={formData.actionPrice} placeholder='Akciona cijena' />
                        </div>

                        <ReactQuill className='bg-white border-gray-50 overflow-auto' style={{ maxHeight: 400 }} theme="snow" defaultValue={formData.description} onChange={setValue} modules={modules} formats={formats} />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CarForm