import React from 'react'
import { LineChart, Line, BarChart, Bar } from 'recharts';
import { useAppData } from '../../context/ApplicationProvider';

function MenagmentAnalyses() {
  const { zadaci, users, sections, currentUser, fetchZadaci } = useAppData();
  
  return (
    <div className='p-3'>
      <div>
        <h1>Analitički prikaz svih korisnika</h1>
        <div className='flex flex-col mt-4'>
          {
            users.map((user) => {
              return (
                <>
                  <p className='text-xs uppercase font-semibold'>{user.name}</p>
                  <div style={{ height: 15 }} className='w-full bg-gray-100 mb-3 relative'>
                    <div className='h-full flex items-center justify-end bg-red-500' style={{ width: `${zadaci.filter((zd) => zd.finishedBy === user._id && zd.status === "Završeno").length}%` }}>
                      <p className='text-white mr-1' style={{ fontSize: 10 }}>{zadaci.filter((zd) => zd.finishedBy === user._id && zd.status === "Završeno").length}</p>
                    </div>
                    <p className='absolute top-0 right-1 text-xs'>{zadaci.filter((zd) => zd.user === user._id).length}</p>
                  </div>
                </>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default MenagmentAnalyses