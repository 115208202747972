import React from 'react'

// ruter
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuthenticationPage from './pages/AuthenticationPage';
import LoggedRouter from './LoggedRouter';
import { ChakraProvider } from '@chakra-ui/react'

import ProtectedLoggedRouter from './LoggedRouter';
import { AuthProvider } from './context/AuthContext';
import { ApplicationProvider } from './context/ApplicationProvider';

function App() {
  return (
    <ApplicationProvider>
      <AuthProvider>
        <ChakraProvider>
          <Router>
            <Routes>
              <Route path='/' element={<AuthenticationPage />} />
              <Route path='/user/logged-in/*' element={<ProtectedLoggedRouter />} />
            </Routes>
          </Router>
        </ChakraProvider>
      </AuthProvider>
    </ApplicationProvider>
  )
}

export default App