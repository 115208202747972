import React, { useEffect, useState } from 'react'
import { useAppData } from '../../context/ApplicationProvider'

function Notifikacije() {


    const [notifikacije, setNotifikacije] = useState([])

    const currentUser = sessionStorage.getItem('user')

    const fetchNotifikacije = async () => {
        await fetch(`https://app.arenabackend.org/korisnici/notifications/${currentUser}`).then((res) => res.json()).then((response) => setNotifikacije(response)).catch((e) => console.log(e))
    }

    useEffect(() => {
        fetchNotifikacije()
    }, [])

    return (
        <div className='flex flex-col gap-3 p-3'>
            {
                notifikacije.map((notifikacija, i) => {
                    let date = new Date(notifikacija.createdAt);
                    return (
                        <div key={i} className={`p-3 bg-gray-100 relative ${notifikacija.isRead === true ? 'opacity-50' : null}`}>
                            <h1>{notifikacija.title}</h1>
                            <p className='text-xs'>Opis: {notifikacija.description}</p>
                            <p className='absolute top-2 right-2 text-sm'>{date.getDate()}.{date.getMonth() + 1}.{date.getFullYear()} u {date.getHours()}:{date.getMinutes()}</p>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Notifikacije